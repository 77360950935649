import 'dayjs/locale/vi';

import dayjs, { Dayjs } from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(RelativeTime).locale('vi');
export const checkDateTime = (value: string | Date | Dayjs | null) => {
  if (!value) {
    return false;
  }
  return dayjs(value).isValid();
};

export const humanDateTimeToNow = (value: string) => {
  const time = dayjs(value);
  if (!time.isValid()) {
    return '';
  }
  return time.toNow(true);
};
export const humanDateTimeFromNow = (value: string) => {
  const time = dayjs(value);

  if (!time.isValid()) {
    return '';
  }
  return time.fromNow();
};
export const dateTimeToNowOrDate = (value: string) => {
  const time = dayjs(value);
  if (!time.isValid()) {
    return '';
  }
  const day = (new Date().getTime() - new Date(value).getTime()) / 1000 / 86400;
  if (day > 30) return time.format('DD/MM/YYYY');
  return time.toNow(true);
};
export const humanDate = (value: string) => {
  const time = dayjs(value);

  if (!time.isValid()) {
    return '';
  }
  return time.format('DD/MM/YYYY');
};

export const humanTime = (value: string) => {
  const time = dayjs(value);

  if (!time.isValid()) {
    return '';
  }
  return time.format('hh:mm A');
};

export const humanTimeDate = (value: string) => {
  const time = dayjs(value);

  if (!time.isValid()) {
    return '';
  }
  return time.format('HH:mm DD/MM/YYYY');
};
export const humanTimeArticle = (value: string, locale: string) => {
  const _l = locale || 'vi';
  dayjs.extend(localizedFormat);
  return dayjs(value).locale(_l).format('ll');
};
export const systemRangeStartTimestamp = (value: string | Date | Dayjs | null) => {
  if (!value) {
    return null;
  }

  if (typeof value === 'string' || value instanceof Date) {
    const val = dayjs(value);

    if (!val.isValid()) {
      return val.startOf('date').valueOf();
    }
    return null;
  }

  return value.startOf('date').valueOf();
};

// export const systemInitMonthRange = (): Dayjs => {
//   const data = new Date();
//   return [dayjs(data).startOf('month'), dayjs(data).endOf('month')];
// };

export const systemRangeEndTimestamp = (value: string | Dayjs | Date | null) => {
  if (!value) {
    return null;
  }

  if (typeof value === 'string' || value instanceof Date) {
    const val = dayjs(value);

    if (!val.isValid()) {
      return val.endOf('date').valueOf();
    }
    return null;
  }
  return value.endOf('date').valueOf();
};
export function convertToISOTime(dateString: string) {
  const [day, month, year] = dateString.split('/').map(Number);

  // Create a new Date object using the components
  const dateObject = new Date(year, month - 1, day);

  // Use Date methods to get the individual components
  const isoYear = dateObject.getUTCFullYear();
  const isoMonth = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
  const isoDay = String(dateObject.getUTCDate() + 1).padStart(2, '0');

  // Assemble the ISO time format string
  const isoTimeFormat = `${isoYear}-${isoMonth}-${isoDay}T00:00:00.000Z`;

  return isoTimeFormat;
}
