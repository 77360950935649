import React from 'react';
export const RewardIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M12.25 14.8438V23.4976" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.92383 23.5H16.5777" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M6.19231 9.65355C4.81522 9.65355 3.49454 9.1065 2.52079 8.13275C1.54705 7.15901 1 5.83832 1 4.46124V2.73047H7.05769V9.65355H6.19231Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3068 9.65355C19.6839 9.65355 21.0046 9.1065 21.9783 8.13275C22.9521 7.15901 23.4991 5.83832 23.4991 4.46124V2.73047H17.4414V9.65355H18.3068Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4432 9.65385C17.4432 11.0309 16.8962 12.3516 15.9224 13.3254C14.9487 14.2991 13.628 14.8462 12.2509 14.8462C10.8738 14.8462 9.55313 14.2991 8.57939 13.3254C7.60564 12.3516 7.05859 11.0309 7.05859 9.65385V1H17.4432V9.65385Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
