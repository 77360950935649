import React from 'react';

type SendIconProps = {
  color?: string;
};

export const SendIcon = ({ color }: SendIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7044 2.00416C13.2807 2.07954 12.7016 2.27118 11.8575 2.55253L5.08902 4.8087C4.18175 5.11113 3.52157 5.33138 3.04389 5.52054C2.54754 5.7171 2.33322 5.8497 2.24537 5.9422C1.69555 6.52111 1.69555 7.42925 2.24537 8.00816C2.33322 8.10066 2.54754 8.23326 3.04389 8.42982C3.52157 8.61898 4.18175 8.83923 5.08902 9.14165C5.10343 9.14646 5.11766 9.1512 5.13169 9.15587C5.45374 9.26314 5.67612 9.33721 5.87624 9.44743C6.29064 9.67566 6.63178 10.0168 6.86001 10.4312C6.97023 10.6313 7.0443 10.8537 7.15157 11.1758C7.15625 11.1898 7.16098 11.204 7.16579 11.2184C7.46821 12.1257 7.68846 12.7859 7.87763 13.2635C8.07418 13.7599 8.20678 13.9742 8.29928 14.0621C8.87819 14.6119 9.78633 14.6119 10.3652 14.0621C10.4577 13.9742 10.5903 13.7599 10.7869 13.2635C10.9761 12.7859 11.1963 12.1257 11.4987 11.2184L13.7549 4.44989C14.0363 3.60586 14.2279 3.02679 14.3033 2.60305C14.3793 2.17568 14.3043 2.07859 14.2666 2.04086C14.2288 2.00313 14.1318 1.92814 13.7044 2.00416ZM13.5293 1.01962C14.039 0.928933 14.5744 0.934471 14.9737 1.33375C15.373 1.73304 15.3785 2.2684 15.2878 2.77819C15.198 3.28295 14.9816 3.93208 14.7172 4.72523L14.7036 4.76612L12.4474 11.5347L12.4419 11.5511C12.1461 12.4386 11.9172 13.1252 11.7167 13.6317C11.5222 14.1227 11.3235 14.5311 11.0539 14.7872C10.089 15.7035 8.57548 15.7035 7.61063 14.7872C7.341 14.5311 7.14231 14.1227 6.94787 13.6317C6.74727 13.1252 6.51841 12.4386 6.22256 11.551L6.2171 11.5347C6.09018 11.1539 6.04482 11.0239 5.98408 10.9136C5.84713 10.665 5.64245 10.4603 5.39381 10.3234C5.28351 10.2626 5.15355 10.2173 4.77279 10.0903L4.75641 10.0849C3.86884 9.78902 3.18228 9.56017 2.67571 9.35957C2.1847 9.16513 1.77636 8.96644 1.52028 8.69681C0.603917 7.73196 0.603917 6.2184 1.52028 5.25355C1.77636 4.98392 2.1847 4.78523 2.67571 4.59079C3.18229 4.39019 3.86887 4.16133 4.75645 3.86547L4.77279 3.86002L11.5413 1.60384L11.5822 1.59023C12.3753 1.32582 13.0245 1.10941 13.5293 1.01962Z"
        fill={color || "white"}
      />
    </svg>
  );
};
