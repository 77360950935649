import React from 'react';

export const MessageIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 42 42" fill="none">
      <path
        d="M37.9163 27.7657C37.9163 32.4854 34.127 36.3086 29.4073 36.3255H29.3903H12.6259C7.92309 36.3255 4.08301 32.5193 4.08301 27.7995V27.7826C4.08301 27.7826 4.09316 20.2953 4.10669 16.5296C4.10838 15.8225 4.92038 15.4267 5.47356 15.8665C9.49296 19.0553 16.6808 24.8695 16.7705 24.9457C17.9716 25.9082 19.4941 26.4512 21.0504 26.4512C22.6068 26.4512 24.1293 25.9082 25.3303 24.9271C25.42 24.8678 32.4472 19.2278 36.5275 15.9866C37.0823 15.5451 37.8977 15.9409 37.8994 16.6463C37.9163 20.3832 37.9163 27.7657 37.9163 27.7657Z"
        fill="currentColor"
      />
      <path
        d="M37.0305 10.3978C35.5655 7.63698 32.6829 5.87427 29.5093 5.87427H12.6265C9.45293 5.87427 6.57033 7.63698 5.10535 10.3978C4.77717 11.0152 4.9328 11.785 5.47921 12.2214L18.0398 22.2682C18.9195 22.9787 19.9852 23.3323 21.051 23.3323C21.0578 23.3323 21.0628 23.3323 21.0679 23.3323C21.073 23.3323 21.0798 23.3323 21.0848 23.3323C22.1506 23.3323 23.2163 22.9787 24.096 22.2682L36.6566 12.2214C37.203 11.785 37.3587 11.0152 37.0305 10.3978Z"
        fill="currentColor"
      />
    </svg>
  );
};
