/* eslint-disable @next/next/no-img-element */
import { LoadingDot32px } from '@/components/icons/LoadingDot';
import React, { useRef, useState } from 'react';
import { ImageViewer } from 'react-image-viewer-dv';
import { Icon } from '../Icon';
interface IImage {
  image: string;
  alt?: string;
  className?: string;
  classImg?: string;
  onError?: () => void;
  onSuccess?: () => void;
}
export const ViewImage = ({ image, alt, className, classImg, onError, onSuccess }: IImage) => {
  const [isError, setError] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);
  const ref = useRef<any>();
  const errorImage = () => {
    setError(true);
    if (ref.current && counter >= 3) {
      setError(false);
      return clearTimeout(ref.current);
    }
    ref.current = setTimeout(() => {
      setError(false);
      setCounter((prev) => prev + 1);
      onSuccess && onSuccess();
    }, 1500);
    onError && onError();
  };

  return (
    <div className={className}>
      {isError ? (
        <div
          className="tw-w-40 tw-pb-100-percent  tw-bg-center tw-bg-cover tw-relative"
          style={{
            backgroundImage: `url('/bg-default.png')`,
          }}
        >
          <div className="tw-absolute tw-w-full tw-h-full tw-left-0 tw-top-0 tw-bg-white tw-bg-opacity-50 tw-flex tw-justify-center tw-items-center">
            <div className="tw-w-8 tw-h-8 tw-cursor-pointer tw-flex tw-justify-center tw-items-center">
              <Icon className="tw-text-primary-main" icon={<LoadingDot32px />} />
            </div>
          </div>
        </div>
      ) : (
        <ImageViewer>
          {/* <Image src={image} alt={alt || 'image'} onLoad={onLoad} className="tw-w-full tw-h-32" /> */}
          <img src={image} onError={errorImage} alt={alt || 'image'} className={classImg} />
        </ImageViewer>
      )}
    </div>
  );
};
