import React from 'react';

export const BellIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      attributeName="bell"
    >
      <path
        fill="currentColor"
        d="M6.50248 7.27499C6.78492 4.45063 9.16156 2.2998 12 2.2998C14.8384 2.2998 17.2151 4.45063 17.4975 7.27499L17.7841 10.1411C17.8016 10.3154 17.8103 10.4026 17.8207 10.4884C17.9649 11.6715 18.3717 12.8075 19.0113 13.8133C19.0576 13.8863 19.1062 13.9591 19.2034 14.1049L20.0645 15.3966C20.8508 16.5761 21.244 17.1658 21.0715 17.6411C21.0388 17.7309 20.9935 17.8156 20.9368 17.8926C20.6371 18.2998 19.9283 18.2998 18.5108 18.2998H5.48923C4.07168 18.2998 3.36291 18.2998 3.06318 17.8926C3.00651 17.8156 2.96117 17.7309 2.92854 17.6411C2.75601 17.1658 3.14916 16.5761 3.93548 15.3966L4.79661 14.1049C4.89378 13.9591 4.94236 13.8863 4.98873 13.8133C5.62832 12.8075 6.03508 11.6715 6.17927 10.4884C6.18972 10.4026 6.19844 10.3154 6.21587 10.1411L6.50248 7.27499Z"
      />
      <path
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        d="M10.0681 20.9292C10.1821 21.0355 10.4332 21.1295 10.7825 21.1965C11.1318 21.2635 11.5597 21.2998 12 21.2998C12.4403 21.2998 12.8682 21.2635 13.2175 21.1965C13.5668 21.1295 13.8179 21.0355 13.9319 20.9292"
      />
    </svg>
  );
};

export const BellOutlineIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M12.3546 2.91016C9.04456 2.91016 6.35456 5.60016 6.35456 8.91016V11.8002C6.35456 12.4102 6.09456 13.3402 5.78456 13.8602L4.63456 15.7702C3.92456 16.9502 4.41456 18.2602 5.71456 18.7002C10.0246 20.1402 14.6746 20.1402 18.9846 18.7002C20.1946 18.3002 20.7246 16.8702 20.0646 15.7702L18.9146 13.8602C18.6146 13.3402 18.3546 12.4102 18.3546 11.8002V8.91016C18.3546 5.61016 15.6546 2.91016 12.3546 2.91016Z"
      stroke="#525252"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M14.2039 3.19945C13.8939 3.10945 13.5739 3.03945 13.2439 2.99945C12.2839 2.87945 11.3639 2.94945 10.5039 3.19945C10.7939 2.45945 11.5139 1.93945 12.3539 1.93945C13.1939 1.93945 13.9139 2.45945 14.2039 3.19945Z"
      stroke="#525252"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3535 19.0605C15.3535 20.7105 14.0035 22.0605 12.3535 22.0605C11.5335 22.0605 10.7735 21.7205 10.2335 21.1805C9.69351 20.6405 9.35352 19.8805 9.35352 19.0605"
      stroke="#525252"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);
