import React, { ReactNode, useEffect, useState } from 'react';

import FooterPartial from '@/layouts/new/partials/FooterPartial';
import HeaderPartial from '@/layouts/new/partials/HeaderPartial';
import ChatPopupPage from './partials/ChatPopup';
import { PopupPartial } from './partials/Popup';

import { PlatForm } from '../shared';
type NewLayoutProps = {
  isAuth: boolean;
  children: ReactNode;
  keyActive: string;
  isShowNav?: boolean;
  isMobile: boolean;
  isShowBubble?: boolean;
  platform: string;
};

const NewLayout = ({
  children,
  isAuth,
  keyActive,
  isShowNav = true,
  isShowBubble = true,
  isMobile,
  platform = PlatForm.Web,
}: NewLayoutProps) => {
  return (
    <main className="tw-relative">
      <div>
        <HeaderPartial isAuth={isAuth} keyActive={keyActive} platform={platform} />
        <div className={`tw-h-full ${isShowNav ? 'tw-pb-20 md:tw-pb-0' : ''} md:tw-min-h-80-screen `}>{children}</div>
        <FooterPartial keyActive={keyActive} isAuth={isAuth} isShowNav={isShowNav} platform={platform} />
      </div>
      <div id="modal-root"></div>
      <PopupPartial></PopupPartial>
      {/* {isClient && <Zalo />} */}

      {isShowBubble && <ChatPopupPage isAuth={isAuth} isMobile={isMobile} />}
    </main>
  );
};

export default NewLayout;
