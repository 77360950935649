import React from 'react';

export const DotHeaderIcon = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 18 18" fill="none">
        <rect
          width="5.97873"
          height="5.97898"
          transform="matrix(0.701562 -0.712608 0.714256 0.699885 9.53516 8.99609)"
          fill="currentColor"
        />
        <rect
          width="5.97873"
          height="5.97898"
          transform="matrix(0.701562 -0.712608 0.714256 0.699885 4.78906 4.26074)"
          fill="currentColor"
        />
        <rect
          width="5.97873"
          height="5.97898"
          transform="matrix(0.701562 -0.712608 0.714256 0.699885 0 9.03809)"
          fill="currentColor"
        />
        <rect
          width="5.97873"
          height="5.97898"
          transform="matrix(0.701562 -0.712608 0.714256 0.699885 4.78906 13.8154)"
          fill="currentColor"
        />
      </svg>
    </>
  );
};
