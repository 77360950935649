import * as React from 'react';
import { motion } from 'framer-motion';
import { MenuItem } from './MenuItem';
import { Link, Image } from '@/components';
import { PagePath } from '@/layouts/shared';
import { Category } from '@/features/products/list/const';
import { useCategories } from '@/contexts/profile';

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};
type NavigationProps = {
  handleClick: () => void;
  categoryHeader?: {
    code: string;
    image: string;
  };
};
export const Navigation = ({ handleClick, categoryHeader }: NavigationProps) => {
  const categories: Category[] | null = useCategories();

  return (
    <motion.ul
      variants={variants}
      className="tw-py-6 tw-absolute tw-top-[45px] tw-left-0 tw-h-[100vh] tw-bg-dark tw-w-[300px] tw-bg-white"
    >
      <div className="tw-grid tw-gap-y-3">
        <div className="tw-grid tw-gap-y-[10px] tw-px-6">
          <Link
            url={{ pathname: PagePath.SupportPage }}
            className="tw-font-medium hover:!tw-underline tw-uppercase tw-text-xs"
          >
            <div className="tw-text-secondary-70" onClick={handleClick}>
              Hỗ trợ
            </div>
          </Link>
          <Link
            url={{ pathname: PagePath.BlogPage, query: { id: 'kien-thuc-trang-suc' } }}
            className="tw-text-xs tw-font-medium hover:!tw-underline tw-uppercase"
          >
            <div className="tw-text-secondary-70" onClick={handleClick}>
              Kiến thức
            </div>
          </Link>
        </div>
        <div className="tw-bg-primary-main tw-text-white tw-text-sm tw-py-2 tw-text-center">Danh mục sản phẩm</div>
        <div className="tw-flex tw-flex-col tw-gap-y-[10px] tw-px-6">
          {categories &&
            (categories as Category[]).map((c, index) => (
              <MenuItem item={c} key={`category-${c.code}-${index}`} handleClick={handleClick} />
            ))}

          {categoryHeader && (
            <div className="tw-w-[200px] tw-pb-[70%] tw-relative tw-h-0" onClick={handleClick}>
              <Link url={{ pathname: PagePath.CategoryPage, query: { id: categoryHeader?.code } }}>
                <Image
                  alt={categoryHeader?.code}
                  src={categoryHeader?.image}
                  className="tw-w-full tw-h-full !tw-absolute tw-top-0 tw-left-0"
                  objectFit="cover"
                />
              </Link>
            </div>
          )}
        </div>
      </div>
    </motion.ul>
  );
};
// const itemIds = [0, 1, 2, 3, 4];
