import React, { ReactNode } from 'react';

type ModalFooterProps = {
  children: ReactNode;
  className?: string;
};

export const ModalFooter = ({ className, children }: ModalFooterProps): JSX.Element => {
  return <div className={className}>{children}</div>;
};

ModalFooter.defaultProps = {
  className: '',
};
