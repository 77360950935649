import { useMasterDataQuery } from '@/features/accounts/profile/api';
import { useFetchCategories } from '@/features/products/list/api';
import { useFetchUserQuery } from '@/layouts/new/api';
import { getAuth } from '@/libs/cookies';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

export const EmbraceDataContext = createContext({
  profile: null,
  masterData: null,
  categories: null,
});
type ProfileProps = {
  children: ReactNode;
};

const EmbraceDataProvider = ({ children }: ProfileProps) => {
  const [embraceData, setEmbraceData] = useState<any>({
    profile: null,
    masterData: null,
    categories: null,
  });
  const { isAuth } = getAuth();
  const { refetch } = useFetchUserQuery({
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      setEmbraceData((prev: any) => {
        return {
          ...prev,
          profile: data.data,
        };
      });
    },
    onError: (error) => console.log(error),
  });
  const { refetch: refetchCategories } = useFetchCategories({
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      setEmbraceData((prev: any) => {
        return {
          ...prev,
          categories: data.data,
        };
      });
    },
    onError: (error) => console.log(error),
  });

  const { refetch: refetchMasterData } = useMasterDataQuery({
    enabled: false,
    onSuccess: ({ data }) =>
      setEmbraceData((prev: any) => {
        return {
          ...prev,
          masterData: data,
        };
      }),
  });

  useEffect(() => {
    if (isAuth) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, refetch, embraceData.profile]);
  useEffect(() => {
    refetchCategories();
  }, [refetchCategories, embraceData.categories]);

  useEffect(() => {
    if (!embraceData.masterData) {
      refetchMasterData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchMasterData]);

  return <EmbraceDataContext.Provider value={embraceData}>{children}</EmbraceDataContext.Provider>;
};

export default EmbraceDataProvider;

export const useProfile = () => {
  const { profile } = useContext(EmbraceDataContext);
  return profile;
};

export const useCategories = () => {
  const { categories } = useContext(EmbraceDataContext);
  return categories;
};

export const useMasterData = () => {
  const { masterData } = useContext(EmbraceDataContext);
  return masterData as any;
};
