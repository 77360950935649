import { REFRESH_TOKEN } from './../cookies/index';
import { PagePath } from '@/layouts/shared';
import axios, { AxiosResponse } from 'axios';
import { getAuth, cleanAuth, initAuth, getCookie } from '@/libs/cookies';

type AxiosErrorResponse = {
  code: string;
  message: string;
  data?: any;
};

const instance = axios.create({
  baseURL: process.env.API_URL,
});
const instanceNews = axios.create({
  baseURL: process.env.API_NEWS_URL,
});
instance.interceptors.request.use((config) => {
  const { isAuth, token } = getAuth();

  if (isAuth) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  return config;
});

instance.interceptors.response.use(
  ({ data }) => {
    const dataValue = data || {};
    if (dataValue.data) {
      return { ...dataValue };
    }

    return { data: dataValue };
  },
  async ({ response }) => {
    const { data } = response || {};
    const { status } = response || {};
    const { code } = data || {};
    if (response && (status === 401 || status === 403)) {
      const refreshToken = getCookie(REFRESH_TOKEN);
      if (code === 'OTP_INVALID') {
        cleanAuth();
        return Promise.reject(data);
      }
      if (!refreshToken) {
        cleanAuth();
        return (window.location.href = PagePath.HomePage);
      }

      try {
        const result = await axios.post(`${process.env.API_URL}/api/v1/user/token/refresh`, null, {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        });
        const { access_token, refresh_token } = result.data;
        initAuth(access_token, refresh_token);
        location.reload();
        return;
      } catch (_error) {
        cleanAuth();
        return Promise.reject(data);
      }
    }
    return Promise.reject(data);
  }
);
instanceNews.interceptors.response.use(
  ({ data }) => {
    const dataValue = data || {};
    if (dataValue.data) {
      return { ...dataValue };
    }
    return { data: dataValue };
  },
  ({ response }) => {
    const { data } = response || {};
    const { message } = data || {};

    return Promise.reject({ message });
  }
);
const AxiosChatInstance = axios.create({
  baseURL: process.env.API_CHAT_URL,
});
const AxiosUploadImageInstance = axios.create({
  baseURL: process.env.API_BASE_URL,
});
AxiosChatInstance.interceptors.request.use((config) => {
  const { isAuth, token } = getAuth();

  if (isAuth) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  return config;
});

AxiosChatInstance.interceptors.response.use(
  ({ data }) => {
    const dataValue = data || {};
    if (dataValue.data) {
      return { ...dataValue };
    }
    return { data: dataValue };
  },
  async ({ response }) => {
    const { data } = response || {};
    const { status } = response;
    const { message } = data || {};
    if (response && (status === 401 || status === 403)) {
      const refreshToken = getCookie(REFRESH_TOKEN);
      if (!refreshToken) {
        cleanAuth();
        return (window.location.href = PagePath.LoginPage);
      }
      try {
        const result = await axios.post(`${process.env.API_URL}/api/v1/user/token/refresh`, null, {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        });
        const { access_token, refresh_token } = result.data;
        initAuth(access_token, refresh_token);
        return;
      } catch (_error) {
        cleanAuth();
        return Promise.reject(_error);
      }
    }
    return Promise.reject({ message });
  }
);

export { AxiosChatInstance };
export { instanceNews };
export type { AxiosResponse, AxiosErrorResponse };

export default instance;
