import { Icon, LogoIcon, Image, XIcon } from '@/components';
import { Conversation } from '@/features/consults/chat/const';
import React, { useState } from 'react';
type ChatProps = {
  chat: Conversation;
  selectChat: (data: Conversation) => void;
  closeChat: (data: Conversation) => void;
  userId: number;
};
export const ListChat = ({ chat, selectChat, closeChat, userId }: ChatProps) => {
  const [show, setShow] = useState<boolean>(false);
  const name = chat.user_conversations?.find((v) => v.user_id !== userId)?.user_name;
  return (
    <div
      className="tw-flex tw-items-center tw-px-2 tw-py-1 hover:tw-bg-gray-200"
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      key={chat.id}
    >
      <div className="tw-relative tw-flex tw-items-center " onClick={() => selectChat(chat)}>
        {chat.type_image ? (
          <Image
            src={chat.type_image}
            alt={chat.type_title}
            className="tw-w-6 tw-h-6 tw-rounded tw-drop-shadow tw-overflow-hidden"
          ></Image>
        ) : (
          <div className="tw-w-6 tw-h-6 tw-rounded tw-drop-shadow tw-overflow-hidden tw-bg-primary-main tw-flex tw-justify-center tw-items-center tw-text-white">
            <Icon icon={<LogoIcon width="20" height="16" />} className="!tw-w-full" />
          </div>
        )}

        {chat.unread_message > 0 && (
          <div
            className="tw-w-3 tw-h-3 tw-bg-red-500 tw-rounded-full tw-z-10 tw-absolute tw-text-white tw-flex tw-items-center tw-justify-center "
            style={{
              right: '-6px',
              top: '-4px',
              boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.25)',
              fontSize: '8px',
            }}
          >
            {chat.unread_message}
          </div>
        )}
      </div>
      <div
        className="tw-ml-2 tw-line-clamp-1 tw-text-sm tw-flex-1"
        onClick={() => selectChat(chat)}
        style={{ color: '#24292E' }}
      >
        {chat.type_title || name}
      </div>
      {show && (
        <div className="" onClick={() => closeChat(chat)} style={{ width: '12px', height: '12px', color: '#24292E' }}>
          <XIcon className="tw-w-full tw-h-full" />
        </div>
      )}
    </div>
  );
};
