import React from 'react';

export const FilterIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 28 28" fill="none">
      <path d="M14 22.168L4.66667 22.168" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M23.333 5.83203L19.833 5.83203" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M23.333 22.168L18.6663 22.168" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M15.167 5.83203L4.66699 5.83203" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M8.16699 14L4.66699 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M23.333 14L12.833 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <ellipse
        cx="16.3333"
        cy="22.1667"
        rx="2.33333"
        ry="2.33333"
        transform="rotate(-90 16.3333 22.1667)"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <ellipse
        cx="10.5003"
        cy="13.9987"
        rx="2.33333"
        ry="2.33333"
        transform="rotate(-90 10.5003 13.9987)"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <ellipse
        cx="17.5003"
        cy="5.83464"
        rx="2.33333"
        ry="2.33333"
        transform="rotate(-90 17.5003 5.83464)"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
export const FilterIconCustom = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
    <path d="M14 22.1667L4.66667 22.1667" stroke="white" strokeLinecap="round" />
    <path d="M23.334 5.83325L19.834 5.83325" stroke="white" strokeLinecap="round" />
    <path d="M23.334 22.1667L18.6673 22.1667" stroke="white" strokeLinecap="round" />
    <path d="M15.166 5.83325L4.66602 5.83325" stroke="white" strokeLinecap="round" />
    <path d="M8.16602 14L4.66602 14" stroke="white" strokeLinecap="round" />
    <path d="M23.334 14L12.834 14" stroke="white" strokeLinecap="round" />
    <ellipse
      cx="16.3333"
      cy="22.1667"
      rx="2.33333"
      ry="2.33333"
      transform="rotate(-90 16.3333 22.1667)"
      stroke="white"
      strokeLinecap="round"
    />
    <ellipse
      cx="10.4993"
      cy="13.9999"
      rx="2.33333"
      ry="2.33333"
      transform="rotate(-90 10.4993 13.9999)"
      stroke="white"
      strokeLinecap="round"
    />
    <ellipse
      cx="17.4993"
      cy="5.83342"
      rx="2.33333"
      ry="2.33333"
      transform="rotate(-90 17.4993 5.83342)"
      stroke="white"
      strokeLinecap="round"
    />
  </svg>
);

export const FilterSquareIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path
      d="M7.30065 6.04102H13.6923C14.2257 6.04102 14.659 6.47435 14.659 7.00768V8.07435C14.659 8.46602 14.4173 8.94935 14.1757 9.19102L12.0923 11.0327C11.8007 11.2743 11.609 11.7577 11.609 12.1493V14.2327C11.609 14.5243 11.4173 14.9077 11.1757 15.0577L10.5007 15.4827C9.86732 15.8743 9.00065 15.4327 9.00065 14.6577V12.091C9.00065 11.7493 8.80898 11.316 8.60898 11.0743L6.76732 9.13268C6.52565 8.89935 6.33398 8.45768 6.33398 8.16602V7.05768C6.33398 6.47435 6.76732 6.04102 7.30065 6.04102Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99935 18.8327H12.9993C17.166 18.8327 18.8327 17.166 18.8327 12.9993V7.99935C18.8327 3.83268 17.166 2.16602 12.9993 2.16602H7.99935C3.83268 2.16602 2.16602 3.83268 2.16602 7.99935V12.9993C2.16602 17.166 3.83268 18.8327 7.99935 18.8327Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SortIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M2.5 6.33398H17.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M5 10.5H15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M8.33398 14.666H11.6673" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);
