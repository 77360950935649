import React from 'react';

export const GiftIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 34 34" fill="none">
      <path
        d="M4.22656 13.2707C4.22656 12.3279 4.22656 11.8565 4.51946 11.5636C4.81235 11.2707 5.28375 11.2707 6.22656 11.2707H27.5857C28.5285 11.2707 28.9999 11.2707 29.2928 11.5636C29.5857 11.8565 29.5857 12.3279 29.5857 13.2707V16.3149C29.5857 17.2577 29.5857 17.7291 29.2928 18.022C28.9999 18.3149 28.5285 18.3149 27.5857 18.3149H27.3592C26.4163 18.3149 25.9449 18.3149 25.6521 18.6078C25.3592 18.9007 25.3592 19.3721 25.3592 20.3149V26.1768C25.3592 27.1196 25.3592 27.591 25.0663 27.8839C24.7734 28.1768 24.302 28.1768 23.3592 28.1768H10.4531C9.51027 28.1768 9.03887 28.1768 8.74597 27.8839C8.45308 27.591 8.45308 27.1196 8.45308 26.1768V20.3149C8.45308 19.3721 8.45308 18.9007 8.16019 18.6078C7.8673 18.3149 7.39589 18.3149 6.45308 18.3149H6.22656C5.28375 18.3149 4.81235 18.3149 4.51946 18.022C4.22656 17.7291 4.22656 17.2577 4.22656 16.3149V13.2707Z"
        stroke="currentColor"
      />
      <path d="M7.04443 18.3149H26.7682" stroke="currentColor" strokeLinecap="round" />
      <path d="M16.9062 9.86188L16.9063 28.1768" stroke="currentColor" strokeLinecap="round" />
      <path
        d="M16.9063 9.86186L15.2204 8.17594C14.0129 6.96842 12.5408 6.05862 10.9207 5.5186L9.67689 5.10399C8.38183 4.6723 7.04443 5.63624 7.04443 7.00135V8.42034C7.04443 9.2812 7.59529 10.0455 8.41198 10.3177L11.271 11.2707"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M16.9062 9.86186L18.5921 8.17594C19.7996 6.96842 21.2717 6.05862 22.8918 5.5186L24.1356 5.10399C25.4307 4.6723 26.7681 5.63624 26.7681 7.00135V8.42034C26.7681 9.2812 26.2172 10.0455 25.4005 10.3177L22.5415 11.2707"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};
