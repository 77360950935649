import React from 'react'

export const ImageUploadIcon =() => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32 32" fill="none">
    <mask id="mask0_2869_44268" style={{
      maskType: 'alpha'
    }} maskUnits="userSpaceOnUse" x="4" y="4" width="24" height="24">
    <path d="M4 12C4 8.22876 4 6.34315 5.17157 5.17157C6.34315 4 8.22876 4 12 4H20C23.7712 4 25.6569 4 26.8284 5.17157C28 6.34315 28 8.22876 28 12V20C28 23.7712 28 25.6569 26.8284 26.8284C25.6569 28 23.7712 28 20 28H12C8.22876 28 6.34315 28 5.17157 26.8284C4 25.6569 4 23.7712 4 20V12Z" fill="black"/>
    </mask>
    <g mask="url(#mask0_2869_44268)">
    <path d="M17.1973 19.7174L5.74602 7.12106C5.48297 6.83171 5.11007 6.66675 4.71903 6.66675C2.96732 6.66675 1.65352 5.06417 1.99706 3.34648L2.52912 0.686168C2.68131 -0.0747626 2.7574 -0.455228 2.88157 -0.776247C3.38827 -2.08625 4.54495 -3.0345 5.92889 -3.27445C6.26803 -3.33325 6.65603 -3.33325 7.43203 -3.33325H21.7919C25.6525 -3.33325 27.5828 -3.33325 28.7602 -2.12519C29.9377 -0.917136 29.8882 1.01251 29.7893 4.87181L29.333 22.6667L24.236 18.4193L24.236 18.4193C23.361 17.6901 22.9235 17.3255 22.4824 17.214C21.8857 17.0633 21.2528 17.1957 20.7665 17.573C20.4071 17.8518 20.1524 18.3612 19.643 19.38C19.3538 19.9585 19.2092 20.2477 19.0101 20.3915C18.7408 20.5861 18.3924 20.6341 18.0804 20.5198C17.8498 20.4352 17.6323 20.196 17.1973 19.7174L17.1973 19.7174Z" fill="currentColor" fillOpacity="0.2" stroke="white" strokeWidth="1.2" strokeLinecap="round"/>
    </g>
    <path d="M4 17.3333V12C4 8.22876 4 6.34315 5.17157 5.17157C6.34315 4 8.22876 4 12 4H20C23.7712 4 25.6569 4 26.8284 5.17157C28 6.34315 28 8.22876 28 12V20C28 23.7712 28 25.6569 26.8284 26.8284C25.6569 28 23.7712 28 20 28H16" stroke="currentColor" strokeWidth="1.2"/>
    <circle cx="22" cy="10" r="2" fill="white"/>
    <path d="M10.667 21.3335V20.7335H11.267V21.3335H10.667ZM4.37514 27.1354C4.11638 27.3424 3.73881 27.3004 3.5318 27.0416C3.3248 26.7829 3.36675 26.4053 3.62551 26.1983L4.37514 27.1354ZM10.067 28.0002V21.3335H11.267V28.0002H10.067ZM10.667 21.9335H4.00033V20.7335H10.667V21.9335ZM11.0418 21.802L4.37514 27.1354L3.62551 26.1983L10.2922 20.865L11.0418 21.802Z" fill="currentColor"/>
    </svg>
}
