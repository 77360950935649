import React from "react";
import { IconProps } from "../const";

export const LockIcon = ({ width, height }: IconProps) => {
  const _w = width || '24';
  const _h = height || '24';

  return (
    <svg width={_w} height={_h} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" transform="translate(0.5 0.5)" fill="white"/>
      <path d="M12.5 13.5C12.2348 13.5 11.9804 13.6054 11.7929 13.7929C11.6054 13.9804 11.5 14.2348 11.5 14.5V17.5C11.5 17.7652 11.6054 18.0196 11.7929 18.2071C11.9804 18.3946 12.2348 18.5 12.5 18.5C12.7652 18.5 13.0196 18.3946 13.2071 18.2071C13.3946 18.0196 13.5 17.7652 13.5 17.5V14.5C13.5 14.2348 13.3946 13.9804 13.2071 13.7929C13.0196 13.6054 12.7652 13.5 12.5 13.5ZM17.5 9.5V7.5C17.5 6.17392 16.9732 4.90215 16.0355 3.96447C15.0979 3.02678 13.8261 2.5 12.5 2.5C11.1739 2.5 9.90215 3.02678 8.96447 3.96447C8.02678 4.90215 7.5 6.17392 7.5 7.5V9.5C6.70435 9.5 5.94129 9.81607 5.37868 10.3787C4.81607 10.9413 4.5 11.7044 4.5 12.5V19.5C4.5 20.2956 4.81607 21.0587 5.37868 21.6213C5.94129 22.1839 6.70435 22.5 7.5 22.5H17.5C18.2956 22.5 19.0587 22.1839 19.6213 21.6213C20.1839 21.0587 20.5 20.2956 20.5 19.5V12.5C20.5 11.7044 20.1839 10.9413 19.6213 10.3787C19.0587 9.81607 18.2956 9.5 17.5 9.5ZM9.5 7.5C9.5 6.70435 9.81607 5.94129 10.3787 5.37868C10.9413 4.81607 11.7044 4.5 12.5 4.5C13.2956 4.5 14.0587 4.81607 14.6213 5.37868C15.1839 5.94129 15.5 6.70435 15.5 7.5V9.5H9.5V7.5ZM18.5 19.5C18.5 19.7652 18.3946 20.0196 18.2071 20.2071C18.0196 20.3946 17.7652 20.5 17.5 20.5H7.5C7.23478 20.5 6.98043 20.3946 6.79289 20.2071C6.60536 20.0196 6.5 19.7652 6.5 19.5V12.5C6.5 12.2348 6.60536 11.9804 6.79289 11.7929C6.98043 11.6054 7.23478 11.5 7.5 11.5H17.5C17.7652 11.5 18.0196 11.6054 18.2071 11.7929C18.3946 11.9804 18.5 12.2348 18.5 12.5V19.5Z" fill="#106657"/>
    </svg>
  )
}