type CommentIconProps = {
  color?: string;
  width?: number;
  height?: number;
};

export const CommentIcon = ({ color, width, height }: CommentIconProps) => {
  const _w = width || 23;
  const _h = height || 23;

  return (
    <>
      <svg width={_w} height={_h} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.9913 21.49L20.7742 16.94C20.7043 16.685 20.7393 16.41 20.859 16.17C21.9214 14.035 22.2756 11.485 21.6222 8.825C20.7343 5.225 17.931 2.335 14.3495 1.38C13.3718 1.125 12.4091 1 11.4763 1C4.78231 1 -0.445226 7.295 1.36047 14.3C2.19847 17.54 5.65024 20.895 8.8975 21.685C9.7754 21.9 10.6383 22 11.4763 22C13.2771 22 14.963 21.535 16.4445 20.735C16.6091 20.645 16.7937 20.595 16.9732 20.595C17.063 20.595 17.1528 20.605 17.2426 20.63L21.6621 21.815C21.687 21.82 21.7119 21.825 21.7319 21.825C21.9015 21.825 22.0362 21.665 21.9913 21.49ZM19.3775 17.315L20.0659 19.89L17.6167 19.235C17.4072 19.18 17.1927 19.15 16.9732 19.15C16.5542 19.15 16.1303 19.26 15.7512 19.465C14.4193 20.185 12.9828 20.55 11.4763 20.55C10.7331 20.55 9.97992 20.455 9.23669 20.275C6.48824 19.605 3.46046 16.645 2.76213 13.935C2.03386 11.105 2.6075 8.2 4.33338 5.965C6.05926 3.73 8.66306 2.45 11.4763 2.45C12.3044 2.45 13.1474 2.56 13.9804 2.785C17.058 3.605 19.4473 6.055 20.2205 9.175C20.7642 11.375 20.5397 13.57 19.5671 15.525C19.2877 16.085 19.2179 16.72 19.3775 17.315Z"
          fill={color || 'currentColor'}
          stroke={color || 'currentColor'}
        />
      </svg>
    </>
  );
};
