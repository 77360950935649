import React from 'react';

import { getLinkBct, getSocialUrl } from '@/libs/app';
import {
  Link,
  Image,
  HomeIcon,
  HomeIconActive,
  ProductsIcon,
  ProductsIconActive,
  LiveStreamIcon,
  LiveStreamIconActive,
  NewsIcon,
  NewsIconActive,
  AccountIcon,
  AccountIconActive,
  Icon,
  ReelIcon,
  ReelIconActive,
} from '@/components';
import { useTranslation } from 'next-i18next';
import { SocialType, PagePath, BctType, KeyActive, PlatForm } from '@/layouts/shared';
import Collapse from './Collapse';
import { MasterDataModel } from '@/models';
import { useMasterData } from '@/contexts/profile';
type FooterPartialProps = {
  isAuth: boolean;
  keyActive: string;
  isShowNav?: boolean;
  platform: string;
};

const FooterPartial = ({ keyActive, isAuth, isShowNav = true, platform }: FooterPartialProps) => {
  const { t } = useTranslation('layout');
  const masterData: MasterDataModel = useMasterData();

  const mobileNavItems = [
    {
      path: PagePath.HomePage,
      title: t('header.homepage'),
      target: '',
      icon: <HomeIcon />,
      iconActive: <HomeIconActive />,
      key: KeyActive.Home,
    },

    {
      path: PagePath.ProductsPage,
      title: t('header.product'),
      target: '',
      icon: <ProductsIcon />,
      iconActive: <ProductsIconActive />,
      key: KeyActive.Product,
    },
    {
      path: PagePath.LiveVideosPage,
      title: t('header.live-stream'),
      target: '',
      icon: <LiveStreamIcon />,
      iconActive: <LiveStreamIconActive />,
      key: KeyActive.LiveVideo,
    },
    {
      path: PagePath.ReelPage,
      title: t('header.reel'),
      target: '',
      icon: <ReelIcon />,
      iconActive: <ReelIconActive />,
      key: KeyActive.Reels,
    },
    {
      path: {
        pathname: PagePath.BlogPage,
        query: {
          id: masterData?.menu_blogs[0].slug,
        },
      },
      title: t('header.news'),
      target: '',
      icon: <NewsIcon />,
      iconActive: <NewsIconActive />,
      key: KeyActive.News,
    },
    {
      path: isAuth ? PagePath.AccountProfilePage : PagePath.LoginPage,
      title: t('header.account'),
      target: '',
      icon: <AccountIcon />,
      iconActive: <AccountIconActive />,
      key: KeyActive.Account,
    },
  ];
  return (
    <>
      <div
        className={`tw-border-t tw-border-primary-main tw-pt-6 md:tw-pt-14 ${
          keyActive !== KeyActive.GuideMeasuring && platform === PlatForm.Web && 'tw-mb-14'
        } ${platform === PlatForm.Mobile && 'tw-hidden'}  md:tw-mb-0`}
      >
        <div className="container tw-mx-auto tw-pb-6 md:tw-pb-14">
          <div className="tw-hidden md:tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-12 tw-gap-0 md:tw-gap-2 lg:tw-gap-6 tw-mt-6 tw-px-0  tw-gap-y-4 lg:tw-gap-y-0">
            <div className="tw-flex tw-flex-col tw-gap-y-5 lg:tw-col-span-4">
              <object
                className="tw-h-12 tw-w-60 tw-pointer-events-none tw-cursor-pointer"
                type="image/svg+xml"
                data="/logo.svg"
              ></object>
              <div className="tw-flex tw-items-center">
                <Image alt="Email" src="/email.svg" objectFit="contain" className={`tw-w-6 tw-h-6`} />
                <Link url={`mailto:support@anthu.tech`} className="tw-text-lg tw-ml-3">
                  support@anthu.tech
                </Link>
              </div>
              <div className="tw-flex tw-items-start tw-gap-3">
                <Image alt="call" src="/call.svg" objectFit="contain" className={`tw-w-6 tw-h-6`} />
                <div className="list tw-flex tw-flex-col tw-gap-y-1">
                  <div className="tw-flex tw-text-primary-main tw-text-sm">
                    <span className="tw-block tw-w-[160px]">Hotline mua hàng:</span>
                    <Link title="033 333 6789" url={`tel:0333336789`} className="tw-text-sm">
                      <span className="tw-font-semibold">033 333 6789</span>
                    </Link>
                  </div>
                  <div className="tw-flex tw-text-primary-main">
                    <span className="tw-block tw-w-[160px] tw-text-sm">Liên hệ hợp tác:</span>
                    <Link url={`tel:0333333789`} title="0333333789" className="tw-text-sm">
                      <span className="tw-font-semibold">03 3333 3789</span>
                    </Link>
                  </div>
                  <div className="tw-flex tw-text-primary-main">
                    <span className="tw-block tw-w-[160px] tw-text-sm">Chăm sóc khách hàng:</span>
                    <Link url={`tel:0333338939`} title="0333338939" className="tw-text-sm">
                      <span className="tw-font-semibold">03 3333 8939</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-y-5 lg:tw-col-span-3">
              <div className="tw-font-bold tw-uppercase tw-text-base tw-h-6 tw-text-secondary-70 tw-mb-5"></div>
              <div className="tw-gap-4 tw-grid tw-grid-cols-2">
                <div className="tw-flex tw-flex-col tw-gap-y-4">
                  <Link
                    url={{ pathname: PagePath.SupportPage }}
                    className="tw-text-base tw-font-regular hover:!tw-underline tw-uppercase"
                  >
                    Hỗ trợ
                  </Link>
                  <Link
                    url={{ pathname: PagePath.BlogPage, query: { id: 'kien-thuc-trang-suc' } }}
                    className="tw-text-base tw-font-regular hover:!tw-underline tw-uppercase"
                  >
                    Kiến thức
                  </Link>
                </div>
                <div className="tw-flex tw-flex-col tw-gap-y-4">
                  <Link
                    url={{ pathname: PagePath.ProductsPage }}
                    className="tw-text-base tw-font-regular hover:!tw-underline tw-uppercase"
                  >
                    Sản phẩm
                  </Link>
                  <Link
                    url={{ pathname: PagePath.LiveVideosPage }}
                    className="tw-text-base tw-font-regular hover:!tw-underline tw-uppercase"
                  >
                    Trực tiếp
                  </Link>
                  <Link url="/blog/tin-tuc" className="tw-text-base tw-font-regular hover:!tw-underline tw-uppercase">
                    Khuyến mãi
                  </Link>
                </div>
              </div>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-y-5 lg:tw-col-span-2">
              <div className="tw-font-bold tw-uppercase tw-text-base tw-text-secondary-70 tw-mb-5">
                {t('footer.title.link')}
              </div>
              <div className="tw-flex tw-flex-col rn-gap-y-3">
                <div className="list tw-flex tw-gap-x-3 tw-items-center">
                  <Link title="facebook" url={getSocialUrl(SocialType.Facebook)} target="_blank">
                    <span className="tw-hidden">FaceBook</span>
                    <Image src="/facebook-color.svg" alt="Facebook" className="tw-w-11 tw-h-11 tw-rounded-full" />
                  </Link>
                  <Link title="youtube" url={getSocialUrl(SocialType.TikTok)} target="_blank">
                    <span className="tw-hidden">TikTok</span>
                    <Image src="/tiktok.svg" alt="Tiktok" className="tw-h-11 tw-w-11 tw-rounded-full" />
                  </Link>
                </div>
                <div className="list tw-flex tw-gap-x-3 tw-items-center">
                  <Link title="youtube" url={getSocialUrl(SocialType.Youtube)} target="_blank">
                    <span className="tw-hidden">Youtube</span>
                    <Image src="/youtube.svg" alt="Youtube" className="tw-h-11 tw-w-11 tw-rounded-full" />
                  </Link>
                  <Link title="youtube" url={getSocialUrl(SocialType.Instagram)} target="_blank">
                    <span className="tw-hidden">Instagram</span>
                    <Image src="/instagram.svg" alt="instagram" className="tw-h-11 tw-w-11 tw-rounded-full" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-y-5 lg:tw-col-span-3">
              <div className="tw-font-bold tw-uppercase tw-text-base tw-text-secondary-70 tw-mb-5">
                {t('footer.title.download')}
              </div>
              <div className="tw-flex tw-gap-3 md:tw-gap-4">
                <Image
                  src="/qr-code.jpg"
                  alt="Apple Store"
                  title="apple"
                  className="tw-w-20 tw-h-20"
                  objectFit="contain"
                />
                <div className="list tw-hidden md:tw-flex tw-flex-col tw-gap-y-2">
                  <Link title="apple" url={getSocialUrl(SocialType.AppleStore)} target="_blank">
                    <span className="tw-hidden">Apple</span>

                    <Image src="/dl-appstore.png" alt="Apple Store" className="tw-h-8 tw-w-24" objectFit="contain" />
                  </Link>
                  <Link title="google" url={getSocialUrl(SocialType.GoogleStore)} target="_blank">
                    <span className="tw-hidden">Google store</span>
                    <Image src="/dl-ggplay.png" alt="Google Store" className="tw-h-8 tw-w-24" objectFit="contain" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="md:tw-hidden tw-grid tw-grid-cols-1">
            <div>
              <object
                className="tw-h-14 tw-pointer-events-none tw-cursor-pointer"
                title="logo"
                type="image/svg+xml"
                data="/logo.svg"
              ></object>
              {/* <Image alt="logo" src="/logo.svg" objectFit="contain" className={`tw-w-36 tw-h-11 tw-mx-auto`} /> */}
              <div className="tw-flex tw-mt-5">
                <Image
                  title="call"
                  alt="call"
                  src="/call.svg"
                  objectFit="contain"
                  className={`tw-w-6 tw-h-6 tw-mr-4`}
                />
                <div className="list tw-flex tw-flex-col tw-gap-y-2">
                  <div className="tw-flex tw-text-primary-main tw-text-sm">
                    <span className="tw-block tw-w-[160px]">Hotline mua hàng:</span>
                    <Link title="033 333 6789" url={`tel:0333336789`} className="tw-text-sm">
                      <span className="tw-font-semibold">033 333 6789</span>
                    </Link>
                  </div>
                  <div className="tw-flex tw-text-primary-main tw-text-sm">
                    <span className="tw-block tw-w-[160px]">Liên hệ hợp tác:</span>
                    <Link url={`tel:0333333789`} title="0333333789" className="tw-text-sm">
                      <span className="tw-font-semibold">03 3333 3789</span>
                    </Link>
                  </div>
                  <div className="tw-flex tw-text-primary-main tw-text-sm">
                    <span className="tw-block tw-w-[160px]">Chăm sóc khách hàng:</span>
                    <Link url={`tel:0333338939`} title="0333338939">
                      <span className="tw-font-semibold">03 3333 8939</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="tw-flex tw-mt-5">
                <Image
                  title="image"
                  alt="Email"
                  src="/email.svg"
                  objectFit="contain"
                  className={`tw-w-6 tw-h-6 tw-mr-4`}
                />
                <Link title="support@anthu.tech" url={`mailto:support@anthu.tech`} className="tw-text-xl tw-flex-1">
                  support@anthu.tech
                </Link>
              </div>
            </div>
            <div className="tw-mt-4">
              <Collapse title={t('footer.title.support')}>
                <div className="tw-gap-y-1 tw-flex tw-flex-col">
                  <Link
                    url={{ pathname: PagePath.SupportPage }}
                    className="tw-text-base tw-font-regular hover:!tw-underline tw-uppercase"
                  >
                    Hỗ trợ
                  </Link>
                  <Link
                    url={{ pathname: PagePath.BlogPage, query: { id: 'kien-thuc-trang-suc' } }}
                    className="tw-text-base tw-font-regular hover:!tw-underline tw-uppercase"
                  >
                    Kiến thức
                  </Link>
                  <Link
                    url={{ pathname: PagePath.ProductsPage }}
                    className="tw-text-base tw-font-regular hover:!tw-underline tw-uppercase"
                  >
                    Sản phẩm
                  </Link>
                  <Link
                    url={{ pathname: PagePath.LiveVideosPage }}
                    className="tw-text-base tw-font-regular hover:!tw-underline tw-uppercase"
                  >
                    Trực tiếp
                  </Link>
                  <Link url="/blog/tin-tuc" className="tw-text-base tw-font-regular hover:!tw-underline tw-uppercase">
                    Khuyến mãi
                  </Link>
                </div>
              </Collapse>
            </div>
            <div className="">
              <Collapse title={t('footer.title.link')}>
                <div className="list tw-flex tw-gap-x-3 tw-items-center">
                  <Link url={getSocialUrl(SocialType.Facebook)} target="_blank" title="fb link">
                    <span className="tw-hidden">fb link</span>

                    <Image
                      title="facebook"
                      src="/facebook-color.svg"
                      alt="Facebook"
                      className="tw-w-11 tw-h-11 tw-rounded-full"
                    />
                  </Link>
                  <Link url={getSocialUrl(SocialType.TikTok)} target="_blank" title="fb link">
                    <span className="tw-hidden">Tiktok link</span>
                    <Image title="Tiktok" src="/tiktok.svg" alt="tiktok" className="tw-w-11 tw-h-11 tw-rounded-full" />
                  </Link>
                  <Link url={getSocialUrl(SocialType.Youtube)} target="_blank" title="youtube Link">
                    <span className="tw-hidden">Youtube link</span>

                    <Image
                      title="youtube"
                      src="/youtube.svg"
                      alt="Youtube"
                      className="tw-h-11 tw-w-11 tw-rounded-full"
                    />
                  </Link>
                  <Link url={getSocialUrl(SocialType.Instagram)} target="_blank" title="youtube Link">
                    <span className="tw-hidden">Instagram link</span>

                    <Image
                      title="instagram"
                      src="/instagram.svg"
                      alt="instagram"
                      className="tw-h-11 tw-w-11 tw-rounded-full"
                    />
                  </Link>
                </div>
              </Collapse>
            </div>
            <div className="">
              <Collapse title={t('footer.title.download')}>
                <div className="tw-flex tw-gap-3 md:tw-gap-4">
                  <Image
                    title="qr code"
                    src="/qr-code.jpg"
                    alt="Apple Store"
                    className="tw-w-20 tw-h-20"
                    objectFit="contain"
                  />
                  <div className="list tw-hidden tw-flex-col tw-gap-y-2 md:tw-flex">
                    <Link url={getSocialUrl(SocialType.AppleStore)} target="_blank" title="apple link">
                      <span className="tw-hidden">apple qr</span>
                      <Image
                        title="Apple"
                        src="/dl-appstore.png"
                        alt="Apple Store"
                        className="tw-h-8 tw-w-24"
                        objectFit="contain"
                      />
                    </Link>
                    <Link url={getSocialUrl(SocialType.GoogleStore)} target="_blank" title="google link">
                      <span className="tw-hidden">google qr</span>

                      <Image
                        title="gg_play"
                        src="/dl-ggplay.png"
                        alt="Google Store"
                        className="tw-h-8 tw-w-24"
                        objectFit="contain"
                      />
                    </Link>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
        <div
          className="tw-bg-cover tw-text-xs md:tw-text-base tw-bg-center tw-text-center tw-py-10 md:tw-py-14 tw-text-white "
          style={{ backgroundImage: `url('/footer-bg.svg')` }}
        >
          <div className="tw-flex tw-flex-col tw-gap-y-2 container">
            <div className="tw-font-bold">© 2022 Công ty TNHH An Thư The Diamond Store</div>
            <div>
              MST: <b>0314503621</b>, Ngày cấp: <b>07/07/2017</b>, Người đại diện: <b>Nguyễn Thành An</b>
            </div>
            <div>
              Giấy chứng nhận ĐKKD <b>số 0314503621</b> do SKH&ĐT TP. HCM cấp lần đầu ngày 07/07/2017, sửa đổi lần thứ 7
              ngày 21/04/2020
            </div>
            <div>
              Địa chỉ đăng ký trụ sở chính: <b>89A Nguyễn Trãi, P. Bến Thành, Quận 1, TP. HCM, Việt Nam</b>
            </div>
            <div className="tw-flex tw-items-center tw-uppercase tw-justify-center">
              <b>Chứng nhận</b>&nbsp;&nbsp;{' '}
              <div className="list tw-flex tw-gap-x-3">
                <Link url={getLinkBct(BctType.Announced)} target="_blank" title="bct">
                  <span className="tw-hidden">bct</span>

                  <Image
                    src="/bct-2.png"
                    alt="Facebook"
                    title="fb"
                    objectFit="contain"
                    className="tw-w-32 tw-h-11 tw-rounded-full"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isShowNav && platform === PlatForm.Web && (
        <div className="tw-fixed md:tw-hidden tw-bottom-0 tw-w-full tw-bg-white tw-left-0 tw-z-55">
          <div className="tw-grid tw-grid-cols-6 tw-gap-4 tw-py-4 ">
            {mobileNavItems.map((item) => {
              const isActive = item.key === keyActive;
              return (
                <div key={item.title}>
                  <Link url={item.path}>
                    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
                      <Icon className="" icon={isActive ? item.iconActive : item.icon} />
                      <span className={`tw-mt-1 tw-text-10 ${isActive ? 'tw-text-primary-main' : 'tw-text-gray-90'}`}>
                        {item.title}
                      </span>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default FooterPartial;
