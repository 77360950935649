import AppProvider from '@/contexts';

import BasicLayout from '@/layouts/basic';
import PrivateLayout from '@/layouts/private';
import { motion, AnimatePresence } from 'framer-motion';
import type { AppProps } from 'next/app';
import { PageLayout } from '@/layouts/shared';
import { appWithTranslation } from 'next-i18next';
import Script from 'next/script';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-image-gallery/styles/css/image-gallery.css';

import './global.css';
import Head from 'next/head';
import NewLayout from '@/layouts/new';

const App = ({ Component, pageProps, router }: AppProps) => {
  const { layout, isAuth, keyActive, isShowNav, isMobile, isShowBubble, platform } = pageProps as any;

  if (layout === PageLayout.Basic) {
    return (
      <AppProvider pageProps={pageProps}>
        <BasicLayout>
          <Component {...pageProps} />
        </BasicLayout>
      </AppProvider>
    );
  }
  if (layout == PageLayout.New) {
    return (
      <AppProvider pageProps={pageProps}>
        <NewLayout
          platform={platform}
          isAuth={isAuth}
          keyActive={keyActive}
          isShowBubble={isShowBubble}
          isShowNav={isShowNav}
          isMobile={isMobile}
        >
          <>
            <Head>
              <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Head>
            <Script src="https://www.googletagmanager.com/gtag/js?id=G-YT69X6YZR8" strategy="afterInteractive" />
            <Script id="google-analytics" strategy="afterInteractive">
              {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            setTimeout(()=>{
              gtag('js', new Date());
              gtag('config', 'G-YT69X6YZR8');
            },5000)
           
          `}
            </Script>
            <div key={router.pathname} className="tw-h-full tw-w-full">
              <Component {...pageProps} />
            </div>
          </>
        </NewLayout>
      </AppProvider>
    );
  }
  return (
    <AppProvider pageProps={pageProps}>
      <PrivateLayout isAuth={isAuth} isMobile={isMobile}>
        <>
          <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" />
          </Head>
          <Script src="https://www.googletagmanager.com/gtag/js?id=G-YT69X6YZR8" strategy="afterInteractive" />
          <Script id="google-analytics" strategy="afterInteractive">
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            setTimeout(()=> {
              gtag('js', new Date());
              gtag('config', 'G-YT69X6YZR8');
            },5000)
            
          `}
          </Script>
          <Component {...pageProps} />
        </>
      </PrivateLayout>
    </AppProvider>
  );
};

export default appWithTranslation(App);
