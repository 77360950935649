import React from 'react';

import cm from '@/libs/tailwind-merge';

import { Controller, Control } from 'react-hook-form';
import { InputProps, Input } from '@/components/ui/Input';

interface TextFieldProps extends InputProps {
  name: string;
  error?: any;
  control: Control<any>;
  showErrorMessage?: boolean;
  disabled?: boolean;
}

export const TextField = ({
  name,
  error,
  control,
  className,
  disabled,
  showErrorMessage,
  containerClassName,
  wrapperClassName,
  errorMessageClassName,
  ...other
}: TextFieldProps) => {
  const hasError = !!error;
  const inputCs = cm(className, '');
  const containerCs = cm(containerClassName, `${hasError ? 'tw-border-red-500' : ''}`);
  const errorMsgCs = cm(errorMessageClassName, "tw-text-red-500 tw-text-xs");
  const wrapperCs = cm(wrapperClassName, "tw-flex tw-flex-col tw-gap-y-1")
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <div className={wrapperCs}>
            <Input {...field} {...other} disabled={disabled} containerClassName={containerCs} className={inputCs} />
            {hasError && showErrorMessage && <div className={errorMsgCs}>{error}</div>}
          </div>
        );
      }}
    />
  );
};

TextField.defaultProps = {
  hasError: false,
  showErrorMessage: true,
};
