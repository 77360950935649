import { ArrowLeftIcon } from '@/components/icons/ArrowLeft';
import React, { MouseEvent } from 'react';

import { useTranslation } from 'react-i18next';
import { Icon } from '../Icon';

type PaginationProps = {
  page: number;
  count: number;
  onPageChange: (val: number) => void;
};

const treeDot = '...';
const maxNumDisplay = 5;

export const Pagination = ({ page, count, onPageChange }: PaginationProps): JSX.Element => {
  const { t } = useTranslation('layout');

  const canPrev = page > 1;
  const canNext = page < count;

  let nums = [] as (string | number)[];
  if (count <= maxNumDisplay) {
    nums = Array.from(Array(count).keys());
  }

  if (count > maxNumDisplay) {
    const startNum = 0;
    const endNum = count - 1;
    const maxNumRangDisplay = maxNumDisplay - 1;

    nums = [startNum, treeDot, page - 2, page - 1, page, treeDot, endNum];

    const isFirstRange = maxNumRangDisplay;
    if (page < isFirstRange) {
      nums = [...Array.from(Array(maxNumRangDisplay).keys()), treeDot, endNum];
    }

    const isLastRange = count - maxNumRangDisplay;
    if (page > isLastRange) {
      nums = [
        startNum,
        treeDot,
        ...Array(maxNumDisplay)
          .fill(0)
          .map((_, idx) => endNum - maxNumRangDisplay + idx),
      ];
    }
  }

  const onPagePrev = () => {
    if (!canPrev) {
      return;
    }
    return onPageChange(page - 1);
  };

  const onPageClick = (e: MouseEvent<HTMLSpanElement>) => {
    const { index } = e.currentTarget.dataset;
    if (!index) {
      return;
    }
    return onPageChange(~~index);
  };

  const onPageNext = () => {
    if (!canNext) {
      return;
    }

    return onPageChange(page + 1);
  };

  return (
    <nav aria-label="navigation">
      <ul className="tw-inline-flex">
        <li className="tw-hidden lg:tw-inline-block">
          <span
            onClick={onPagePrev}
            className={`tw-inline-block tw-h-full tw-w-auto tw-px-2 sm:tw-px-3 tw-cursor-pointer ${
              !canPrev ? 'tw-text-secondary-60 tw-cursor-not-allowed' : ' tw-text-primary-main'
            }`}
          >
            <Icon icon={<ArrowLeftIcon />} />
          </span>
        </li>

        {nums.map((num, index) => {
          if (typeof num === 'string') {
            return (
              <li key={`pagination-item-${index}`}>
                <span className="tw-inline-block tw-h-full tw-w-auto tw-px-2 sm:tw-px-3">{num}</span>
              </li>
            );
          }

          const value = num + 1;
          const isActive = value === page;
          return (
            <li key={`pagination-item-${index}`}>
              <span
                data-index={value}
                onClick={onPageClick}
                className={`tw-inline-block tw-h-full tw-w-auto tw-px-2 sm:tw-px-3 tw-cursor-pointer ${
                  isActive ? 'tw-font-bold tw-border-b-2 tw-border-primary-main' : ''
                }`}
              >
                {value}
              </span>
            </li>
          );
        })}

        <li className="tw-hidden lg:tw-inline-block">
          <span
            onClick={onPageNext}
            className={`tw-inline-block tw-h-full tw-w-auto tw-px-2 sm:tw-px-3 tw-cursor-pointer ${
              !canNext ? 'tw-text-secondary-60 tw-cursor-not-allowed' : ' tw-text-primary-main'
            }`}
          >
            <Icon className="tw-rotate-180" icon={<ArrowLeftIcon />} />
          </span>
        </li>
      </ul>
    </nav>
  );
};

Pagination.defaultProps = {
  className: '',
  pageSize: 10,
};
