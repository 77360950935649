import React, { MouseEvent, ReactNode } from 'react';

import cm from '@/libs/tailwind-merge';

type ButtonProps = {
  icon: ReactNode;
  className?: string;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
};

export const Icon = ({ icon, className, onClick }: ButtonProps): JSX.Element => {
  const cs = cm('tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center tw-rounded-full', className);

  const onIconClick = (e: MouseEvent<HTMLDivElement>) => {
    if (typeof onClick === 'function') {
      return onClick(e);
    }
  };

  return (
    <div className={`${cs} wrapper-icon`} onClick={onIconClick}>
      {icon}
    </div>
  );
};
