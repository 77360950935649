import React from 'react';
import { TFunction } from 'next-i18next';
import { humanTimeDate } from '@/libs/dayjs';
import { UserConversation } from '@/features/consults/chat/const';

type RenderRevokedMessageType = {
  userId: number;
  createdAt: string;
  idMessAd: number;
  ownerId: number;
  ownerName: string;
  isPopup?: boolean;
  staff: UserConversation | undefined;
  t: TFunction;
};

export const RenderRevokedMessage = (props: RenderRevokedMessageType) => {
  const { ownerId, ownerName, userId, idMessAd, createdAt, staff, isPopup, t } =
    props;

  return (
    <div
      className={`${
        ownerId === userId
          ? 'chat-user custom-chat-user cu-rec'
          : 'custom-chat-admin ca-rec tw-break-all'
      }  ${
        ownerId === userId && !idMessAd ? 'custom-chat-user cu-rec' : ''
      } tw-px-4 tw-pt-2 tw-pb-1 tw-rounded-2xl tw-w-fit tw-relative tw-max-w-70 tw-break-all`}
    >
      {staff && ownerId !== userId && (
        <div
          className={`tw-text-secondary-30 ${!isPopup && 'tw-font-semibold'}`}
          style={{
            fontSize: isPopup ? '10px' : '15px',
            lineHeight: '15px',
          }}
        >
          {`An Thư Diamond - ${staff?.user_name}`}
        </div>
      )}
      {ownerId === userId && ownerName && (
        <div
          className={`tw-text-white ${!isPopup && 'tw-font-semibold'}`}
          style={{
            fontSize: isPopup ? '10px' : '15px',
            lineHeight: '15px',
          }}
        >
          {ownerName}
        </div>
      )}
      <div
        className="tw-py-1 tw-text-[15px] tw-font-light tw-italic tw-opacity-60"
        style={{
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
        }}
      >
        {t('Tin nhắn đã được thu hồi')}
      </div>
      <div
        className={`tw-text-right ${
          ownerId === userId ? 'tw-text-white' : 'tw-text-secondary-50'
        }`}
        style={{
          fontSize: isPopup ? '11px' : '9px',
        }}
      >
        {humanTimeDate(createdAt)}
      </div>
    </div>
  );
};
