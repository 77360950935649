import React from 'react';

const RateStar = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path
        d="M19.3321 10.1014L19.3321 10.1015L19.3423 10.1032L22.1044 10.5582C22.1044 10.5582 22.1044 10.5582 22.1045 10.5582C22.901 10.6901 23.2195 11.0135 23.3024 11.2722C23.3855 11.5319 23.314 11.9838 22.7418 12.5612C22.7415 12.5615 22.7413 12.5617 22.741 12.562L20.5867 14.7163C20.3329 14.9701 20.1566 15.3181 20.0586 15.6611C19.9606 16.0044 19.9255 16.3965 20.0092 16.7543C20.0092 16.7544 20.0092 16.7545 20.0092 16.7547L20.6265 19.4186L20.6265 19.4187C20.86 20.4253 20.6446 20.8725 20.4503 21.0145C20.2562 21.1563 19.7652 21.2255 18.878 20.6963L18.8773 20.6959L16.2915 19.1595C16.2909 19.1591 16.2903 19.1588 16.2896 19.1584C15.9595 18.9594 15.553 18.8761 15.1769 18.8761C14.8008 18.8761 14.3943 18.9594 14.0642 19.1584C14.0636 19.1588 14.063 19.1591 14.0624 19.1595L11.4776 20.6952C11.4774 20.6953 11.4772 20.6954 11.477 20.6955C10.5897 21.2195 10.0977 21.1517 9.90273 21.0098C9.70942 20.8692 9.49376 20.4255 9.72731 19.4187L9.72734 19.4186L10.3448 16.7536L10.3472 16.7433L10.3492 16.7329C10.4157 16.3783 10.3759 15.9908 10.2752 15.648C10.1749 15.3067 9.9993 14.9593 9.74546 14.7055L7.58963 12.5497C7.01786 11.9779 6.94672 11.5289 7.02976 11.2695C7.11304 11.0094 7.43308 10.6842 8.22906 10.5471L10.9889 10.0925C10.9891 10.0925 10.9893 10.0925 10.9894 10.0925C11.3224 10.0381 11.6501 9.87344 11.9166 9.67521C12.1824 9.47746 12.4335 9.21195 12.581 8.91055L14.1063 5.85999C14.1065 5.8596 14.1067 5.85922 14.1069 5.85883C14.4877 5.1077 14.8982 4.89973 15.1648 4.90039C15.432 4.90106 15.8437 5.11169 16.2251 5.86931C16.2252 5.86942 16.2252 5.86953 16.2253 5.86965L17.7511 8.92132C17.8995 9.22456 18.1521 9.48758 18.4171 9.68315C18.6839 9.87998 19.0074 10.041 19.3321 10.1014Z"
        fill="#CFE0DD"
        stroke="currentColor"
      />
      <path
        d="M8.66602 6.22852H2.16602C1.72185 6.22852 1.35352 5.86018 1.35352 5.41602C1.35352 4.97185 1.72185 4.60352 2.16602 4.60352H8.66602C9.11018 4.60352 9.47852 4.97185 9.47852 5.41602C9.47852 5.86018 9.11018 6.22852 8.66602 6.22852Z"
        fill="currentColor"
      />
      <path
        d="M5.41602 21.3965H2.16602C1.72185 21.3965 1.35352 21.0282 1.35352 20.584C1.35352 20.1398 1.72185 19.7715 2.16602 19.7715H5.41602C5.86018 19.7715 6.22852 20.1398 6.22852 20.584C6.22852 21.0282 5.86018 21.3965 5.41602 21.3965Z"
        fill="currentColor"
      />
      <path
        d="M3.24935 13.8125H2.16602C1.72185 13.8125 1.35352 13.4442 1.35352 13C1.35352 12.5558 1.72185 12.1875 2.16602 12.1875H3.24935C3.69352 12.1875 4.06185 12.5558 4.06185 13C4.06185 13.4442 3.69352 13.8125 3.24935 13.8125Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RateStar;
