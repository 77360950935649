/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/alt-text */
import React, {
  useEffect,
  Dispatch,
  useState,
  useRef,
  useContext,
} from 'react';

import * as yup from 'yup';
import toast from 'react-hot-toast';
import {
  Message,
  ConversationPayloadDefault,
  ImageUploadType,
  ChatReducerType,
  ChatReducerTypes,
  UserConversation,
  Conversation,
} from '@/features/consults/chat/const';
import { humanTimeDate } from '@/libs/dayjs';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChatActionType } from '@/features/consults/chat/reducer';
import {
  TextField,
  InputVariant,
  Icon,
  SendIcon,
  Button,
  ImageUploadIcon,
  InfoLineIcon,
  LoadingDot32px,
} from '@/components';
import {
  useFetchConversationById,
  useFetchConversationMessagesQuery,
  useSeenConversation,
  useUploadImage,
} from '@/features/consults/chat/api';
import { RenderImage } from '@/features/consults/chat/partials/message/RenderImage';
import {
  AccountStatus,
  MessageMethodType,
  MessageType,
} from '@/layouts/shared';
import { toPhoneNumberNational } from '@/libs/app';
import { AnimatePresence, motion } from 'framer-motion';
import Sticker from '@/features/consults/chat/partials/sticker';
import { useMasterData } from '@/contexts/profile';
import { FaceIcon } from '@/components/icons/Face';
import { RenderRevokedMessage } from '@/features/consults/chat/partials/message/RenderRevokedMessage';
import RenderProductsMessage from '@/features/consults/chat/partials/message/RenderProductMessage';
export type ConversationPartialProps = {
  socket: any;
  ownerId: number;
  ownerName: string;
  conversationId: number;
  state: ChatReducerTypes;
  dispatch: Dispatch<ChatActionType>;
  seenMessage: (value: number) => void;
  createDirectConversation: () => void;
  loading: boolean;
  newMessage: Message | undefined;
};

const ConversationNoProductPartial = ({
  conversationId,
  ownerId,
  ownerName,
  state,
  dispatch,
  socket,
  seenMessage,
  createDirectConversation,
  loading,
  newMessage,
}: ConversationPartialProps) => {
  const { t } = useTranslation(['consult', 'order', 'layout']);
  const masterData: any = useMasterData();
  const [showNote, setShowNote] = useState<boolean>(false);
  const [showSticker, setShowSticker] = useState<boolean>(false);
  const [conversation, setConversation] = useState<Conversation>();

  const collectionSticker = masterData?.list_collection_sticker || [];

  const messageEl = useRef<any>(null);
  const { mutate: mutateSeenConversation } = useSeenConversation();
  const scrollToBottom = () => {
    if (messageEl) {
      messageEl.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [state.messageMapper]);

  const { refetch, isLoading } = useFetchConversationMessagesQuery(
    conversationId,
    { limit: 100, offset: 0 },
    {
      enabled: false,
      onSuccess: ({ data }) => {
        const messages = data;
        const payload = (Array.isArray(messages) ? messages : []).reduce(
          (acc, item) => {
            acc = { ...acc, [item.id]: item };
            return acc;
          },
          {}
        );
        dispatch({ type: ChatReducerType.OnMessageMapperInit, payload });
      },
      onError: ({ message }) => {
        toast.error(message);
      },
    }
  );
  const { refetch: refetchConversation } = useFetchConversationById(
    conversationId,
    {
      onSuccess: ({ data }) => {
        setConversation(data);
        refetch();
      },
    }
  );
  const handleSeenMessage = (id: number) => {
    seenMessage(id);
    mutateSeenConversation(id);
  };
  const { mutate, isLoading: loadImage } = useUploadImage({
    onSuccess: (data: ImageUploadType) => {
      const payload = {
        method: 'chat',
        data: {
          type: 'send_message',
          data: {
            content: '',
            conversation_id: conversationId,
            type: MessageType.Text,
            images: [data.data.location],
          },
        },
      };
      socket.send(JSON.stringify(payload));
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: ConversationPayloadDefault,
    resolver: yupResolver(
      yup.object({
        comment: yup.string().required(t('Tư vấn không được để trống')),
      })
    ),
  });
  useEffect(() => {
    refetchConversation();
  }, [conversationId, refetchConversation]);
  const sendSticker = (s: string) => {
    setShowSticker(false);
    const payload = {
      method: 'chat',
      data: {
        type: 'send_message',
        data: {
          content: '',
          conversation_id: conversationId,
          type: MessageType.Sticker,
          images: [s],
        },
      },
    };
    socket.send(JSON.stringify(payload));
  };
  const onCommentClick = handleSubmit(({ comment }) => {
    reset({ ...ConversationPayloadDefault });
    if (!comment || !socket) return;
    const payload = {
      method: 'chat',
      data: {
        type: 'send_message',
        data: {
          content: comment,
          conversation_id: conversationId,
          type: MessageType.Text,
        },
      },
    };
    return socket && socket.send(JSON.stringify(payload));
  });
  const keyPress = (e: any) => {
    if (e.keyCode === 13) {
      onCommentClick();
    }
  };

  const handleChangeFile = (event: any) => {
    if (!event.target.files[0]) {
      return;
    }
    const formData = new FormData();
    formData.append('file', event.target.files[0], event.target.files[0].name);
    mutate(formData);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMessageReceived = (message: Message) => {
    if (message.conversation_id === conversationId) {
      const _message = {
        content: message.content || '',
        conversation_id: message.conversation_id,
        created_at: message.created_at,
        id: message.id,
        images: message.images,
        order_code: message.order_code || '',
        status: message.status,
        type: message.type || message.message_type || '',
        user_id: message.user_id,
        handover: message.handover,
      };
      return dispatch({
        type: ChatReducerType.OnChatSubmit,
        payload: { [message.id]: _message },
      });
    }
  };

  const onChatSeen = (message: Message) => {};
  useEffect(() => {
    if (newMessage) onMessageReceived(newMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessage]);

  let idMessUser: number = 0;
  let idMessAd: number = 0;
  let userStaffId: number = 0;

  for (let m of Object.values(state.messageMapper)) {
    if (m.user_id !== ownerId) {
      userStaffId = m.user_id;
    }
  }

  let messageData = Object.values(state.messageMapper).map((d) => {
    d.user_id === ownerId ? (idMessUser += 1) : (idMessAd += 1);
    return {
      ...d,
      idMessUser: idMessUser,
      idMessAd: idMessAd,
      images: d.images || [],
    };
  });

  const getAdmin = (id: number | undefined) => {
    if (!conversation || !conversation.user_conversations?.length || !id)
      return;
    return conversation.user_conversations.find((u) => u.user_id === id);
  };
  const currentAdmin = getAdmin(conversation?.supported_by);
  const renderMessage = (
    userId: number,
    content: string,
    createdAt: string,
    idMessAd: number,
    staff: UserConversation | undefined
  ) => {
    if (!content) return <></>;
    return (
      <div
        className={`tw-bg-white ${
          ownerId === userId
            ? 'chat-user custom-chat-user cu-rec'
            : 'custom-chat-admin border-white ca-rec !tw-bg-white tw-break-all'
        } tw-px-4 tw-py-2 tw-rounded-2xl tw-w-fit tw-relative tw-break-all`}
      >
        {staff && ownerId !== userId && (
          <div
            className="tw-text-secondary-30"
            style={{
              fontSize: '10px',
              lineHeight: '15px',
            }}
          >
            {`${staff?.user_name} - ${toPhoneNumberNational(
              staff?.user_phone + '',
              staff?.user_country,
              true
            )} `}
          </div>
        )}
        {ownerId === userId && (
          <div
            className="tw-text-white"
            style={{
              fontSize: '10px',
              lineHeight: '15px',
            }}
          >
            {ownerName}
          </div>
        )}
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
          }}
        >
          {content}
        </div>
        <div
          className={`tw-text-right ${
            ownerId === userId ? 'tw-text-white' : 'tw-text-secondary-50'
          }`}
          style={{
            fontSize: '11px',
          }}
        >
          {humanTimeDate(createdAt)}
        </div>
      </div>
    );
  };
  return (
    <div
      className="tw-flex tw-flex-col tw-col-span-1 sm:tw-col-span-2 tw-rounded-t-2xl tw-bg-muted-main sm:tw-order-last lg:tw-order-none tw-h-full tw-overflow-hidden tw-relative"
      style={{
        background: '#EDF0F5',
        border: '1px solid #DFE1E7',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
      }}
    >
      {!socket && (
        <div className="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-z-20 tw-h-full tw-bg-gray-50 !tw-bg-opacity-30 tw-flex tw-justify-center tw-items-center">
          <LoadingDot32px />
        </div>
      )}
      <div className="tw-py-3 tw-px-4 tw-bg-white tw-relative">
        <div className="tw-flex tw-justify-end tw-items-center">
          {loading || currentAdmin ? (
            <div
              className="tw-text-primary-main tw-font-medium tw-py-1 tw-px-3 tw-cursor-pointer"
              onClick={createDirectConversation}
            >
              + Hội thoại mới
            </div>
          ) : (
            <div className="tw-text-primary-main tw-font-medium tw-py-1 tw-px-3 tw-cursor-pointer tw-opacity-70">
              + Hội thoại mới
            </div>
          )}
        </div>
        <div className="tw-flex tw-items-center tw-text-lg">
          <span>Nhắn tin với An Thư</span>
          <span
            className=""
            onMouseEnter={() => setShowNote(true)}
            onMouseLeave={() => setShowNote(false)}
          >
            <Icon className="tw-ml-2" icon={<InfoLineIcon />} />
          </span>
        </div>
        {showNote && (
          <div
            className="tw-absolute tw-text-white tw-bg-black tw-block tw-z-10 tw-bg-opacity-75 tw-px-3 tw-py-2  tw-rounded-5 tw-w-4/5 tw-text-center tw-inset-x-full"
            style={{
              top: 'calc(100% + 8px)',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <div className=" tw-text-xs">
              Mỗi đoạn hội thoại của bạn sẽ do một chuyên viên tư vấn của An Thư
              hỗ trợ. Trong trường hợp bạn cần một chuyên viên khác tư vấn, vui
              lòng chọn nút <b>+ Hội thoại mới</b> bên trên.
            </div>
          </div>
        )}
      </div>
      <div className="tw-flex tw-flex-col tw-gap-y-4 tw-flex-1 tw-overflow-auto tw-p-4">
        {messageData.map(
          ({
            id,
            content,
            images,
            order_code,
            user_id: userId,
            created_at: createdAt,
            idMessAd,
            type,
            handover,
            is_revoked: isRevoked,
          }) => {
            const staff =
              userId > 0 ? getAdmin(userId) : getAdmin(handover?.to_user_id);

            if (isRevoked) {
              return (
                <div key={`message-${id}`}>
                  <div
                    className={`tw-flex ${
                      ownerId === userId
                        ? 'tw-justify-end'
                        : 'tw-justify-start '
                    }`}
                  >
                    <RenderRevokedMessage
                      userId={userId}
                      idMessAd={idMessAd}
                      ownerId={ownerId}
                      ownerName={ownerName}
                      staff={staff}
                      createdAt={createdAt}
                      isPopup={true}
                      t={t}
                    />
                  </div>
                </div>
              );
            }

            return (
              <div key={`message-${id}`}>
                {ownerId !== userId && idMessAd === 1 && (
                  <div className="tw-py-3 tw-text-primary-main tw-text-xs tw-text-center tw-flex-wrap tw-flex tw-items-center tw-justify-center">
                    {'An Thư Diamond - '}
                    {staff?.user_name ? staff?.user_name : 'Admin'}
                    &nbsp;{t('bắt đầu tư vấn cho bạn')}
                  </div>
                )}
                {type === MessageType.Video &&
                  images &&
                  images.length !== 0 && (
                    <video width="75%" height="240" controls>
                      <source src={images[0]} type="video/ogg" />
                    </video>
                  )}
                <div
                  className={`tw-flex ${
                    ownerId === userId ? 'tw-justify-end' : 'tw-justify-start '
                  }`}
                >
                  {type === MessageType.Product && (
                    <RenderProductsMessage
                      msgId={id}
                      productCodes={images}
                      userId={userId}
                      idMessAd={idMessAd}
                      ownerId={ownerId}
                      ownerName={ownerName}
                      staff={staff}
                      createdAt={createdAt}
                      isPopup={true}
                      t={t}
                    />
                  )}
                  {(type === MessageType.Text ||
                    type === MessageType.Sticker) &&
                    images &&
                    images.length !== 0 && (
                      <RenderImage
                        userId={userId}
                        ownerId={ownerId}
                        ownerName={ownerName}
                        staff={staff}
                        idMessAd={idMessAd}
                        image={images}
                        createdAt={createdAt}
                        isPopup={true}
                      ></RenderImage>
                    )}
                  {type != MessageType.OrderInfo &&
                  type != MessageType.Handover &&
                  type != MessageType.OrderInfo &&
                  type != MessageType.Product &&
                  images.length === 0 &&
                  content !== '4ea02fc6-b0f1-4bcb-b241-5742f946d26d'
                    ? renderMessage(userId, content, createdAt, idMessAd, staff)
                    : ''}
                </div>
              </div>
            );
          }
        )}
        <div className="" key="message-end" ref={messageEl}></div>
      </div>
      {!currentAdmin ||
      (currentAdmin && currentAdmin.user_status != AccountStatus.Deactivate) ? (
        <div className="tw-relative">
          <AnimatePresence>
            {showSticker && (
              <motion.div
                initial="stickerWrapperInitial"
                exit="stickerWrapperExit"
                animate="stickerWrapperAnimate"
                transition={{ duration: 0.3 }}
                variants={{
                  stickerWrapperInitial: {
                    opacity: 0,
                  },
                  stickerWrapperExit: {
                    opacity: 0,
                  },
                  stickerWrapperAnimate: {
                    opacity: 1,
                  },
                }}
              >
                <div
                  className="tw-absolute tw-bottom-full tw-left-1/2 tw--translate-x-2/4 tw-w-full tw-rounded-5 tw-drop-shadow-lg"
                  style={{
                    backgroundColor: '#F7F7FC',
                    width: '97%',
                  }}
                >
                  <div className="tw-relative tw-w-full">
                    <div className="tw-w-full tw-py-2">
                      {!!collectionSticker && (
                        <Sticker
                          collectionStickers={collectionSticker}
                          sendSticker={sendSticker}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          <div className="tw-flex tw-gap-x-2 tw-items-center tw-py-3 tw-px-5 tw-rounded-b-2xl">
            <>
              <div className="tw-text-primary-main tw-flex tw-items-center tw-relative">
                <input
                  type="file"
                  className="tw-absolute tw-w-full tw-h-full tw-opacity-0"
                  onChange={handleChangeFile}
                />
                <Icon className="tw-w-8 tw-h-8" icon={<ImageUploadIcon />} />
              </div>
              <div className="tw-flex-1">
                <TextField
                  name="comment"
                  control={control}
                  variant={InputVariant.Outlined}
                  error={errors.comment?.message}
                  onKeyDown={keyPress}
                  onFocus={() => handleSeenMessage(conversationId)}
                  placeholder={t('Gửi câu hỏi tư vấn')}
                  containerClassName="tw-bg-white tw-border-0 tw-rounded-full tw-flex-1 tw-px-4"
                />
              </div>

              <Button
                loading={isLoading}
                onClick={onCommentClick}
                className="tw-flex tw-justify-center tw-items-center !tw-rounded-full  tw-bg-primary-main !tw-w-8 !tw-h-8"
              >
                <Icon className="!tw-w-4 !tw-h-4" icon={<SendIcon />} />
              </Button>
              {!!collectionSticker.length && (
                <Button
                  loading={isLoading}
                  onClick={() => setShowSticker(!showSticker)}
                  className="tw-flex tw-justify-center tw-items-center !tw-rounded-full !tw-bg-transparent !tw-border-0 !tw-text-primary-main !tw-w-10 !tw-h-10"
                >
                  <Icon className="tw-w-4 tw-h-4" icon={<FaceIcon />} />
                </Button>
              )}
            </>
          </div>
        </div>
      ) : (
        <div className="tw-px-4">
          <span className="tw-text-sm tw-block tw-text-center">
            Hiện tại Nhân viên tư vấn cho bạn đã offline<br></br> Vui lòng chọn{' '}
            <b className="tw-text-primary-main"> Hội thoại mới</b> để được hỗ
            trợ tốt nhất từ An Thư nhé!
          </span>
          <div className="tw-bg-primary-main tw-mt-2 tw-h-8 tw-rounded tw-mb-2"></div>
        </div>
      )}
    </div>
  );
};

export default ConversationNoProductPartial;
