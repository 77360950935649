import { OrderAfterService } from './../../features/orders/list/const';
import { TFunction } from 'next-i18next';
import {
  AfterServiceStatus,
  BctType,
  City,
  CityName,
  CurrencySymbol,
  DefaultCountry,
  TransactionType,
} from '@/layouts/shared';
import { OrderType, DepositType, PaymentMethod, SocialType, PriceSymbol } from '@/layouts/shared';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import { OrderItemModel, ProductModel, ImageModel, TransactionModel, TransactionItemModel } from '@/models';
import { TransactionInfo } from '@/features/orders/detail/const';

interface Product extends ProductModel {
  images: ImageModel[];
}

interface OrderItem extends OrderItemModel {
  products: Product;
}
const phoneUtil = PhoneNumberUtil.getInstance();
export const toPhoneNumberNational = (
  phone: string,
  country: string = DefaultCountry.Vn,
  isVn: boolean = false
): string => {
  const number = phoneUtil.parseAndKeepRawInput(phone, country);
  if (country === DefaultCountry.Vn && isVn)
    return phoneUtil.format(number, PhoneNumberFormat.NATIONAL).replace(/\s/g, '');
  return phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL).replace(/\s/g, '');
};

export const checkPhone = (phone: string, country: string = DefaultCountry.Vn) => {
  const number = phoneUtil.parseAndKeepRawInput(phone, country);
  return phoneUtil.isValidNumber(number);
};
export const getNationalNumber = (phone: string, country: string = DefaultCountry.Vn) => {
  const number = phoneUtil.parseAndKeepRawInput(phone, country);
  return number.getNationalNumberOrDefault();
};
interface UserTransaction extends TransactionModel {
  transaction_detail: TransactionItemModel;
}

export const transformSnakeToKebab = (text: string | undefined) => {
  if (!text) {
    return '';
  }
  return text.toLocaleLowerCase().split('_').join('-');
};

export const transformCamelToKebab = (text: string) => {
  if (!text) {
    return '';
  }
  return text.replace(/[A-Z]/g, (letter) => `-${letter.toLocaleLowerCase()}`);
};

export const getCurrentPage = (limit: number, offset: number) => {
  let page = offset / limit;
  return page + 1;
};

export const getTotalPage = (count: number, limit: number) => {
  return Math.ceil(count / limit);
};

export const getPageQuery = (current: number, limit: number) => {
  let offset = current - 1;
  if (offset < 0) {
    offset = 1;
  }

  return offset * limit;
};

export const toInt = (value: string | number | string[] | undefined | null): number => {
  if (!value) {
    return 0;
  }

  if (Array.isArray(value)) {
    const [val] = value;
    return ~~val;
  }
  return ~~value;
};

export const toString = (value: string | string[] | undefined): string => {
  if (!value) {
    return '';
  }

  if (Array.isArray(value)) {
    const [val] = value;

    if (!val) {
      return '';
    }
    return val.toString();
  }
  return value.toString();
};

export const toMoney = (value: string | number, t: TFunction, symbol = PriceSymbol.$): string => {
  const val = +value || 0;

  if (symbol === PriceSymbol.$) {
    return t(`layout:price.${symbol}`, { value: val.toLocaleString() });
  }
  return t(`layout:price.${symbol}`, { value: val.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') });
};

export const toHumanOrderStatus = (value: string, t: TFunction) => {
  if (!value) return '';
  return t(`layout:order-status.${value.toLocaleLowerCase()}`);
};

export const toHumanDepositStatus = (value: string, t: TFunction) => {
  if (!Object.values(DepositType).includes(value as DepositType)) {
    return '';
  }
  return t(`layout:deposit-status.${value.toLocaleLowerCase()}`);
};

export const toHumanPaymentMethod = (value: string, t: TFunction) => {
  if (!Object.values(PaymentMethod).includes(value as PaymentMethod)) {
    return '';
  }
  return t(`layout:payment-method.${value.toLocaleLowerCase()}`);
};
export const toNamePaymentMethod = (actionType: string, paymentMethod: string, t: TFunction) => {
  if (actionType === TransactionType.RefundOrder || actionType === TransactionType.ReturnOrder) {
    if (paymentMethod === PaymentMethod.Atd) return t('layout:name-payment-method.atd');
    return t('layout:name-payment-method.cash');
  }
  return t(`layout:name-payment-method.${paymentMethod.toLocaleLowerCase()}`);

  // if (value === PaymentMethod.Atd) return t('layout:name-payment-method.atd');
  // return t('layout:name-payment-method.cash');
};
export const toFormPaymentMethod = (tr: TransactionInfo, t: TFunction) => {
  switch (tr.action_type) {
    case TransactionType.RefundOrder:
      return t(`layout:refund-form`);
    case TransactionType.ReturnOrder:
      return t(`layout:refund-form`);
    case TransactionType.DebitOrder:
      return t(`layout:transaction-form-debt`);
    case TransactionType.DeductionOrder:
      return t(`layout:transaction-form`);
    case TransactionType.PayOffDebitOrder:
      return t(`layout:transaction-form-debt`);
    default:
      return t(`layout:transaction-form`);
  }
};

export const getOrderItemImage = (orderItems: OrderItem[]): string => {
  const [{ products }] = Array.isArray(orderItems) ? orderItems : [];

  const { images } = products || {};
  const productImages = Array.isArray(images) ? images : [];

  const { url } = productImages[0] || {};

  return url;
};

export const getProductImage = (images: ImageModel[] | undefined) => {
  const productImages = Array.isArray(images) ? images : [];

  const { url } = productImages[0] || {};

  return url;
};

export const getLiveVideoImage = (images: ImageModel[]) => {
  const productImages = Array.isArray(images) ? images : [];

  const { url } = productImages[0] || {};

  return url;
};

export const getTransaction = (userTransactions: UserTransaction[]): UserTransaction => {
  const transactions = Array.isArray(userTransactions) ? userTransactions : [];
  if (transactions.length >= 1) {
    const [userTransaction] = transactions;
    if (userTransaction) {
      return userTransaction;
    }
  }
  return {} as UserTransaction;
};

export const getExchangeDebit = (userTransactions: UserTransaction[]) => {
  const transactions = Array.isArray(userTransactions) ? userTransactions : [];
  if (transactions.length >= 1) {
    let _transactions = transactions.filter(
      (v) => v.action_type === TransactionType.DebitOrder || v.action_type === TransactionType.PayOffDebitOrder
    );
    let amount = _transactions.reduce((a, b) => a + b.amount, 0);
    return amount;
  } else return 0;
};
export const getExchangeTransaction = (userTransactions: UserTransaction[]): UserTransaction => {
  const transactions = Array.isArray(userTransactions) ? userTransactions : [];
  if (transactions.length >= 1) {
    let transaction = transactions.find((v) => v.action_type === 'EXCHANGE_ORDER' || v.action_type === 'RETURN_ORDER');
    if (transaction) return transaction;
  }
  return {} as UserTransaction;
};
export const getRemainingTransaction = (userTransactions: UserTransaction[]) => {
  const transactions = Array.isArray(userTransactions) ? userTransactions : [];
  if (transactions.length >= 1) {
    let transaction = transactions.find((v) => v.action_type === 'PAY_OFF_ORDER');
    if (transaction) return transaction;
  }
  return null;
};
export const getPaymentInfo = (userTransactions: TransactionInfo[]) => {
  const transactions = Array.isArray(userTransactions) ? userTransactions : [];
  let tradingInfo = [];
  let images: string[] = [];
  if (transactions.length >= 1) {
    for (let tr of transactions) {
      if (tr.bank_info) {
        tradingInfo.push({ tradingCode: tr.code, branchBank: tr.bank_info.bank_branch });
      }
      if (tr && tr.image) {
        images.push(...tr.image.split(','));
      }
    }
    // let transaction = transactions.find((v) => v.action_type === 'PAY_OFF_ORDER');
    // if (transaction) return transaction;
  }
  return { trading_info: tradingInfo, images };
};
export const getPaymentInfoOA = (userTransactions: UserTransaction[]) => {
  const transactions = Array.isArray(userTransactions) ? userTransactions : [];
  let tradingInfo: { tradingCode: string; branchBank: string }[] = [];
  let images: string[] = [];
  if (transactions.length >= 1) {
    for (let tr of transactions) {
      const { transaction_detail: trDetail } = tr;
      if (trDetail && trDetail.bank_info) {
        tradingInfo.push({ tradingCode: trDetail.code, branchBank: trDetail.bank_info.bank_branch });
      }
      if (trDetail && trDetail.image) images.push(...trDetail.image.split(','));
    }
  }
  return { trading_info: tradingInfo, images };
};
export const getPayTheRest = (userTransactions: UserTransaction[]): UserTransaction => {
  const transactions = Array.isArray(userTransactions) ? userTransactions : [];
  if (transactions.length >= 1) {
    let transaction = transactions.find((v) => v.action_type === 'PAY_OFF_ORDER');
    if (transaction) return transaction;
  }
  return {} as UserTransaction;
};
export const getImagePayRemaining = (userTransactions: UserTransaction[]) => {
  const transactions = Array.isArray(userTransactions) ? userTransactions : [];
  let images: string[] = [];

  if (transactions.length >= 1) {
    let _transactions = transactions.filter((t, i) => i !== 0);
    for (let t of _transactions) {
      if (t?.transaction_detail && t?.transaction_detail.image) {
        const _images = t.transaction_detail.image.split(',');
        images.push(..._images);
      }
    }
  }
  return images;
};

export const getSocialUrl = (type: SocialType): string => {
  if (type === SocialType.AppleStore && process.env.APPLE_STORE_URL) {
    return process.env.APPLE_STORE_URL;
  }
  if (type === SocialType.Youtube && process.env.YOUTUBE_URL) {
    return process.env.YOUTUBE_URL;
  }
  if (type === SocialType.GoogleStore && process.env.GOOGLE_STORE_URL) {
    return process.env.GOOGLE_STORE_URL;
  }

  if (type === SocialType.Facebook && process.env.FACEBOOK_URL) {
    return process.env.FACEBOOK_URL;
  }
  if (type === SocialType.TikTok && process.env.TIKTOK_URL) {
    return process.env.TIKTOK_URL;
  }

  if (type === SocialType.Instagram && process.env.INSTAGRAM_URL) {
    return process.env.INSTAGRAM_URL;
  }

  return '/';
};
export const getLinkBct = (type: BctType): string => {
  if (type === BctType.Announced && process.env.BCT_ANNOUNCED) {
    return process.env.BCT_ANNOUNCED;
  }
  return '/';
};
export const parseStatusColor = (status: string, asStatus?: string) => {
  if (asStatus) {
    return 'tw-text-warning-90';
  }
  switch (status) {
    case OrderType.Pending:
      return 'tw-text-info-main';
    case OrderType.Confirmed:
      return 'text-green-main';
    case OrderType.Completed:
      return 'tw-text-primary-main';
    case OrderType.Cancelled:
      return 'tw-text-danger-main';
    case OrderType.Processing:
      return 'text-violet-main';
    default:
      return 'tw-text-warning-90';
  }
};
export const parseASStatusColor = (status: string) => {
  switch (status) {
    case OrderType.Pending:
      return 'tw-text-info-main';
    case OrderType.Confirmed:
      return 'text-green-main';
    case OrderType.Completed:
      return 'tw-text-primary-main';
    case OrderType.Cancelled:
      return 'tw-text-danger-main';
    case OrderType.Processing:
      return 'text-violet-main';
    default:
      return 'tw-text-warning-90';
  }
};
export const parseASStatusColorBg = (status: string) => {
  switch (status) {
    case OrderType.Pending:
      return 'tw-bg-info-main tw-bg-opacity-10';
    case OrderType.Confirmed:
      return 'bg-green-main';
    case OrderType.Cancelled:
      return 'tw-bg-danger-main tw-bg-opacity-10';
    case OrderType.Processing:
      return 'bg-violet-main-10';
    case OrderType.Completed:
      return 'tw-bg-primary-main tw-bg-opacity-10';
    default:
      return 'bg-warning-80-10';
  }
};
export const parseStatusColorBg = (status: string, asStatus?: string) => {
  if (asStatus) {
    return 'tw-bg-warning-90 tw-bg-opacity-10';
  }
  switch (status) {
    case OrderType.Pending:
      return 'tw-bg-info-main tw-bg-opacity-10';
    case OrderType.Confirmed:
      return 'bg-green-main';
    case OrderType.Cancelled:
      return 'tw-bg-danger-main tw-bg-opacity-10';
    case OrderType.Processing:
      return 'bg-violet-main-10';
    case OrderType.Completed:
      return 'tw-bg-primary-main tw-bg-opacity-10';
    default:
      return 'bg-warning-80-10';
  }
};

export const parseStatus = (status: string, cancelType: string, afterServiceStatus?: string) => {
  if (afterServiceStatus && status == OrderType.Completed) {
    switch (afterServiceStatus) {
      case AfterServiceStatus.Cancelled:
        return 'cancelled-after';
      case AfterServiceStatus.Editing:
        return 'editing-after';
      case AfterServiceStatus.Delivering:
        return 'delivering-after';
      case AfterServiceStatus.Processing:
        return 'processing-after';
      default:
        return 'completed-after';
    }
  }
  if (status !== OrderType.Cancelled) return status;
  return cancelType;
};

export const formatNumber = (number: number) => {
  return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};
export function debounce<T extends Function>(cb: T, wait = 20) {
  let h: any;
  let callable = (...args: any) => {
    clearTimeout(h);
    h = setTimeout(() => cb(...args), wait);
  };
  return <T>(<any>callable);
}
export const createHandle = (str: string) => {
  if (str) {
    str = str + '';
    str = str.trim();
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/\,/g, '-');
    str = str.replace(/\./g, '-');
    str = str.replace(/\!/g, '-');
    str = str.replace(/\?/g, '-');
    str = str.replace(/\~/g, '-');
    str = str.replace(/\ /g, '-');
    str = str.replace(/\|/g, '-');
    str = str.replace(/\./g, '-');
    str = str.replace(/\"/g, '-');
    str = str.replace(/\'/g, '-');
    str = str.replace(/\-\-+/g, '-');
    str = str.replace(/\s+/g, '-');
    str = str.replace(/[^\w\-]+/g, '');
    str = str.replace(/\-\-+/g, '-');
    str = str.replace(/^-+/, '');
    str = str.replace(/-+$/, '');
    if (str.slice(-1) == '-') str = str.substring(0, str.length - 1);
  }
  return str;
};
export const parseUtcTime = (time?: string) => {
  if (time) return new Date(new Date(time).toISOString()).getTime();
  return new Date(new Date().toISOString()).getTime();
};
export const pad = (number: number) => {
  if (number < 10) {
    return '0' + number;
  }
  return '' + number;
};
export const checkTime = (startTime: string, endTime: string) => {
  const now = new Date(new Date().toISOString()).getTime();
  const start = new Date(new Date(startTime).toISOString()).getTime();
  const end = new Date(new Date(endTime).toISOString()).getTime();
  return now >= start && now <= end;
};
export const parseCity = (city: string, t: TFunction) => {
  switch (city) {
    case City.HN:
      return t('list.city.hn');
    default:
      return t('list.city.hcm');
  }
};
export const getPriceReturn = (
  price: number,
  rate: number,
  transactionMethod: string,
  t: TFunction,
  paymentMethod: string
) => {
  if (paymentMethod === PaymentMethod.Atd) {
    if (transactionMethod != PaymentMethod.Atd) return toMoney(price * rate, t, PriceSymbol.$);
    return toMoney(price, t, PriceSymbol.Atd);
  } else {
    if (transactionMethod == PaymentMethod.Atd) return toMoney(price / rate, t, PriceSymbol.Atd);
    return toMoney(price, t, PriceSymbol.$);
  }
};
export const getPriceTransaction = (transaction: UserTransaction, paymentMethod: string) => {
  if (!transaction.transaction_detail) return transaction.amount;
  const { transaction_detail: _t } = transaction;
  if (paymentMethod !== PaymentMethod.Atd) {
    if (transaction.currency === CurrencySymbol.Vnd) return transaction.amount;
    return transaction.amount * +_t.atd_vnd;
  }
  if (transaction.currency === CurrencySymbol.Vnd) return transaction.amount / +_t.atd_vnd;
  return transaction.amount;
};

export const testImage = (url: string) => {
  return new Promise(function (resolve, reject) {
    var image = new Image();
    image.addEventListener('load', resolve);
    image.addEventListener('error', reject);
    image.src = url;
  });
};
export const getCity = (city: string) => {
  switch (city) {
    case City.HN:
      return CityName.HN;
    default:
      return CityName.HCM;
  }
};
export const getUserTransactionsOther = (transactions: TransactionModel[]) => {
  return transactions.filter(
    (transaction) =>
      transaction.action_type == TransactionType.DebitOrder ||
      transaction.action_type == TransactionType.PayOffDebitOrder ||
      transaction.action_type == TransactionType.PayOffOrder
  );
};

const PAY_OFF_STATUS: string[] = [
  TransactionType.DebitOrder,
  TransactionType.PayOffDebitOrder,
  TransactionType.PayOffOrder,
  TransactionType.Payoff,
  TransactionType.PayOffAsOrder,
];
export const getPricePaid = (transactions: TransactionModel[]) => {
  return transactions
    .filter((transaction) => PAY_OFF_STATUS.includes(transaction.action_type))
    .reduce((a, b) => a + b.amount, 0);
};
export const getPriceAsPaid = (orderAs: OrderAfterService[]) => {
  const transactions = [];
  for (let order of orderAs) {
    if (order.user_transactions.length) transactions.push(...order.user_transactions);
  }
  if (!transactions.length) return 0;
  return transactions.filter((t) => t.action_type === TransactionType.PayOffAsOrder).reduce((a, b) => a + b.amount, 0);
};
export const getPriceSymbolAsPaid = (orderAs: OrderAfterService[], priceSymbol: PriceSymbol) => {
  const transactions = [];
  for (let order of orderAs) {
    if (order.user_transactions.length) transactions.push(...order.user_transactions);
  }
  if (!transactions.length) return priceSymbol;
  const transaction = transactions.find((t) => t.action_type === TransactionType.PayOffAsOrder);
  if (!transaction) return priceSymbol;
  return transaction.payment_method === PaymentMethod.Atd ? PriceSymbol.Atd : PriceSymbol.$;
};

export const htmlEncode = (text: string) => {
  return text
    .replace(/\&/g, '&amp;')
    .replace(/\</g, '&lt;')
    .replace(/\>/g, '&gt;')
    .replace(/\"/g, '&quot;')
    .replace(/\'/g, '&#x27;')
    .replace(/\//g, '&#x2F;');
};

export const getCashbackDetail = (transaction: UserTransaction, paymentMethod: string, t: TFunction) => {
  const { transaction_detail: _t } = transaction;
  let ratio = 1;
  let p = paymentMethod;
  if (paymentMethod === PaymentMethod.Atd) {
    if (_t && _t.payment_method !== PaymentMethod.Atd) {
      ratio = _t.atd_vnd;
      p = _t.payment_method;
    }
    return toMoney(transaction.amount * ratio, t, p === PaymentMethod.Atd ? PriceSymbol.Atd : PriceSymbol.$);
  }
  if (_t && _t.payment_method == PaymentMethod.Atd) {
    ratio = 1 / _t.atd_vnd;
    p = _t.payment_method;
  }
  return toMoney(transaction.amount * ratio, t, p === PaymentMethod.Atd ? PriceSymbol.Atd : PriceSymbol.$);
};

export const getCashBackPrice = (user_transactions: UserTransaction[], paymentMethod: string, t: TFunction) => {
  const transactions = user_transactions.filter(
    (v) => v.action_type === TransactionType.RefundOrder || v.action_type === TransactionType.ReturnOrder
  );
  if (transactions.length === 0) {
    return toMoney(0, t, paymentMethod === PaymentMethod.Atd ? PriceSymbol.Atd : PriceSymbol.$);
  }
  let cashback = transactions.reduce((a: number, b: UserTransaction) => {
    return a + b.amount;
  }, 0);

  return toMoney(cashback, t, paymentMethod === PaymentMethod.Atd ? PriceSymbol.Atd : PriceSymbol.$);
};

export const getSlugFromUrl = (url: string) => {
  if (!url) return '';
  const parts = url.split('/').filter((el) => !!el);
  return parts[parts.length - 1];
};
