import React from 'react';

export const ClockIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" fill="transparent" />
      <circle cx="11.9998" cy="11.9998" r="5.83333" stroke="currentColor" />
      <path
        d="M15 11.9998H12.25C12.1119 11.9998 12 11.8879 12 11.7498V9.6665"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};
export const ClockFiledIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 12.25V12.5347L12.2449 12.68L16.3096 15.0918L16.0816 15.4658L11.5 12.7169V7.5H12V12.25ZM2.5 12C2.5 6.75549 6.74679 2.5 11.99 2.5C17.2442 2.5 21.5 6.75647 21.5 12C21.5 17.2435 17.2442 21.5 11.99 21.5C6.74679 21.5 2.5 17.2445 2.5 12ZM3.5 12C3.5 16.6961 7.30386 20.5 12 20.5C16.6961 20.5 20.5 16.6961 20.5 12C20.5 7.30386 16.6961 3.5 12 3.5C7.30386 3.5 3.5 7.30386 3.5 12Z"
        stroke="currentColor"
      />
    </svg>
  );
};
export const ClockSolidIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M9.99102 1.66406C5.39102 1.66406 1.66602 5.3974 1.66602 9.9974C1.66602 14.5974 5.39102 18.3307 9.99102 18.3307C14.5993 18.3307 18.3327 14.5974 18.3327 9.9974C18.3327 5.3974 14.5993 1.66406 9.99102 1.66406ZM12.741 13.9224L9.16602 10.3391V5.83073H10.8327V9.65573L13.9243 12.7474L12.741 13.9224Z"
        fill="currentColor"
      />
    </svg>
  );
};
