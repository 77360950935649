import React, { createContext, ReactNode, useEffect, useState } from 'react';

import FooterPartial from '@/layouts/private/partials/footer';
import SidebarPartial from '@/layouts/private/partials/sidebar';
import ChatPopupPage from '../new/partials/ChatPopup';
import dynamic from 'next/dynamic';

type PrivateLayoutProps = {
  isAuth: boolean;
  children: ReactNode;
  isMobile: boolean;
};
const PrivateLayout = ({ children, isAuth, isMobile }: PrivateLayoutProps) => {
  const [isOpen, setOpen] = useState<boolean>(true);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <main className="tw-relative">
      <SidebarPartial setOpen={setOpen} isAuth={isAuth} isOpen={isOpen} />
      <div className={`tw-px-3 md:tw-px-5 tw-relative tw-mb-16 lg:tw-mb-0 ${isOpen ? 'lg:tw-ml-32' : 'lg:tw-ml-12'}`}>
        <div className="tw-min-h-80-screen tw-pt-4">{children}</div>
        <FooterPartial />
      </div>
      <ChatPopupPage isAuth={isAuth} isMobile={isMobile} />
      {/* <Facebook isMobile={isMobile} />
      <Zalo /> */}
    </main>
  );
};

export default PrivateLayout;
