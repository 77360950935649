import React, { memo, useEffect, useImperativeHandle, useState } from 'react';
import { XIcon, Image, ChatLineIcon, Icon, LogoIcon, Link } from '@/components';
import {
  useFetchConversationsQuery,
  useFetchLastedDirectConversation,
  useSeenConversation,
} from '@/features/consults/chat/api';
import { ConversationModel, ConversationType } from '@/models';
import toast from 'react-hot-toast';
import ListChatHidden from './ChatHidden';
import { Conversation, Message } from '@/features/consults/chat/const';
import { MessageMethodType, PagePath } from '@/layouts/shared';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { Zalo } from '@/features/consults/zalo';

export interface ChatDetailType {
  chatId: number | null;
  productId: number | null;
}
interface ListChatProps {
  socket: any;
  userId: number;
  isMobile: boolean;
  isChatDetail: boolean;
  setChatDetail: (data: ChatDetailType) => void;
  createNewDirectConversation: () => void;
  listIds: number[];
  isAuth: boolean;
  setNewMessage: (data: Message) => void;
}

// eslint-disable-next-line react/display-name
const ListChatBubble = React.forwardRef((props: ListChatProps, ref) => {
  const router = useRouter();
  const {
    listIds,
    socket,
    userId,
    setChatDetail,
    createNewDirectConversation,
    setNewMessage,
    isMobile,
    isChatDetail,
    isAuth,
  } = props;
  const [listChatShow, setListChatShow] = useState<ConversationModel[]>([]);
  const [listChatHidden, setListChatHidden] = useState<ConversationModel[]>([]);
  const [ids, setIds] = useState<number[]>([]);
  const [isClient, setIsClient] = useState<boolean>(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  const { refetch: refetchConversions } = useFetchConversationsQuery(
    {
      type: ConversationType.Product,
      ids,
      offset: 0,
      limit: 40,
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
      onError: ({ message }) => {
        toast.error(message);
      },
      onSuccess: ({ data }) => {
        if (!data.length) return;
        let _data: Conversation[] = [];
        for (let id of ids) {
          for (let d of data) {
            if (d.id === id) {
              _data.push(d);
            }
          }
        }
        if (_data.length <= 5) {
          setListChatShow(_data);
          setListChatHidden([]);
        } else {
          setListChatShow(_data.slice(0, 5));
          setListChatHidden(_data.slice(5, _data.length));
        }
      },
    }
  );
  const { refetch: fetchDirectConversation } = useFetchLastedDirectConversation({
    enabled: false,
    retry: false,
    onSuccess(data) {
      if (data.data && data.data.id) {
        if (isMobile) {
          router.push({ pathname: PagePath.ConsultChatPage, query: { id: data.data.id } });
        } else {
          setChatDetail({
            chatId: data.data.id,
            productId: null,
          });
        }
      } else {
        createNewDirectConversation();
      }
    },
    onError: () => {
      createNewDirectConversation();
    },
  });
  const { mutate } = useSeenConversation({
    onSuccess: () => refetchConversions(),
  });
  useEffect(() => {
    if (listIds.length) setIds(listIds);
  }, [listIds]);
  useEffect(() => {
    if (ids.length) {
      localStorage.removeItem('listChatBubble');
      localStorage.setItem(
        'listChatBubble',
        JSON.stringify({
          userId: userId,
          ids: Array.from(new Set(ids)),
        })
      );
    }
  }, [ids, userId]);
  useEffect(() => {
    if (!!ids.length && isAuth) {
      refetchConversions();
    }
  }, [ids, isAuth, refetchConversions]);
  const checkDirectConversation = () => {
    fetchDirectConversation();
  };
  useImperativeHandle(ref, () => ({
    seenMessage: (id: number) => mutate(id),
  }));

  useEffect(() => {
    if (socket)
      socket.onmessage = (evt: any) => {
        const _data = JSON.parse(evt.data);
        if (_data.method !== MessageMethodType.NewMessage) return;

        const data = _data.data;
        setNewMessage(data);
        const isId = ids.includes(data?.conversation_id);
        if (!isId) {
          setIds((prev) => {
            return [data.conversation_id, ...prev];
          });
        } else {
          let _ids = ids.filter((v) => v != data.conversation_id);
          setIds([data.conversation_id, ..._ids]);
        }
        const _chat = listChatShow.find((c) => c.id === data?.conversation_id);
        if (!!_chat && !!userId && userId !== data.user_id) {
          setChatDetail({
            chatId: _chat.id,
            productId: _chat.type_id,
          });
        }
        // if (isAuth) refetchConversions();
      };
  }, [socket && socket.onmessage, ids]);

  const closeChatItem = (id: number | undefined) => {
    if (!id) return;
    let _ids = ids.filter((v) => v != id);
    setChatDetail({
      chatId: null,
      productId: null,
    });
    if (ids.length == 1) {
      localStorage.removeItem('listChatBubble');
      setIds(_ids);
      return localStorage.setItem(
        'listChatBubble',
        JSON.stringify({
          userId: userId,
          ids: [],
        })
      );
    }
    return setIds(_ids);
  };

  const openChat = (chat: ConversationModel | undefined) => {
    if (!chat) return;
    if (isMobile) {
      router.push({ pathname: PagePath.ConsultChatPage, query: { id: chat.id } });
    } else {
      setChatDetail({
        chatId: chat.id,
        productId: chat.type_id,
      });
    }
    chat.unread_message = 0;
    return mutate(chat.id);
  };
  const selectChat = (data: Conversation) => {
    setChatDetail({
      chatId: data.id,
      productId: data.type_id,
    });
    mutate(data.id);
    let _ids = ids;
    let index = _ids.findIndex((v) => v === data.id);
    if (index >= 0) {
      _ids.splice(index, 1);
      _ids.unshift(data.id);
      setIds(_ids);
    }
  };
  const closeChat = (data: Conversation) => {
    closeChatItem(data.id);
  };

  const unreadMessagesCount = () => {
    const counter = [...listChatShow, ...listChatHidden].reduce((sum, b) => sum + (b.unread_message ?? 0), 0);
    return counter;
  };

  const ZALO_OA_ID = process.env.ZALO_OA_ID || '';
  const FB_PAGE_ID = process.env.FB_PAGE_ID || '';
  const FB_LINK = process.env.FB_LINK || 'https://www.facebook.com/messages/t/138153763641280';
  return (
    <>
      {!!listChatShow.length && (
        <div
          className={`${
            isChatDetail
              ? 'tw-mr-2 md:tw-mr-0 md:tw-mb-2 tw-flex tw-flex-row md:tw-flex-col tw-gap-x-2 md:tw-gap-x-0 md:tw-gap-y-2'
              : 'tw-mb-2 tw-flex tw-flex-col tw-gap-y-2'
          }`}
        >
          {listChatShow.map((chat, index) => {
            const isId = ids.find((id) => id === chat.id);
            return (
              isId && (
                <div
                  key={`${chat?.id}-${index}`}
                  className={` tw-relative tw-w-10 tw-h-10 md:tw-w-14 md:tw-h-14 hover-show md:tw-mt-4 md:tw-ml-0 tw-cursor-pointer`}
                >
                  {chat?.unread_message && chat?.unread_message > 0 ? (
                    <div
                      className="tw-w-5 tw-h-5 tw-bg-red-500 tw-rounded-full tw-z-10 tw-absolute tw-text-white tw-flex tw-items-center tw-justify-center "
                      style={{
                        right: '-10px',
                        top: '-6px',
                        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.25)',
                        fontSize: '12px',
                      }}
                    >
                      {chat?.unread_message}
                    </div>
                  ) : (
                    <div
                      className="tw-w-5 tw-h-5 tw-bg-white tw-rounded-full tw-z-10 tw-absolute tw-text-black  tw-items-center tw-justify-center show tw-cursor-pointer"
                      style={{
                        right: '-6px',
                        top: '-6px',
                        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.25)',
                        color: '#474D58',
                      }}
                      onClick={() => closeChatItem(chat?.id)}
                    >
                      <XIcon className="tw-w-3 tw-h-3" />
                    </div>
                  )}
                  <div onClick={() => openChat(chat)} className="tw-h-full tw-w-full">
                    {chat?.type_image ? (
                      <Image
                        src={chat?.type_image}
                        className="chat-popup-item tw-rounded-full tw-overflow-hidden tw-w-full tw-h-full"
                        alt="chat"
                      ></Image>
                    ) : (
                      <div className="chat-popup-item tw-rounded-full tw-w-full tw-h-full tw-bg-primary-main tw-text-white tw-flex tw-justify-center tw-items-center">
                        <Icon icon={<LogoIcon width="32" height="24" />} className="!tw-w-full" />
                      </div>
                    )}
                  </div>
                </div>
              )
            );
          })}
        </div>
      )}

      {listChatHidden.length > 0 && (
        <div className="tw-mt-3" key="chat-hidden">
          <ListChatHidden userId={userId} listChat={listChatHidden} selectChat={selectChat} closeChat={closeChat} />
        </div>
      )}
      <div
        className={` tw-relative tw-flex  ${
          isChatDetail
            ? 'tw-flex-row tw-gap-x-2 md:tw-flex-col sm:tw-gap-y-4 sm:tw-gap-x-0'
            : 'tw-flex-col tw-gap-y-2  tw-items-end md:tw-mt-3'
        }`}
        key="chat-no-product"
      >
        {isAuth && (
          <div
            className="tw-relative tw-bg-primary-main tw-cursor-pointer tw-rounded-full tw-flex tw-justify-center tw-items-center tw-flex-col tw-text-white tw-w-10 tw-h-10 md:tw-w-60px md:tw-h-60px"
            onClick={checkDirectConversation}
          >
            <Icon icon={<ChatLineIcon />} className="tw-h-6 tw-w-6 md:tw-h-9 md:tw-w-9" />
            <span
              className="tw-hidden md:tw-block"
              style={{
                fontSize: '7.5px',
              }}
            >
              Nhắn tin
            </span>
            {unreadMessagesCount() > 0 && (
              <div
                className="tw-absolute tw-h-5 tw-w-5"
                style={{
                  right: '-6px',
                  top: '-5px',
                }}
              >
                <Image src="/diamond-icon.svg" alt="diamond-icon" className="tw-w-full tw-h-full" />
              </div>
            )}
          </div>
        )}

        {ZALO_OA_ID && (
          <>{isClient && <Zalo />}</>

          // <div
          //   className="tw-block tw-rounded-full tw-z-50 tw-w-10 tw-h-10 md:tw-w-60px md:tw-h-60px"
          //   style={{
          //     boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px',
          //   }}
          // >
          //   <Link url={`https://zalo.me/${ZALO_OA_ID}`} target="_blank" className="tw-w-full tw-w-block">
          //     <span className="tw-hidden">zalo</span>
          //     <Image className="tw-w-full tw-h-full" src="/zalo.webp" alt="zalo" />
          //   </Link>
          // </div>
        )}
        {!isMobile ? (
          <div>
            <div id="fb-root"></div>
            <div id="fb-customer-chat" className="fb-customerchat"></div>
            {/* <script
              dangerouslySetInnerHTML={{
                __html: `
            window.fbAsyncInit = function() {
              FB.init({
                xfbml            : true,
                version          : 'v14.0'
              });
            };
            setTimeout(()=>{
              (function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = 'https://connect.facebook.net/vi_VN/sdk/xfbml.customerchat.js';
                fjs.parentNode.insertBefore(js, fjs);
              }(document, 'script', 'facebook-jssdk'));
            }, 5000)
            `,
              }}
            /> */}
            <script
              dangerouslySetInnerHTML={{
                __html: `
            var chatbox = document.getElementById('fb-customer-chat');
      chatbox.setAttribute("page_id", "${FB_PAGE_ID}");
      chatbox.setAttribute("attribution", "biz_inbox");
            `,
              }}
            />
          </div>
        ) : (
          <div
            className="tw-block tw-rounded-full tw-z-50 tw-w-10 tw-h-10 md:tw-w-60px md:tw-h-60px"
            style={{
              boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px',
            }}
          >
            <Link url={FB_LINK} target="_blank" className="tw-w-full tw-w-block">
              <Image className="tw-w-full tw-h-full" src="/facebook.png" alt="facebook" />
            </Link>
          </div>
        )}
      </div>
    </>
  );
});

export default ListChatBubble;
