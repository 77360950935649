import React from 'react';
import { IconProps } from '../const';

export const EditFillIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2067 7.7957L16.0027 5.9997C16.548 5.45445 16.8206 5.18182 16.9663 4.88773C17.2436 4.32818 17.2436 3.67122 16.9663 3.11167C16.8206 2.81757 16.548 2.54495 16.0027 1.9997C15.4575 1.45445 15.1848 1.18182 14.8907 1.03609C14.3312 0.758804 13.6742 0.758804 13.1147 1.03609C12.8206 1.18182 12.548 1.45445 12.0027 1.9997L10.184 3.81835C11.1479 5.46895 12.5341 6.84451 14.2067 7.7957ZM8.72959 5.27281L1.85908 12.1433C1.43402 12.5684 1.22149 12.7809 1.08176 13.042C0.942021 13.3031 0.883076 13.5978 0.765185 14.1873L0.149789 17.2643C0.0832673 17.5969 0.0500062 17.7632 0.144614 17.8578C0.239222 17.9524 0.405527 17.9191 0.738138 17.8526L3.81512 17.2372C4.40457 17.1193 4.6993 17.0604 4.96039 16.9206C5.22149 16.7809 5.43402 16.5684 5.85908 16.1433L12.7485 9.25392C11.1268 8.23827 9.75513 6.87597 8.72959 5.27281Z"
        fill="currentColor"
      />
    </svg>
  );
};
