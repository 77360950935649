import { HandoverModel } from './../../../models/handover';
import { LastedMessage } from '@/features/livestream/detail/const';
import { ProductDiscountType } from '@/features/products/shared';
import {
  MessageModel,
  ConversationModel,
  ProductModel,
  ImageModel,
  AttributeModel,
  ConversationType,
  UserModel,
} from '@/models';

/**
 * Props
 */

export type InfoProductPartialProps = {
  productId: number;
  messageId: number;
};

/**
 * Models
 */

export { ConversationType };

export interface Message extends MessageModel {
  handover?: HandoverModel;
}

export type UserConversation = {
  user_country: string;
  user_id: number;
  user_name: string;
  user_phone: number;
  user_role: string;
  user_avatar: string;
  user_status: string;
};

export type RatingStaffPayload = {
  staff_id: number;
  object_id: number;
  object_name: string;
  object_type: string;
  content: string;
  number_rating: number;
};

export type RatingStaffResponse = {
  id: number;
  object_id: any;
};
export interface Conversation extends ConversationModel {
  user_conversations?: UserConversation[];
  supported_by?: number;
  last_message?: LastedMessage;
}

export type FetchRatingStaffPayload = {
  staffId: number | string;
  pId: number | string;
};

export type RatingStaffQueryResponse = {
  id: number;
  number_rating: number;
  content: string;
  user_id: number;
  staff_id: number;
  object_id: number;
  object_name: string;
  object_type: string;
  created_at: string;
  updated_at: string;
};
interface Product extends ProductModel {
  images: ImageModel[];
  attributes: AttributeModel;
  product_discount: ProductDiscountType;
  auction: {
    origin_price: number;
    origin_token: number;
    sell_price: number;
    sell_token: number;
    status: string;
  };
}

/**
 * Query
 */

export type ConversationPayload = {
  comment: string;
};

export type FetchConversationMessagesPayload = {
  limit: number;
  offset: number;
};

export type ConversationMessagesQueryResponse = {
  data: Message[];
};

export type ConversationsQueryPayload = {
  type?: string;
  ids?: number[];
  limit?: number;
  offset?: number;
};

export type ConversationsSearchQueryPayload = {
  keyword: string;
};

export type ConversationsQueryResponse = {
  data: Conversation[];
};

export type ConversationQueryResponse = {
  data: Conversation;
};
export type staffUserQueryResponse = {
  data: UserModel;
};
export type User = UserModel;
export type ProductQueryResponse = { data: Product };
export type ImageUploadResponse = {
  data: {
    location: string;
  };
};
/**
 * Const
 */

export type ConversationTypes = Conversation;

export type ProductTypes = Product;

export enum ChatReducerType {
  OnChatSubmit = 'OnChatSubmit',
  OnMessageMapperInit = 'OnMessageMapperInit',
}

export type ImageUploadType = {
  data: {
    location: string;
  };
};
export type ChatReducerTypes = {
  messageMapper: { [key: number]: Message };
};

/**
 * Default !value
 */

export const ConversationPayloadDefault = {
  comment: '',
};

export interface DirectConverSationResponse {
  conversation: Conversation;
  is_new: boolean;
}
