import { QueryKey } from '@/layouts/shared';
import {
  CommentQueryPayload,
  CommentQueryResponse,
  CommentsQueryResponse,
  StreamersQueryPayload,
  StreamersQueryResponse,
  LiveVideosQueryPayload,
  LiveVideosQueryResponse,
  CreateChatConversationPayload,
  LiveVideoQueryResponse,
  ProductDepositPayload,
  ProductDepositResponse,
  LiveVideosHomeQueryResponse,
  StickerPayload,
  StickerQueryResponse,
  SendStickerPayload,
  CollectionStickerPayload,
  LuckyDetailResponse,
  fetchCommentPayload,
  ListOfParticipantsPayload,
  ListOfParticipantsResponse,
  CreateTicketType,
  TicketByUserResponse,
  ReplyCommentQueryResponse,
  ProductResponse,
  AuctionResponse,
  SendAuctionPayload,
  giftAtdPayload,
} from '@/features/livestream/detail/const';
import axios, { AxiosResponse, AxiosErrorResponse, AxiosChatInstance } from '@/libs/axios';
import { useQuery, QueryObserverOptions, MutationOptions, useMutation } from 'react-query';
import { LuckyModel } from '@/models/lucky';

export const fetchLiveVideos = (params: string | LiveVideosQueryPayload) => {
  return axios.get('/api/v1/live-videos/paging', { params });
};
export const fetchLiveVideosHome = () => {
  return axios.get('/api/v1/live-videos/header');
};
export const trackingVideo = (id: number) => {
  return axios.get(`/api/v1/live-videos/tracking/${id}`);
};
export const fetchStreamers = (params: string | StreamersQueryPayload) => {
  return axios.get('/api/v1/streamer/list', { params });
};

export const fetchLiveVideo = (id: number) => {
  return axios.get(`/api/v1/live-videos/${id}`);
};
export const fetchCollectionSticker = (params: CollectionStickerPayload) => {
  return axios.get(`/api/v1/sticker/collections-total-stickers`, { params });
};
export const fetchSticker = (params: string | StickerPayload) => {
  return axios.get(`/api/v1/sticker/list`, { params });
};
export const fetchProductDeposit = (params: string | {}) => {
  return axios.get(`/api/v1/order/product/`, { params });
};
export const fetchListOfParticipants = (params: string | ListOfParticipantsPayload) => {
  return axios.get(`/api/v1/lottery/tickets`, { params });
};

export const fetchComments = (payload: any) => {
  return axios.get(`/api/v1/comments/live-stream/${payload.id}/list?offset=${payload.offset}&limit=20`);
};
export const fetchAuctionByProductId = (id: number | string) => {
  return axios.get(`/api/v1/products/${id}/auction`);
};
export const fetchReplyCommentById = (id: number) => {
  return axios.get(`/api/v1/comments/reply/${id}`);
};
export const fetchCommentById = (id: number | string | string[]) => {
  return axios.get(`/api/v1/comments/${id}`);
};

export const addCommentQuery = (payload: CommentQueryPayload) => {
  return axios.post('/api/v1/comments', payload);
};
export const sendAuction = (payload: SendAuctionPayload) => {
  return axios.post(`/api/v1/auction/${payload.id}/offer/${payload.price}`);
};
export const createCodeTicketQuery = (payload: { lottery_game_id: string }) => {
  return axios.post('/api/v1/lottery/collect/ticket', payload);
};
export const openLucky = (id: number) => {
  return axios.post(`api/v1/lucky/${id}/open-box`);
};
export const watchLiveVideo = () => {
  return axios.put('/api/v1/user/watch/live_videos');
};
export const sendSticker = (payload: SendStickerPayload) => {
  return axios.post('/api/v1/live-videos/sticker', payload);
};

export const giftAtd = (payload: giftAtdPayload) => {
  return axios.post(`/api/v1/live-videos/${payload.liveId}/gift-atd`, {
    amount: payload.amount,
    session_id: payload.sessionId,
  });
};

export const getSession = () => axios.post('/api/v1/session');

export const endWatchLiveVideo = () => {
  return axios.put('/api/v1/user/end/watch/live_videos');
};

export const fetchLuckyBuyLiveId = (id: number) => {
  return axios.get(`/api/v1/lucky/by-livestream/${id}/detail`);
};
export const fetchTicketByUser = (params: { lottery_game_id: string }) => {
  return axios.get(`/api/v1/lottery/ticket`, { params });
};
export const createConversation = (payload: CreateChatConversationPayload) => {
  return AxiosChatInstance.post('/v2/me/chat/conversations', payload);
};
export const getProductsByLiveId = (liveId: string | number) => {
  return axios.get(`/api/v1/products/live-stream/${liveId}/lived`);
};

export const useFetchProductDepositQuery = (
  payload: ProductDepositPayload,
  options?: QueryObserverOptions<
    AxiosResponse<ProductDepositResponse>,
    AxiosErrorResponse,
    ProductDepositResponse,
    AxiosResponse<ProductDepositResponse>,
    (string | ProductDepositPayload)[]
  >
) => {
  return useQuery([QueryKey.Products, payload], () => fetchProductDeposit(payload), options);
};
export const useFetchProductLiveIdQuery = (
  liveId: string | number,
  options?: QueryObserverOptions<
    AxiosResponse<ProductResponse>,
    AxiosErrorResponse,
    ProductResponse,
    AxiosResponse<ProductResponse>,
    (string | number)[]
  >
) => {
  return useQuery([QueryKey.Products, liveId], () => getProductsByLiveId(liveId), options);
};
export const useListOfParticipantsQuery = (
  payload: ListOfParticipantsPayload,
  options?: QueryObserverOptions<
    AxiosResponse<ListOfParticipantsResponse>,
    AxiosErrorResponse,
    ListOfParticipantsResponse,
    AxiosResponse<ListOfParticipantsResponse>,
    (string | ListOfParticipantsPayload)[]
  >
) => {
  return useQuery([QueryKey.ListParticipants, payload], () => fetchListOfParticipants(payload), options);
};
export const useStickerQuery = (
  payload: StickerPayload,
  options?: QueryObserverOptions<
    AxiosResponse<StickerQueryResponse>,
    AxiosErrorResponse,
    StickerQueryResponse,
    AxiosResponse<StickerQueryResponse>,
    (string | StickerPayload)[]
  >
) => {
  return useQuery([QueryKey.Sticker, payload], () => fetchSticker(payload), options);
};
export const useFetchLiveVideosQuery = (
  payload: LiveVideosQueryPayload,
  options?: QueryObserverOptions<
    AxiosResponse<LiveVideosQueryResponse>,
    AxiosErrorResponse,
    LiveVideosQueryResponse,
    AxiosResponse<LiveVideosQueryResponse>,
    (string | LiveVideosQueryPayload)[]
  >
) => {
  return useQuery([QueryKey.LiveVideos, payload], () => fetchLiveVideos(payload), options);
};
export const useFetchLuckyBuyLiveIdQuery = (
  id: number,
  options?: QueryObserverOptions<
    AxiosResponse<LuckyDetailResponse>,
    AxiosErrorResponse,
    LuckyDetailResponse,
    AxiosResponse<LuckyDetailResponse>,
    (string | number)[]
  >
) => {
  return useQuery([QueryKey.Lucky, id], () => fetchLuckyBuyLiveId(id), options);
};
export const useTicketByUserQuery = (
  params: CreateTicketType,
  options?: QueryObserverOptions<
    AxiosResponse<TicketByUserResponse>,
    AxiosErrorResponse,
    TicketByUserResponse,
    AxiosResponse<TicketByUserResponse>,
    (string | CreateTicketType)[]
  >
) => {
  return useQuery([QueryKey.Lucky, params], () => fetchTicketByUser(params), options);
};
export const useFetchLiveVideosHomeQuery = (
  payload: LiveVideosQueryPayload,
  options?: QueryObserverOptions<
    AxiosResponse<LiveVideosHomeQueryResponse>,
    AxiosErrorResponse,
    LiveVideosHomeQueryResponse,
    AxiosResponse<LiveVideosHomeQueryResponse>,
    (string | LiveVideosQueryPayload)[]
  >
) => {
  return useQuery([QueryKey.LiveVideos, payload], () => fetchLiveVideosHome(), options);
};
export const useFetchTrackingVideo = (
  payload: number,
  options?: QueryObserverOptions<AxiosResponse<any>, AxiosErrorResponse, any, AxiosResponse<any>, (string | number)[]>
) => {
  return useQuery([QueryKey.TrackingVideo, payload], () => trackingVideo(payload), options);
};
export const useFetchLiveVideoQuery = (
  id: number,
  options?: QueryObserverOptions<
    AxiosResponse<LiveVideoQueryResponse>,
    AxiosErrorResponse,
    LiveVideoQueryResponse,
    AxiosResponse<LiveVideoQueryResponse>,
    (string | number)[]
  >
) => {
  return useQuery([QueryKey.LiveVideos, id], () => fetchLiveVideo(id), options);
};
export const useFetchStreamersQuery = (
  payload: StreamersQueryPayload,
  options?: QueryObserverOptions<
    AxiosResponse<StreamersQueryResponse>,
    AxiosErrorResponse,
    StreamersQueryResponse,
    AxiosResponse<StreamersQueryResponse>,
    (string | StreamersQueryPayload)[]
  >
) => {
  return useQuery([QueryKey.Streams, payload], () => fetchStreamers(payload), options);
};

export const useFetchCommentsQuery = (
  payload: fetchCommentPayload,
  options?: QueryObserverOptions<
    AxiosResponse<CommentsQueryResponse>,
    AxiosErrorResponse,
    CommentsQueryResponse,
    AxiosResponse<CommentsQueryResponse>,
    (string | fetchCommentPayload)[]
  >
) => {
  return useQuery([QueryKey.LiveVideoComments, payload], () => fetchComments(payload), options);
};
export const useFetchReplyCommentByIdQuery = (
  id: number,
  options?: QueryObserverOptions<
    AxiosResponse<ReplyCommentQueryResponse>,
    AxiosErrorResponse,
    ReplyCommentQueryResponse,
    AxiosResponse<ReplyCommentQueryResponse>,
    (string | number)[]
  >
) => {
  return useQuery([QueryKey.ReplyComment, id], () => fetchReplyCommentById(id), options);
};
export const useFetchCommentByIdQuery = (
  id: number,
  options?: QueryObserverOptions<
    AxiosResponse<Comment>,
    AxiosErrorResponse,
    Comment,
    AxiosResponse<Comment>,
    (string | number)[]
  >
) => {
  return useQuery([QueryKey.Comment, id], () => fetchCommentById(id), options);
};
export const useWatchVideoQuery = (
  options?: MutationOptions<AxiosResponse<any>, AxiosErrorResponse, unknown, unknown>
) => {
  return useMutation(() => watchLiveVideo(), options);
};
export const useEndWatchVideoQuery = (
  options?: MutationOptions<AxiosResponse<any>, AxiosErrorResponse, unknown, unknown>
) => {
  return useMutation(() => endWatchLiveVideo(), options);
};
export const useAddCommentQuery = (
  options?: MutationOptions<AxiosResponse<CommentQueryResponse>, AxiosErrorResponse, CommentQueryPayload, unknown>
) => {
  return useMutation((payload: CommentQueryPayload) => addCommentQuery(payload), options);
};
export const useCreateCodeTicketQuery = (
  options?: MutationOptions<AxiosResponse<any>, AxiosErrorResponse, CreateTicketType, unknown>
) => {
  return useMutation((payload: CreateTicketType) => createCodeTicketQuery(payload), options);
};
export const useOpenBoxLucky = (
  options?: MutationOptions<AxiosResponse<LuckyModel>, AxiosErrorResponse, number, unknown>
) => {
  return useMutation((id: number) => openLucky(id), options);
};

export const useCreateChatConversation = (
  options?: MutationOptions<AxiosResponse<any>, AxiosErrorResponse, CreateChatConversationPayload, unknown>
) => {
  return useMutation((payload: CreateChatConversationPayload) => createConversation(payload), options);
};
export const useSendSticker = (
  options?: MutationOptions<AxiosResponse<any>, AxiosErrorResponse, SendStickerPayload, unknown>
) => {
  return useMutation((payload: SendStickerPayload) => sendSticker(payload), options);
};

export const useGiftAtd = (
  options?: MutationOptions<AxiosResponse<any>, AxiosErrorResponse, giftAtdPayload, unknown>
) => {
  return useMutation((payload: giftAtdPayload) => giftAtd(payload), options);
};

export const useFetchAuctionByProductId = (
  payload: number | string,
  options?: QueryObserverOptions<
    AxiosResponse<AuctionResponse>,
    AxiosErrorResponse,
    AuctionResponse,
    AxiosResponse<AuctionResponse>,
    (string | number)[]
  >
) => {
  return useQuery([QueryKey.Auction, payload], () => fetchAuctionByProductId(payload), options);
};
export const useSendAuction = (
  options?: MutationOptions<AxiosResponse<SendAuctionPayload>, AxiosErrorResponse, SendAuctionPayload, unknown>
) => {
  return useMutation((payload: SendAuctionPayload) => sendAuction(payload), options);
};
