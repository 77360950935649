import * as React from 'react';
import { motion } from 'framer-motion';

const Path = (props: any) => (
  <motion.path fill="#525252" strokeWidth="3" stroke="#525252" strokeLinecap="round" {...props} />
);

export const MenuToggle = ({ toggle }: { toggle: () => void }) => (
  <button
    className="tw-flex tw-items-center tw-cursor-pointer tw-border-none tw-outline-none tw-w-10 tw-h-10 tw-bg-transparent tw-justify-center"
    onClick={toggle}
  >
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        variants={{
          closed: { d: 'M 2 2.5 L 20 2.5' },
          open: { d: 'M 3 16.5 L 17 2.5' },
        }}
        fill="currentColor"
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
        fill="currentColor"
      />
      <Path
        variants={{
          closed: { d: 'M 2 16.346 L 20 16.346' },
          open: { d: 'M 3 2.5 L 17 16.346' },
        }}
        fill="white"
      />
    </svg>
  </button>
);
