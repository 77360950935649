import React, { ChangeEvent, forwardRef } from 'react';

import cm from '@/libs/tailwind-merge';

export enum InputVariant {
  Outlined = 'outlined',
  Standard = 'standard',
}

export type InputProps = {
  value?: string;
  className?: string;
  placeholder?: string;
  defaultValue?: string;
  disabled?: boolean;
  endAdornment?: () => void;
  containerClassName?: string;
  errorMessageClassName?: string;
  wrapperClassName?: string;
  variant?: InputVariant;
  startAdornment?: JSX.Element;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: any) => void;
  onFocus?: (e: any) => void;
  autoFocus?: boolean;
  type?: string;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      variant,
      onChange,
      className,
      placeholder,
      disabled,
      endAdornment,
      startAdornment,
      containerClassName,
      onKeyDown,
      onFocus,
      autoFocus,
      defaultValue,
      type = 'text',
    },
    ref
  ) => {
    const cs = cm('tw-border-none tw-outline-none tw-flex-1 tw-w-full', className);
    let variantCs = '';
    if (variant === InputVariant.Standard) {
      variantCs = 'tw-border-b tw-border-b-primary-main';
    }

    if (variant === InputVariant.Outlined) {
      variantCs = 'tw-border tw-border-primary-main';
    }

    const containerCs = cm('tw-flex tw-gap-x-2 tw-p-2 tw-items-center', variantCs, containerClassName);

    return (
      <div className={containerCs}>
        {startAdornment && startAdornment}

        <input
          ref={ref}
          type={type}
          value={value}
          className={cs}
          disabled={disabled}
          onKeyDown={onKeyDown}
          onChange={onChange}
          onFocus={onFocus}
          autoFocus={autoFocus || false}
          placeholder={placeholder}
        />

        {endAdornment && endAdornment()}
      </div>
    );
  }
);

Input.displayName = 'Input';

Input.defaultProps = {
  defaultValue: '',
  className: '',
  placeholder: '',
  onChange: () => {},
  containerClassName: '',
};
