import React from 'react';
type Icon = {
  className?: string;
};
export const XIcon = ({ className }: Icon) => {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 6 6" fill="none">
        <path d="M1 1L5 5" stroke="currentColor" strokeLinecap="round" />
        <path d="M5 1L1 5" stroke="currentColor" strokeLinecap="round" />
      </svg>
    </div>
  );
};
