import React from 'react';

export const LiveVideoIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M21.3309 7.44275C20.9119 7.17879 20.3969 7.15544 19.9579 7.37879L18.4759 8.12701C17.9279 8.40316 17.5879 8.96153 17.5879 9.58285V15.4164C17.5879 16.0377 17.9279 16.595 18.4759 16.8732L19.9569 17.6204C20.1579 17.724 20.3729 17.7737 20.5879 17.7737C20.8459 17.7737 21.1019 17.7006 21.3309 17.5575C21.7499 17.2945 21.9999 16.8387 21.9999 16.3392V8.66204C21.9999 8.16255 21.7499 7.70671 21.3309 7.44275"
      />
      <path
        fill="currentColor"
        d="M11.9051 20H6.11304C3.69102 20 2 18.3299 2 15.9391V9.06091C2 6.66904 3.69102 5 6.11304 5H11.9051C14.3271 5 16.0181 6.66904 16.0181 9.06091V15.9391C16.0181 18.3299 14.3271 20 11.9051 20Z"
      />
    </svg>
  );
};
