import { ScheduleIcon } from '@/components/icons/Schedule';
import React, { useState } from 'react';

import DatePicker from 'react-datepicker';
interface IFun {
  changeTime: (value: [Date, Date]) => void;
  startTime?: string;
  endTime?: string;
  className?: string;
}

export const DateRangePicker = ({ changeTime, startTime, endTime, className }: IFun) => {
  let start = startTime ? startTime : '05/05/2022';
  const [dateRange, setDateRange] = useState([new Date(start), endTime ? new Date(endTime) : new Date()]);
  const [startDate, endDate] = dateRange;
  const handleChange = (value: [Date, Date]) => {
    changeTime(value);
    setDateRange(value);
  };
  const cs = `tw-border tw-flex tw-border-gray-31 tw-rounded-5 tw-overflow-hidden tw-items-center date-picker-custom ${className}`;
  return (
    <>
      <div className={cs}>
        <div className=" tw-h-10 tw-text-secondary-70 tw-px-2 tw-flex tw-items-center tw-justify-center">
          <span className="tw-block tw-w-6 tw-h-6">
            <ScheduleIcon />
          </span>
        </div>
        <DatePicker
          className="tw-text-base tw-h-10 tw-px-1 tw-text-secondary-70 tw-focus:tw-outline-none tw-flex-1"
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd/MM/yyyy"
          onChange={(value: [Date, Date]) => handleChange(value)}
        />
      </div>
    </>
  );
};
