import axios, { AxiosResponse, AxiosErrorResponse } from '@/libs/axios';
import { useQuery, QueryObserverOptions, useMutation, MutationOptions } from 'react-query';

import { QueryKey } from '@/layouts/shared';
import {
  NotificationsQueryPayload,
  NotificationsQueryResponse,
  ReadNotificationQueryPayload,
  ReadNotificationQueryResponse,
  TotalByTabQueryResponse,
} from '@/features/notifications/list/const';

export const fetchNotifications = (params: string | NotificationsQueryPayload) => {
  return axios.get('/api/v1/notification/paging', { params });
};
export const fetchTotalByTab = () => {
  return axios.get('/api/v1/notification/total');
};
export const fetchNotificationTab = () => {
  return axios.get('/api/v1/masterdata/notification_tab_config');
};

export const useFetchNotificationsQuery = (
  payload: NotificationsQueryPayload,
  options?: QueryObserverOptions<
    AxiosResponse<NotificationsQueryResponse>,
    AxiosErrorResponse,
    NotificationsQueryResponse,
    AxiosResponse<NotificationsQueryResponse>,
    (string | NotificationsQueryPayload)[]
  >
) => {
  return useQuery(
    [QueryKey.Notifications, payload],
    ({ queryKey }) => {
      const [, params] = queryKey;
      return fetchNotifications(params);
    },
    options
  );
};

export const useReadNotificationQuery = (
  options?: MutationOptions<
    AxiosResponse<ReadNotificationQueryResponse>,
    AxiosErrorResponse,
    ReadNotificationQueryPayload,
    unknown
  >
) => {
  return useMutation((payload: ReadNotificationQueryPayload) => {
    const { id } = payload;

    return axios.put(`/api/v1/notification/${id}/read`);
  }, options);
};
export const useAllNotificationQuery = (
  options?: MutationOptions<AxiosResponse<any>, AxiosErrorResponse, unknown, unknown>
) => {
  return useMutation(() => {
    return axios.put(`/api/v1/notification/all/read`);
  }, options);
};
export const useFetchTotalByTab = (
  options?: QueryObserverOptions<
    AxiosResponse<TotalByTabQueryResponse>,
    AxiosErrorResponse,
    TotalByTabQueryResponse,
    AxiosResponse<TotalByTabQueryResponse>,
    string[]
  >
) => {
  return useQuery(
    [QueryKey.TotalByTab],
    ({ queryKey }) => {
      const [] = queryKey;
      return fetchTotalByTab();
    },
    options
  );
};
