import React, { ReactNode } from 'react';

import QueryProvider from '@/contexts/query';

import { Toaster } from 'react-hot-toast';
import EmbraceDataProvider from '@/contexts/profile';
import WebSocketProvider from './websocket';
type AppProviderProps = {
  pageProps: any;
  children: ReactNode;
};

const AppProvider = ({ children, pageProps }: AppProviderProps) => {
  return (
    <QueryProvider pageProps={pageProps}>
      <EmbraceDataProvider>
        <WebSocketProvider pageProps={pageProps}>
          <div>{children}</div>
          <Toaster position="top-right" />
        </WebSocketProvider>
      </EmbraceDataProvider>
    </QueryProvider>
  );
};

export default AppProvider;
