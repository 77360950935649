import React from 'react'

interface IBadge {
  size?: number,
  width?: number,
  height?:number,
  className?:string, 
  count: string
}


export const Badge = ( {size, width, height, className, count}: IBadge) => {
  if(size) {
    width = size
    height = size
  } 
  return (
    <div
      className={className} 
      style={{
        width: width ? width + 'px' : '24px',
        height: height ? height + 'px' : '24px'
      }}>
      {count}
    </div>
  )
}
