import React from 'react';

export const CheckIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" fill="transparent" />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        d="M12 18C13.3881 18 14.7333 17.5187 15.8064 16.6381C16.8794 15.7574 17.6139 14.532 17.8847 13.1705C18.1555 11.8091 17.9459 10.3958 17.2915 9.17162C16.6372 7.9474 15.5786 6.98794 14.2961 6.45672C13.0136 5.92551 11.5867 5.8554 10.2583 6.25836C8.92993 6.66131 7.78239 7.51239 7.01118 8.66658C6.23998 9.82077 5.89283 11.2067 6.02889 12.5881C6.16495 13.9695 6.7758 15.2611 7.75736 16.2426"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        d="M14.6665 10.6665L12.7352 12.984C12.0796 13.7707 11.7519 14.1641 11.3113 14.1841C10.8707 14.204 10.5087 13.842 9.78456 13.1179L9.33317 12.6665"
      />
    </svg>
  );
};

export const CheckFilledIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3504 8.50039C15.3504 4.4411 12.0597 1.15039 8.00039 1.15039C3.9411 1.15039 0.650391 4.4411 0.650391 8.50039C0.650391 12.5597 3.9411 15.8504 8.00039 15.8504C12.0597 15.8504 15.3504 12.5597 15.3504 8.50039Z"
        fill="currentColor"
      />
      <path
        d="M10.7037 6.73223L7.16816 10.2678L5.40039 8.5"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
