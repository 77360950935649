import axios, { AxiosResponse, AxiosErrorResponse } from '@/libs/axios';
import { useMutation, MutationOptions, useQuery, QueryObserverOptions } from 'react-query';

import {
  BalanceQueryResponse,
  DefaultParams,
  EventHistoryResponse,
  LuckyOpenedBoxResponse,
  MasterDataQueryResponse,
  ReferralQueryPayload,
  ReferralUserResponse,
  UpdateFacebookProfileQueryPayload,
  UpdateFacebookProfileQueryResponse,
  UpdateProfileQueryPayload,
  UpdateProfileQueryResponse,
} from '@/features/accounts/profile/const';
import { QueryKey } from '@/layouts/shared';

export const fetchAccountProfileServer = async (token: string | undefined) => {
  const profile = await axios.get('/api/v1/user/profile', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return profile;
};

export const fetchBalance = async () => {
  const balance = axios.get('/api/v1/user/balance?on_chain=true');
  return balance;
};
export const logOut = (payload: string) => {
  return axios.delete(`/api/v1/user/logout/${payload}`);
};

export const updateProfile = (payload: UpdateProfileQueryPayload) => {
  return axios.put('/api/v1/user/profile', payload);
};
export const updateFacebookProfile = (payload: UpdateFacebookProfileQueryPayload) => {
  return axios.put('/api/v1/user/facebook/profile', payload);
};
export const fetchReferral = (params: ReferralQueryPayload) => {
  return axios.get('/api/v1/user/referral/list', { params });
};
export const fetchOpenedBox = (params: DefaultParams) => {
  return axios.get('/api/v1/lucky/opened-box', { params });
};
export const fetchEventHistory = (params: DefaultParams) => {
  return axios.get('/api/v1/event/histories', { params });
};
export const fetchMasterData = async () => {
  const masterData = await axios.get('/api/v1/masterdata/list');
  return masterData;
};
export const useLogout = (
  payload: string,
  option?: QueryObserverOptions<AxiosResponse<any>, AxiosErrorResponse, any, AxiosResponse<any>, (string | any)[]>
) => {
  return useQuery([QueryKey.Logout, payload], () => logOut(payload), option);
};
export const useFetchReferralQuery = (
  params: ReferralQueryPayload,
  options?: QueryObserverOptions<
    AxiosResponse<ReferralUserResponse>,
    AxiosErrorResponse,
    ReferralUserResponse,
    AxiosResponse<ReferralUserResponse>,
    (string | ReferralQueryPayload)[]
  >
) => {
  return useQuery([QueryKey.Referral, params], () => fetchReferral(params), options);
};
export const useOpenedBoxQuery = (
  params: DefaultParams,
  options?: QueryObserverOptions<
    AxiosResponse<LuckyOpenedBoxResponse>,
    AxiosErrorResponse,
    LuckyOpenedBoxResponse,
    AxiosResponse<LuckyOpenedBoxResponse>,
    (string | DefaultParams)[]
  >
) => {
  return useQuery([QueryKey.Lucky, params], () => fetchOpenedBox(params), options);
};
export const useEventHistoryQuery = (
  params: DefaultParams,
  options?: QueryObserverOptions<
    AxiosResponse<EventHistoryResponse>,
    AxiosErrorResponse,
    EventHistoryResponse,
    AxiosResponse<EventHistoryResponse>,
    (string | DefaultParams)[]
  >
) => {
  return useQuery([QueryKey.EventHistory, params], () => fetchEventHistory(params), options);
};
export const useMasterDataQuery = (
  options?: QueryObserverOptions<
    AxiosResponse<MasterDataQueryResponse>,
    AxiosErrorResponse,
    MasterDataQueryResponse,
    AxiosResponse<MasterDataQueryResponse>,
    string[]
  >
) => {
  return useQuery([QueryKey.MasterData], () => fetchMasterData(), options);
};
export const uploadAvatar = (file: File) => {
  const formData = new FormData();

  formData.append('file', file);

  return axios.post('/api/v1/images/avatar/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteAccount = () => axios.delete('/api/v1/user');

export const useUpdateProfileQuery = (
  options?: MutationOptions<
    AxiosResponse<UpdateProfileQueryResponse>,
    AxiosErrorResponse,
    UpdateProfileQueryPayload,
    unknown
  >
) => {
  return useMutation((payload: UpdateProfileQueryPayload) => updateProfile(payload), options);
};
export const useDeleteAccount = (options?: MutationOptions<AxiosResponse<any>, AxiosErrorResponse, any, unknown>) => {
  return useMutation(() => deleteAccount(), options);
};
export const useUpdateFacebookProfileQuery = (
  options?: MutationOptions<
    AxiosResponse<UpdateFacebookProfileQueryResponse>,
    AxiosErrorResponse,
    UpdateFacebookProfileQueryPayload,
    unknown
  >
) => {
  return useMutation((payload: UpdateFacebookProfileQueryPayload) => updateFacebookProfile(payload), options);
};
export const useBalanceQuery = (
  options?: QueryObserverOptions<
    AxiosResponse<BalanceQueryResponse>,
    AxiosErrorResponse,
    BalanceQueryResponse,
    AxiosResponse<BalanceQueryResponse>,
    string[]
  >
) => {
  return useQuery([QueryKey.Balance], () => fetchBalance(), options);
};
