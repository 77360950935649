import React from 'react';

export const FolderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 34 34" fill="none">
      <path
        d="M4.22656 8.2265C4.22656 6.34088 4.22656 5.39807 4.81235 4.81229C5.39814 4.2265 6.34094 4.2265 8.22656 4.2265H25.5857C27.4713 4.2265 28.4141 4.2265 28.9999 4.81229C29.5857 5.39807 29.5857 6.34088 29.5857 8.2265V25.5856C29.5857 27.4712 29.5857 28.414 28.9999 28.9998C28.4141 29.5856 27.4713 29.5856 25.5857 29.5856H8.22656C6.34094 29.5856 5.39814 29.5856 4.81235 28.9998C4.22656 28.414 4.22656 27.4712 4.22656 25.5856V8.2265Z"
        stroke="currentColor"
      />
      <path
        d="M4.22656 9.86188H9.27076C10.2522 9.86188 10.7429 9.86188 11.1652 10.073C11.5875 10.2842 11.8819 10.6767 12.4708 11.4619L14.2973 13.8972C14.8861 14.6824 15.1806 15.0749 15.6029 15.2861C16.0251 15.4972 16.5159 15.4972 17.4973 15.4972H25.5857C27.4713 15.4972 28.4141 15.4972 28.9999 14.9114C29.5857 14.3257 29.5857 13.3829 29.5857 11.4972V11.2707"
        stroke="currentColor"
      />
      <path d="M9.86182 22.5414H21.1325" stroke="currentColor" strokeLinecap="round" />
    </svg>
  );
};
