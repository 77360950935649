import React from 'react';

export const CameraIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M8.09539 4.17889C8.26339 4.01089 8.49125 3.9165 8.72884 3.9165H14.1038C14.3414 3.9165 14.5693 4.01089 14.7373 4.17889L16.2666 5.70817H18.583C19.5725 5.70817 20.3747 6.51033 20.3747 7.49984V16.4582C20.3747 17.4477 19.5725 18.2498 18.583 18.2498H4.24968C3.26017 18.2498 2.45801 17.4477 2.45801 16.4582V7.49984C2.45801 6.51033 3.26016 5.70817 4.24967 5.70817H6.56611L8.09539 4.17889ZM9.09991 5.70817L7.57062 7.23745C7.40262 7.40545 7.17476 7.49984 6.93717 7.49984L4.24967 7.49984V16.4582H18.583V7.49984H15.8955C15.6579 7.49984 15.4301 7.40545 15.2621 7.23745L13.7328 5.70817H9.09991ZM11.4163 9.73942C10.4268 9.73942 9.62467 10.5416 9.62467 11.5311C9.62467 12.5206 10.4268 13.3228 11.4163 13.3228C12.4059 13.3228 13.208 12.5206 13.208 11.5311C13.208 10.5416 12.4059 9.73942 11.4163 9.73942ZM7.83301 11.5311C7.83301 9.55207 9.43732 7.94775 11.4163 7.94775C13.3954 7.94775 14.9997 9.55207 14.9997 11.5311C14.9997 13.5101 13.3954 15.1144 11.4163 15.1144C9.43732 15.1144 7.83301 13.5101 7.83301 11.5311Z"
      />
    </svg>
  );
};
