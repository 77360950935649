import { ProductTypes } from '@/features/consults/chat/const';
import { AuctionStatus, DiscountStatus, PagePath, PriceSymbol, ProductStatus } from '@/layouts/shared';
import { checkTime, getCity, toMoney } from '@/libs/app';
import { Icon, Image, Link, PinIcon, TicketIcon, WalletIcon } from '@/components';
import React from 'react';
import { TFunction } from 'next-i18next';
type ProductProps = {
  product: ProductTypes;
  t: TFunction;
  conversationId: number;
};

export const Product = ({ product, t, conversationId }: ProductProps) => {
  let isDiscount = product.product_discount
    ? checkTime(product.product_discount?.started_at, product.product_discount?.ended_at)
    : false;
  if (product.product_discount && product.product_discount?.status === DiscountStatus.Ended) isDiscount = false;
  return (
    <div className="tw-bg-white tw-py-3 tw-px-4 tw-flex tw-gap-x-3">
      <div
        className="tw-relative tw-w-16 tw-h-16 tw-rounded-5 tw-bg-center tw-bg-cover"
        style={{
          backgroundImage: `url('${product?.images && product?.images.length > 0 ? product.images[0]?.url : ''}')`,
        }}
      >
        {isDiscount && (
          <>
            <div className="tw-w-6 tw-h-6 tw-absolute tw-top-2 tw-right-2">
              <Image src="/sale.png" className="tw-w-full tw-h-full" alt="sale"></Image>
            </div>
          </>
        )}
      </div>

      <div className="tw-flex tw-flex-col tw-flex-1">
        <div className="tw-text-sm tw-text-secondary-90 tw-font-normal tw-line-clamp-2">
          {product.product_code} - {product.product_name}
        </div>
        {product.status === ProductStatus.Hide ? (
          <div className="tw-text-13 tw-text-secondary-70 tw-italic">Liên hệ An Thư để biết thêm chi tiết</div>
        ) : (
          <>
            {product.quotation ? (
              <div className="tw-text-warning-90">Liên hệ</div>
            ) : product.is_auction && product.auction ? (
              <div className="tw-text-sm tw-text-secondary-90 tw-font-medium tw-mt-1">
                <span className="tw-text-warning-90">
                  {toMoney(
                    product.auction.status === AuctionStatus.Pending
                      ? product.auction.origin_price || 0
                      : product.auction.sell_price || 0,
                    t
                  )}
                </span>
                &nbsp;~&nbsp;
                <span className="tw-text-primary-main">
                  {toMoney(
                    product.auction.status === AuctionStatus.Pending
                      ? product.auction.origin_token
                      : product.auction.sell_token,
                    t,
                    PriceSymbol.Atd
                  )}
                </span>
              </div>
            ) : (
              <>
                {isDiscount ? (
                  <>
                    <div className="tw-text-sm tw-text-secondary-50 tw-flex tw-items-center tw-font-normal tw-mt-1">
                      <span>Giá ban đầu: &nbsp;</span>
                      <div className="tw-line-through">
                        <span className="">{toMoney(product.price, t)}</span>&nbsp;~&nbsp;
                        <span className="">{toMoney(product.token, t, PriceSymbol.Atd)}</span>
                      </div>
                    </div>
                    <div className="tw-text-sm tw-text-secondary-90 tw-font-medium tw-mt-1">
                      <span className="tw-text-warning-90">
                        {toMoney(product.price - (product.price * product.product_discount.discount) / 100, t)}
                      </span>
                      &nbsp;~&nbsp;
                      <span className="tw-text-primary-main">
                        {toMoney(
                          product.token - (product.token * product.product_discount.discount) / 100,
                          t,
                          PriceSymbol.Atd
                        )}
                      </span>
                    </div>
                  </>
                ) : (
                  <div className="tw-text-sm tw-text-secondary-90 tw-font-medium tw-mt-1">
                    <span className="tw-text-warning-90">{toMoney(product.price, t)}</span>&nbsp;~&nbsp;
                    <span className="tw-text-primary-main">{toMoney(product.token, t, PriceSymbol.Atd)}</span>
                  </div>
                )}
              </>
            )}

            {!product.quotation && (
              <div className="tw-mt-1 tw-flex tw-items-end tw-justify-end">
                {product.status == ProductStatus.Availability ? (
                  <>
                    {product.is_auction ? (
                      <button
                        className="tw-flex  tw-justify-center tw-items-center tw-bg-primary-main tw-px-5 tw-text-base tw-py-1 tw-rounded-5 tw-text-white tw-opacity-50 tw-uppercase"
                        disabled
                      >
                        {t('Đặt cọc')}
                      </button>
                    ) : (
                      <Link
                        url={{
                          pathname: PagePath.ProductDepositPage,
                          query: { id: product.id, message_id: conversationId },
                        }}
                        className="tw-bg-primary-main !tw-text-white   tw-border tw-px-5 tw-py-1 tw-rounded-5 tw-text-base"
                      >
                        <div className="tw-flex tw-items-center tw-justify-center tw-gap-x-1 tw-uppercase">
                          <Icon icon={<WalletIcon />} />
                          <p>{t('Đặt cọc')}</p>
                        </div>
                      </Link>
                    )}
                  </>
                ) : (
                  <button
                    className="tw-flex  tw-justify-center tw-items-center tw-bg-primary-main tw-px-5 tw-text-base tw-py-1 tw-rounded-5 tw-text-white tw-opacity-50 tw-uppercase"
                    disabled
                  >
                    {t('Đã bán')}
                  </button>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
