import React from 'react';
import { IconProps } from '../const';

export const EmailIcon = ({ className }: IconProps) => {
  return (
    <svg className={className ?? ''} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 6.5L2 18.3947C2 19.4993 2.89543 20.3947 4 20.3947L20 20.3947C21.1046 20.3947 22 19.4993 22 18.3947L22 6.5C22 5.39543 21.1046 4.5 20 4.5L4 4.5C2.89543 4.5 2 5.39543 2 6.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M20.9492 6.61926L13.2193 12.5625C12.5004 13.1152 11.4996 13.1151 10.7809 12.5623L3.05448 6.61926"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
};
