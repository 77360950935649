import React from 'react';
import { Link, Image } from '@/components';
import Script from 'next/script';

export const Zalo = () => {
  const ZALO_OA_ID = process.env.ZALO_OA_ID || '';
  console.log('zalo');
  return (
    <>
      {ZALO_OA_ID ? (
        <>
          <div
            className="zalo-chat-widget md:!tw-right-[100px] !tw-bottom-[86px]"
            data-oaid={ZALO_OA_ID}
            data-welcome-message="Rất vui khi được hỗ trợ bạn!"
            data-autopopup="2"
            data-width=""
            data-height=""
          ></div>
          <Script
            src="https://sp.zalo.me/plugins/sdk.js"
            onLoad={() => {
              console.log('Script has loaded');
            }}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};
