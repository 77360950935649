import React from 'react'

export const UserSquareIcon =() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 25 24" fill="none">
<path d="M16.4852 21.4397C17.4496 21.113 18.1467 20.6695 18.4802 20.1707C18.8137 19.6718 18.7671 19.142 18.3469 18.6547C17.9267 18.1673 17.1536 17.7463 16.1344 17.4499C15.1152 17.1535 13.9003 16.9962 12.6574 16.9999C11.4145 17.0036 10.2051 17.1681 9.19614 17.4705C8.18722 17.773 7.42861 18.1985 7.02514 18.6883C6.62166 19.1781 6.59323 19.7081 6.94376 20.2049C7.2943 20.7018 8.00651 21.141 8.98198 21.462" stroke="currentColor" strokeWidth="1.2"/>
<circle cx="12.7002" cy="9.99976" r="3" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round"/>
<rect x="3.3002" y="2.6" width="18.8" height="18.8" rx="3.4" stroke="currentColor" strokeWidth="1.2"/>
</svg>
  )
}
