import React from 'react';

export const DiamondIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        d="M7.84373 20.8868L19.3817 31.9974C20.0378 32.6292 20.3658 32.9451 20.769 32.9451C21.1722 32.9451 21.5002 32.6292 22.1563 31.9974L33.6943 20.8868C34.1416 20.456 34.3653 20.2406 34.4335 19.9564C34.5017 19.6722 34.4001 19.3787 34.197 18.7919L32.0853 12.6916C31.6353 11.3916 31.4103 10.7416 30.8896 10.3708C30.3688 10 29.681 10 28.3054 10H13.2326C11.857 10 11.1692 10 10.6484 10.3708C10.1277 10.7416 9.90268 11.3916 9.4527 12.6916L7.34104 18.7919C7.1379 19.3787 7.03633 19.6722 7.10454 19.9564C7.17274 20.2406 7.3964 20.456 7.84373 20.8868Z"
      />
    </svg>
  );
};
export const DiamondField = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M16.0057 2.57812H3.99066L0.767578 6.99082L9.99835 17.4243L19.2291 6.99082L16.0057 2.57812Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.767578 6.99082H19.2291M12.5053 2.57812H7.49142L6.14642 6.99082L9.99835 17.4243L13.8503 6.99082L12.5053 2.57812Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
