import { CloseIcon } from '@/components/icons/Close';
import { CountryType } from '@/features/auth';
import React, { memo, useState } from 'react';
import { Icon } from '../Icon';
import { ModalBody } from '../ModalBody';
import { ModalHeader } from '../ModalHeader';
import { Image } from '../Image';
import { Input } from '../Input';
import { ModalCustom, SearchIcon } from '@/components';
type SelectCountryProps = {
  options: CountryType[];
  selectCountry: (country: CountryType) => void;
  onClose: () => void;
};
const SelectCountry = ({ options, selectCountry, onClose }: SelectCountryProps) => {
  const [listCountry, setListCountry] = useState<CountryType[]>(options);
  const filterCountry = (value: string) => {
    if (!value) setListCountry(options);
    const val = value.toLocaleLowerCase();
    const op = options.filter((v) => (v.code + ',' + v.label + ',' + v.phone).toLocaleLowerCase().includes(val));
    setListCountry(op);
  };
  return (
    <>
      <ModalCustom className="tw-p-6">
        <ModalHeader className="tw-flex tw-items-center">
          <div className="tw-text-xl tw-font-semibold tw-flex-1">Chọn mã vùng</div>
          <div className="tw-p-2 tw-cursor-pointer" onClick={onClose}>
            <Icon className="!tw-h-6 !tw-w-6" icon={<CloseIcon />} />
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="tw-relative tw-border tw-rounded-5 tw-border-primary-main tw-overflow-hidden tw-px-2">
            <Input
              startAdornment={<Icon className="!tw-w-8 !tw-h-8" icon={<SearchIcon />} />}
              className="tw-h-10 tw-w-full tw-text-black"
              containerClassName="tw-border-none tw-p-0 tw-text-primary-main"
              onChange={(event) => filterCountry(event.target.value)}
              autoFocus={true}
              placeholder="Search"
            />
          </div>
          <ul className="tw-h-80 tw-overflow-y-auto tw-mt-6">
            {listCountry.map((c) => {
              return (
                <li
                  key={c.code}
                  className="tw-py-2 hover:tw-bg-gray-200 tw-flex tw-items-center tw-gap-x-2 tw-px-2 tw-cursor-pointer"
                  onClick={() => selectCountry(c)}
                >
                  <Image
                    src={`https://flagcdn.com/w40/${c?.code.toLocaleLowerCase()}.png`}
                    alt={c?.code || 'country'}
                    className="tw-w-6 tw-h-4"
                  />
                  <div className="tw-flex-1 tw-line-clamp-1">{c.label}</div>
                  <div className="">{c.phone}</div>
                </li>
              );
            })}
          </ul>
        </ModalBody>
      </ModalCustom>
    </>
  );
};

export default memo(SelectCountry);
