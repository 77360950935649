import React from 'react';
import { IconProps } from '../const';

export const UserIcon = ({ width, height, className }: IconProps) => {
  const _w = width || '100%';
  const _h = height || '100%';
  const _cn = className || '';

  return (
    <svg width={_w} height={_h} className={_cn} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        d="M19.7274 19.4471C19.2716 18.1713 18.2672 17.0439 16.8701 16.2399C15.4729 15.4358 13.7611 15 12 15C10.2389 15 8.52706 15.4358 7.12991 16.2399C5.73276 17.0439 4.72839 18.1713 4.27259 19.4471"
      />
      <circle cx="12" cy="7" r="4" stroke="currentColor" strokeLinecap="round" />
    </svg>
  );
};

export const UserOutlineIcon = ({ width, height, className }: IconProps) => {
  const _w = width || '24';
  const _h = height || '24';
  const _cn = className || '';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={_w} height={_h} className={_cn} viewBox="0 0 25 24" fill="none">
      <path
        d="M12.666 12C15.4274 12 17.666 9.76142 17.666 7C17.666 4.23858 15.4274 2 12.666 2C9.90459 2 7.66602 4.23858 7.66602 7C7.66602 9.76142 9.90459 12 12.666 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2562 22C21.2562 18.13 17.4062 15 12.6662 15C7.92617 15 4.07617 18.13 4.07617 22"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
