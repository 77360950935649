import React, { ReactNode } from 'react';

type ModalBodyProps = {
  children: ReactNode;
  className?: string;
};

export const ModalBody = ({ children, className }: ModalBodyProps): JSX.Element => {
  return <div className={className}>{children}</div>;
};

ModalBody.defaultProps = {
  className: '',
};
