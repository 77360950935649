import React from 'react';

export const DiamondLightIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.5 14.0376C30.8688 14.8279 30.2432 15.6182 29.6008 16.4029C25.9093 20.9331 22.2179 25.4578 18.5209 29.9825C18.4814 30.027 18.442 30.0771 18.4025 30.1216C18.1264 30.4332 17.8559 30.4388 17.5854 30.1216C17.1176 29.5595 16.6555 28.9862 16.1933 28.4186C12.3723 23.7214 8.54 19.0298 4.71334 14.3326C4.44282 13.9987 4.43155 13.8929 4.66825 13.5201C6.09973 11.255 7.53684 8.99541 8.96832 6.72474C9.13176 6.46316 9.32901 6.35742 9.63334 6.35742C15.2127 6.35742 20.7921 6.35742 26.3715 6.35742C26.6758 6.35742 26.8731 6.46316 27.0365 6.71917C28.5187 9.07333 30.0122 11.4219 31.5 13.7761V14.0376ZM12.7274 14.366C14.4575 19.0409 16.1764 23.6936 17.9235 28.4074C19.7044 23.688 21.4571 19.0354 23.2211 14.366H12.7274ZM30.108 14.366H24.1792C22.4997 18.8127 20.8259 23.2595 19.1465 27.7062C19.1634 27.7173 19.1859 27.7229 19.2028 27.734C22.8266 23.2929 26.4504 18.8517 30.108 14.366ZM5.88557 14.3549C9.46426 18.7404 13.0035 23.0758 16.5371 27.4168C16.554 27.3445 16.5428 27.2888 16.5258 27.2332C14.9591 23.0035 13.398 18.7793 11.8369 14.5497C11.7806 14.405 11.7129 14.3549 11.5551 14.3549C9.74605 14.3549 7.93698 14.3549 6.12791 14.3549H5.88557ZM22.8999 13.4644C21.2486 11.4609 19.6368 9.49629 18.0024 7.51502C16.368 9.50186 14.7506 11.4664 13.105 13.4644H22.8999ZM12.254 13.086C13.8601 11.1325 15.4325 9.22359 17.0331 7.27571H10.1011C10.8225 9.21803 11.5269 11.1325 12.254 13.086ZM23.717 13.047C24.4553 11.0936 25.1711 9.19576 25.8924 7.27571H18.9718C20.561 9.2069 22.1221 11.1047 23.717 13.047ZM30.232 13.4588C29.0315 11.561 27.8593 9.70778 26.6533 7.80999C25.9319 9.71891 25.2331 11.5833 24.523 13.4588H30.232ZM9.34591 7.81555C8.13423 9.72448 6.962 11.5833 5.77286 13.4588H11.4311C10.7379 11.5777 10.0504 9.71891 9.34028 7.81555H9.34591Z"
        fill="currentColor"
      />
    </svg>
  );
};
