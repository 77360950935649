import JwtDecode from 'jwt-decode';

import { GetServerSidePropsContext } from 'next';
import { GetAuthResponse } from '@/libs/cookies/const';
import { getCookie, setCookies, checkCookies, removeCookies } from 'cookies-next';

export const TOKEN_NAMESPACE = 'token';
export const REFRESH_TOKEN = 'refresh_token';

export const cleanAuth = () => {
  removeCookies(REFRESH_TOKEN);
  removeCookies(TOKEN_NAMESPACE);
};

export const getAuth = (): GetAuthResponse => {
  const init = { isAuth: false };
  const token = getCookie(TOKEN_NAMESPACE) as string;
  const refreshToken = getCookie(REFRESH_TOKEN) as string;
  if (!token) {
    return init;
  }
  try {
    const { id, phone } = JwtDecode(token) as {
      id: number;
      phone: string;
    };

    return { id, phone, refreshToken, isAuth: true, token };
  } catch (ex) {
    return init;
  }
};

export const getAuthServer = ({ req, res }: GetServerSidePropsContext): GetAuthResponse => {
  const init = { isAuth: false, token: '', id: null };
  const token = getCookie(TOKEN_NAMESPACE, { req, res }) as string;
  if (!token) {
    return init;
  }

  try {
    const { id, phone } = JwtDecode(token) as {
      id: number;
      phone: string;
    };
    const refreshToken = getCookie(REFRESH_TOKEN, { req, res }) as string;
    return { id, phone, refreshToken, isAuth: true, token };
  } catch (ex) {
    return init;
  }
};

export const initAuth = (token: string, refreshToken: string): boolean => {
  try {
    const { exp } = JwtDecode(token) as { exp: number };
    const { expR } = JwtDecode(refreshToken) as { expR: number };
    setCookies(TOKEN_NAMESPACE, token, { expires: new Date(exp * 1000) });
    setCookies(REFRESH_TOKEN, refreshToken, { expires: new Date(expR * 1000) });
    return true;
  } catch (_) {
    return false;
  }
};

export { getCookie, setCookies, checkCookies, removeCookies };
