import { Button, ModalBody, ModalCustom, ModalFooter, ModalHeader } from '@/components';
import { PriceSymbol } from '@/layouts/shared';
import { toMoney } from '@/libs/app';
import { TFunction } from 'next-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useFetchAuctionByProductId, useSendAuction } from '../../../api';
import { Auction } from '../../../const';

type AuctionModalProps = {
  productName: string;
  productCode: string;
  t: TFunction;
  handleCloseModal: () => void;
  productId: number;
};
const AuctionModal = ({ productName, productCode, t, handleCloseModal, productId }: AuctionModalProps) => {
  const [price, setPrice] = useState<number>(0);

  const [auction, setAuction] = useState<Auction>();

  const { refetch } = useFetchAuctionByProductId(productId, {
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false,
    onError: () => console.log('Failed to fetch auction'),
    onSuccess: ({ data }) => setAuction(data),
  });
  useEffect(() => {
    refetch();
  }, [productId, refetch]);
  const handleChoosePrice = useCallback(
    (p) => {
      if (p !== price) {
        return setPrice(p);
      } else {
        return setPrice(0);
      }
    },
    [price]
  );
  const { mutate, isLoading } = useSendAuction({
    onSuccess() {
      toast.success('Bạn đã gửi giá đấu thành công!');
      handleCloseModal();
    },
  });
  const sendPriceAuction = useCallback(() => {
    if (!auction?.id) return toast.error('Đấu giá không tồn tại');
    if (!price) return toast.error('Vui lòng nhập giá tiền đấu giá');
    mutate({
      id: auction?.id,
      price: (auction?.sell_price || 0) + price,
    });
  }, [auction, price, mutate]);

  return (
    <ModalCustom className="tw-p-8 !tw-max-w-2xl">
      <ModalHeader className="tw-text-center tw-text-xl tw-font-normal">Thông tin đấu giá</ModalHeader>
      <ModalBody>
        <div className="">
          <p>Phiên bản đấu giá cho sản phẩm:</p>
          <p>
            {productCode}&nbsp;-&nbsp;{productName}
          </p>
          <p>
            Giá khởi điểm:&nbsp;{' '}
            <span className="tw-text-warning-90 tw-font-semibold">
              {toMoney(auction?.origin_price || 0, t, PriceSymbol.$)}
            </span>
          </p>
          <p className="">
            Giá đấu đến thời điểm hiện tại:&nbsp;{' '}
            <span className="tw-text-warning-90 tw-font-semibold">
              {toMoney(auction?.sell_price || 0, t, PriceSymbol.$)}
            </span>
          </p>
          <p>Chọn giá:</p>
        </div>
        <div className="tw-grid tw-grid-cols-4 tw-gap-5 tw-my-6">
          {auction?.auction_config.prices &&
            auction?.auction_config.prices.map((p) => (
              <Button
                key={`price-auction-${p}`}
                className={`tw-w-full tw-py-[10px] tw-cursor-pointer tw-border tw-rounded-lg  tw-text-center hover:tw-bg-primary-main hover:tw-border-primary-main hover:!tw-text-white tw-transition tw-duration-200  disabled:tw-cursor-not-allowed  ${
                  price === p
                    ? 'tw-bg-primary-main !tw-text-white tw-border-primary-main'
                    : 'tw-bg-white !tw-text-secondary-45  tw-border-secondary-45'
                }`}
                onClick={() => handleChoosePrice(p)}
              >
                +&nbsp;{toMoney(p, t, PriceSymbol.$)}
              </Button>
            ))}
        </div>
      </ModalBody>
      <ModalFooter className="tw-border-t tw-pt-5">
        <div className="tw-flex tw-justify-end">
          Giá đấu đã chọn:&nbsp;
          <span className="tw-text-warning-90">
            {toMoney(price ? (auction?.sell_price || 0) + price : 0, t, PriceSymbol.$)}
          </span>
        </div>
        <div className="tw-flex tw-justify-end tw-gap-x-5 tw-mt-6">
          <Button
            className="tw-bg-white hover:tw-bg-danger-main/10 tw-transition tw-duration-200 !tw-text-danger-main tw-text-lg tw-leading-5 !tw-w-40 tw-border-0"
            onClick={handleCloseModal}
          >
            HỦY
          </Button>
          <Button
            className="!tw-w-40 tw-text-lg tw-uppercase tw-leading-5"
            disabled={isLoading}
            onClick={sendPriceAuction}
          >
            Đấu giá
          </Button>
        </div>
      </ModalFooter>
    </ModalCustom>
  );
};

export default AuctionModal;
