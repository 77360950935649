import React from 'react';

const PhoneBoldIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M2.67666 5.3624C3.30278 4.69223 4.32876 3.66779 5.17311 2.83517C5.95479 2.06437 7.21104 2.07945 7.9816 2.86136L9.939 4.84762C10.5194 5.43658 10.497 6.37193 9.96675 7.00642C8.35993 8.92912 8.65743 10.1294 11.2694 12.7413C13.8837 15.3556 15.0477 15.6152 16.9704 14.0004C17.6015 13.4704 18.5337 13.451 19.1207 14.0295L21.1397 16.0194C21.9221 16.7905 21.9366 18.0477 21.1649 18.8295C20.3341 19.6713 19.3124 20.6944 18.6399 21.3256C14.5763 25.1405 -1.12886 9.43566 2.67666 5.3624Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};

export default PhoneBoldIcon;
