import { Image, Icon, LogoIcon } from '@/components';
import { Conversation } from '@/features/consults/chat/const';
import React, { memo, useState } from 'react';
import { ListChat } from './List';
type ListChatHiddenProps = {
  listChat: Conversation[];
  selectChat: (data: Conversation) => void;
  closeChat: (data: Conversation) => void;
  userId: number;
};
const ListChatHidden = ({ listChat, selectChat, closeChat, userId }: ListChatHiddenProps) => {
  const chat = listChat[0];
  const counter = listChat.reduce((a, b) => a + b.unread_message, 0);
  const [isShow, setShow] = useState<boolean>();
  return (
    <>
      <div className="tw-relative tw-cursor-pointer" onClick={() => setShow(!isShow)}>
        <div className={`tw-relative tw-w-10 tw-h-10 md:tw-w-14 md:tw-h-14 hover-show tw-ml-4 md:tw-ml-0`}>
          {counter > 0 && (
            <div
              className="tw-w-5 tw-h-5 tw-bg-red-500 tw-rounded-full tw-z-10 tw-absolute tw-text-white tw-flex tw-items-center tw-justify-center "
              style={{
                right: '-6px',
                top: '-6px',
                boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.25)',
                fontSize: '12px',
              }}
            >
              {counter}
            </div>
          )}

          <div
            className="tw-absolute tw-w-full tw-h-full tw-bottom-0 tw-top-0 tw-right-0 tw-bg-black tw-bg-opacity-50 tw-text-white tw-rounded-full tw-flex tw-justify-center tw-items-center"
            style={{ zIndex: '1' }}
          >
            + {listChat.length}
          </div>
          <div className="tw-h-full tw-w-full" style={{ zIndex: '2' }}>
            {chat?.type_image ? (
              <Image
                src={chat?.type_image}
                className="chat-popup-item tw-rounded-full tw-overflow-hidden tw-w-full tw-h-full"
                alt="chat"
              ></Image>
            ) : (
              <div className="chat-popup-item tw-rounded-full tw-w-full tw-h-full tw-bg-primary-main tw-text-white tw-flex tw-justify-center tw-items-center">
                <Icon icon={<LogoIcon width="32" height="24" />} className="!tw-w-full" />
              </div>
            )}
          </div>
        </div>
        {isShow && (
          <div
            className="tw-absolute tw-p-1 tw-bg-white tw-py-2 tw-bottom-0 tw-rounded-5 tw-drop-shadow "
            style={{
              right: 'calc(100% + 8px)',
            }}
          >
            <div className="tw-overflow-y-auto tw-my-1  tw-max-h-36 tw-w-44">
              {listChat.map((chat) => {
                return (
                  <div key={'hidden-' + chat.id}>
                    <ListChat userId={userId} chat={chat} selectChat={selectChat} closeChat={closeChat} />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(ListChatHidden);
