import { SocketChanel } from '@/layouts/shared';
import { getAuth } from '@/libs/cookies';
import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
type AppProviderProps = {
  children: ReactNode;
  pageProps: any;
};

export const WebSocketContext = createContext(null);

const WebSocketProvider = ({ children, pageProps }: AppProviderProps) => {
  const [waitingToReconnect, setWaitingToReconnect] = useState<boolean | null>(null);
  const clientRef = useRef<any>(null);
  const timeOut = useRef<any>(null);
  const retryRef = useRef<any>(null);
  const [socket, setSocket] = useState<any>();
  const { isAuth } = pageProps;
  const ping = (ws: any) => {
    let socketPayload = { method: 'ping' };
    ws.send(JSON.stringify(socketPayload));
  };
  useEffect(() => {
    const { token } = getAuth();
    if (waitingToReconnect) {
      return;
    }
    if (isAuth && !clientRef.current) {
      const ws = new WebSocket(`${process.env.WEBSOCKET_URL || 'wss://api-dev.anthu.tech/ws/realtime'}?token=${token}`);
      clientRef.current = ws;
      ws.onopen = () => {
        let socketPayload = {
          method: 'subscribe',
          type: 'unicast',
          channels: [{ id: SocketChanel.Consult }],
        };
        ws.send(JSON.stringify(socketPayload));
        timeOut.current = setInterval(() => {
          ping(ws);
        }, 15000);
      };

      setSocket(ws);
      ws.onclose = () => {
        if (clientRef.current) {
          console.log('ws closed by server');
        } else {
          console.log('ws closed by app component unmount');
          return;
        }
        console.log('ws closed');
        setWaitingToReconnect(true);
        retryRef.current = setTimeout(() => setWaitingToReconnect(null), 5000);
      };
      ws.onerror = (evt: any) => {
        setWaitingToReconnect(true);
        retryRef.current = setTimeout(() => setWaitingToReconnect(null), 5000);
      };
      return () => {
        console.log('close connection');
        ws.close();
        clientRef.current = null;
        clearInterval(timeOut.current);
        clearTimeout(retryRef.current);
        timeOut.current = null;
        retryRef.current = null;
      };
    }
  }, [waitingToReconnect, isAuth]);
  return <WebSocketContext.Provider value={socket}>{children}</WebSocketContext.Provider>;
};

export default WebSocketProvider;
export const useWebSocket = () => {
  const socket = useContext(WebSocketContext);
  return socket;
};
