import React from 'react';

export const DiamondFilledIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.6074 3.54932H8.01703C6.56482 3.54932 5.83871 3.54932 5.30404 3.95262C4.76937 4.35592 4.56989 5.05409 4.17094 6.45043L3.57126 8.54932H7.6699L8.6074 3.54932ZM3.11719 10.4735C3.11949 10.4902 3.12234 10.5068 3.12576 10.5233C3.17548 10.764 3.33779 10.9624 3.66241 11.3591L3.66241 11.3591L9.40831 18.3819L7.69493 10.5493H3.49983C3.36429 10.5493 3.23506 10.5224 3.11719 10.4735ZM14.5913 18.3819L20.3372 11.3591C20.6619 10.9624 20.8242 10.764 20.8739 10.5233C20.8773 10.5068 20.8802 10.4902 20.8825 10.4735C20.7646 10.5224 20.6354 10.5493 20.4998 10.5493H16.3047L14.5913 18.3819ZM20.4284 8.54932L19.8287 6.45043L19.8287 6.45042C19.4298 5.05409 19.2303 4.35592 18.6956 3.95262C18.1609 3.54932 17.4348 3.54932 15.9826 3.54932H15.3923L16.3298 8.54932H20.4284ZM13.3574 3.54932H10.6423L9.70475 8.54932H14.2949L13.3574 3.54932ZM11.9998 20.8698L9.74222 10.5493H14.2574L11.9998 20.8698Z"
      />
    </svg>
  );
};
export const StarIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 17.77L18.18 21.5L16.54 14.47L22 9.74L14.81 9.13L12 2.5L9.19 9.13L2 9.74L7.46 14.47L5.82 21.5L12 17.77Z"
      fill="#FFD600"
    />
  </svg>
);
