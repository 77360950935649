import React from 'react';

export const SearchIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18.3998 11.0998C18.3998 13.0998 17.5998 14.9998 16.2998 16.2998C14.9998 17.5998 13.0998 18.3998 11.0998 18.3998C7.0998 18.3998 3.7998 15.0998 3.7998 11.0998C3.7998 6.9998 7.0998 3.7998 11.0998 3.7998C15.0998 3.7998 18.3998 7.0998 18.3998 11.0998Z"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.2998 16.2998L20.2998 20.2998"
      />
    </svg>
  );
};
