import React, { memo, useEffect, useState } from 'react';
import { Button, Image, Loading } from '@/components';
import { StickerType } from '@/features/livestream/detail/const';
import { useStickerQuery } from '@/features/livestream/detail/api';

type SendStickerProps = {
  collectionStickers: string[];
  sendSticker: (sticker: string) => void;
};
const Sticker = ({ collectionStickers, sendSticker }: SendStickerProps) => {
  const [collection, setCollection] = useState<string>(collectionStickers[0]);
  const [listSticker, setListSticker] = useState<StickerType[]>([]);
  const [sticker, setSticker] = useState<StickerType>();
  const { refetch: refetchSticker, isLoading } = useStickerQuery(
    {
      collection: collection,
      offset: 0,
      limit: 100,
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => setListSticker(data.data),
    }
  );
  const chooseSticker = (s: StickerType) => {
    if (s) {
      setSticker(s);
      setTimeout(() => {
        sendSticker(s.icon);
      }, 100);
    }
  };
  useEffect(() => {
    refetchSticker();
  }, [collection, refetchSticker]);
  return (
    <div className="tw-px-4">
      <div className="tw-flex tw-items-center tw-w-11/12 tw-overflow-x-auto">
        {collectionStickers.map((c) => (
          <div
            className={`tw-pr-8  tw-cursor-pointer ${
              collection === c ? 'tw-font-semibold tw-text-primary-main' : 'tw-text-secondary-70'
            }`}
            onClick={() => setCollection(c)}
            key={'sticker-' + c}
          >
            <span className={`${collection == c ? ' tw-border-b tw-border-primary-main' : ''} tw-block`}> {c}</span>
          </div>
        ))}
      </div>
      <div className="tw-h-36 tw-mt-4 tw-leading-0 tw-justify-start tw-items-start tw-overflow-y-auto tw-flex tw-flex-wrap tw-relative ">
        {isLoading ? (
          <div className="tw-col-span-5 tw-flex tw-items-center tw-justify-center tw-w-full">
            <Loading />
          </div>
        ) : (
          listSticker.map((s) => (
            <div
              className={`tw-relative tw-mr-3 tw-mb-3 tw-rounded-5 tw-cursor-pointer ${
                sticker?.id == s.id ? 'tw-border tw-border-primary-main' : ''
              }`}
              key={`sticker-${s.name}`}
              onClick={() => chooseSticker(s)}
            >
              <Image alt={s.name} src={s.icon} className="tw-w-12 tw-h-12" objectFit="cover"></Image>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default memo(Sticker);
