import React, { ReactNode } from 'react';

type ModalHeaderProps = {
  className?: string;
  children: ReactNode;
};

export const ModalHeader = ({ children, className }: ModalHeaderProps): JSX.Element => {
  return <div className={className}>{children}</div>;
};

ModalHeader.defaultProps = {
  className: '',
};
