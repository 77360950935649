import React, { Fragment, useState, MouseEvent, useEffect } from 'react';

import { Icon, ArrowDownIcon, Button } from '@/components';
import { AnimatePresence, motion } from 'framer-motion';

export type OptionTypes = { text: string; value: string | boolean };

type DropdownProps = {
  value: string;
  children: string;
  className?: string;
  manualText?: string;
  options: OptionTypes[];
  classDr?: string;
  onChange: (value: string) => void;
};

export const Dropdown = ({ children, options, onChange, manualText, value, className, classDr }: DropdownProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>('');
  const onDropdownToggle = () => {
    return setOpen(!open);
  };

  const onDropDownClose = () => {
    return setOpen(false);
  };

  const onOptionChange = (e: MouseEvent<HTMLDivElement>) => {
    const { id } = e.currentTarget.dataset;
    if (manualText) {
      return setSelected(id || '');
    }
    setTimeout(() => {
      setOpen(false);
    }, 300);
    return onChange(id || '');
  };

  const onOptionManualClick = () => {
    setTimeout(() => {
      setOpen(false);
    }, 300);
    return onChange(selected as string);
  };

  useEffect(() => {
    if (open) {
      setSelected(value);
    }
  }, [open, value]);

  return (
    <Fragment>
      {open && <div className="tw-fixed tw-inset-0 tw-z-1" onClick={onDropDownClose} />}
      <div className={`tw-relative tw-inline-block tw-w-full sm:tw-w-32 ${className}`}>
        <div
          onClick={onDropdownToggle}
          className={`tw-cursor-pointer tw-flex tw-items-center tw-py-1 tw-px-2 tw-rounded-5 tw-border-primary-main ${classDr} ${
            open || value ? ' ' : 'sm:tw-border-transparent'
          }`}
        >
          <p className={`tw-flex-1 tw-truncate ${value ? 'tw-text-primary-main' : ''}`}>
            {value ? options.find(({ value: val }) => selected === val)?.text : children}
          </p>
          <motion.div
            initial={{ rotate: 0 }}
            animate={{ rotate: open ? '-180deg' : '0deg' }}
            transition={{ duration: 0.4 }}
          >
            <Icon icon={<ArrowDownIcon />} />
          </motion.div>
        </div>
        <AnimatePresence>
          {open && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}
              className="tw-absolute tw-border tw-border-primary-main tw-mt-2  tw-w-full sm:tw-min-w-max tw-bg-white tw-z-20 tw-rounded-5 tw-block tw-pt-2 tw-left-0"
            >
              <div className="tw-max-h-52 tw-overflow-y-auto">
                <Fragment>
                  {options.map((item, index) => {
                    const { value, text } = item;

                    return (
                      <div
                        data-id={value}
                        onClick={onOptionChange}
                        className={`tw-px-4 tw-py-2 tw-cursor-pointer tw-flex ${
                          selected === value ? 'tw-bg-primary-main tw-text-white' : ''
                        }`}
                        key={`item-${value}-${index}`}
                      >
                        {text}
                      </div>
                    );
                  })}
                </Fragment>
              </div>
              {manualText && (
                <div className="tw-flex tw-justify-center tw-p-4">
                  <Button disabled={!selected} onClick={onOptionManualClick} className="tw-rounded-5">
                    {manualText}
                  </Button>
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </Fragment>
  );
};

Dropdown.defaultProps = {
  options: [],
};
