/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';

import NextImage, { ImageProps } from 'next/image';

import { twMerge as cm } from 'tailwind-merge';
interface NextImageProps extends ImageProps {
  src: string;
  rounded?: string;
  className?: string;
  fallbackSrc?: string;
  alt?: string;
}

export const Avatar = React.memo(function (props: NextImageProps) {
  const {
    src = '/',
    fallbackSrc = '/default-avatar.svg',
    layout = 'fill',
    objectFit = 'cover',
    className,
    rounded,
    alt,
  } = props;
  const [imgSrc, setImgSrc] = useState('/');

  const cs = cm('tw-inline-block tw-relative', className);

  const onError = () => {
    setImgSrc(fallbackSrc);
  };

  useEffect(() => {
    if (src) {
      setImgSrc(src);
    }
  }, [src]);

  return (
    <div className={cs}>
      <NextImage
        alt={alt}
        src={imgSrc || fallbackSrc || ''}
        onError={onError}
        className={rounded}
        layout={layout}
        objectFit={objectFit}
      />
    </div>
  );
});
