import React from 'react';

import ReactOtpInput from 'react-otp-input';

type OtpInputProps = {
  value: string;
  onChange: (val: string) => void;
};

export const OtpInput = ({ value, onChange }: OtpInputProps) => {
  return (
    <ReactOtpInput
      numInputs={6}
      value={value}
      isInputNum={true}
      shouldAutoFocus={true}
      onChange={onChange}
      containerStyle="tw-flex tw-justify-center md:tw-justify-start tw-gap-x-2 sm:tw-gap-x-4"
      inputStyle="tw-outline-none tw-border tw-border-primary-main tw-rounded !tw-w-10 sm:!tw-w-12 tw-h-10 sm:tw-h-12"
    />
  );
};
