import React, { ReactNode } from 'react';
import { formatNumber } from '@/libs/app';
import { humanDate } from '@/libs/dayjs';
type ItemContent = {
  label: string;
  time: string;
  amount: number;
  currency: string;
};
interface IItemList {
  icon: ReactNode;
  iconBgColor: string;
  iconColor: string;
  content: ItemContent;
}
export const ItemList = ({ icon, iconBgColor, iconColor, content }: IItemList) => {
  return (
    <>
      <div className="tw-rounded-5 tw-bg-gray-20 tw-px-3 tw-py-4 tw-flex tw-items-center tw-justify-between">
        <div className="tw-flex tw-flex-1">
          <div
            className="icon tw-mr-2 tw-flex tw-justify-center tw-items-center"
            style={{
              backgroundColor: iconBgColor,
              width: '50px',
              height: '50px',
              borderRadius: '15px',
            }}
          >
            <span
              className="tw-block tw-w-6 tw-h-6"
              style={{
                color: iconColor,
              }}
            >
              {icon}
            </span>
          </div>
          <div className="item-info tw-flex-1">
            <div className="tw-text-black tw-text-base tw-font-normal">{content.label}</div>
            <div className="tw-text-secondary-50 tw-font-normal">{humanDate(content.time)}</div>
          </div>
        </div>
        <div className="tw-font-bold tw-text-lg md:tw-text-2xl amount" title="amount">
          {content.amount < 0 ? (
            <span className="tw-text-red-600">-</span>
          ) : (
            <span className="tw-text-primary-main">+</span>
          )}
          &nbsp;
          {content?.amount < 0 ? formatNumber(-content?.amount) : formatNumber(content?.amount)}
          &nbsp;{content.currency}
        </div>
      </div>
    </>
  );
};
