import { Icon } from '@/components';
import { LoadingSpin32 } from '@/components/icons/LoadingSpin';
import React from 'react';

export const Loading = ({ className }: { className?: string }) => {
  return (
    <div className={`tw-py-10 tw-flex tw-justify-center tw-items-center ${className}`}>
      <Icon className="tw-w-8 tw-h-8 tw-text-primary-main" icon={<LoadingSpin32 />} />
    </div>
  );
};
