import React from 'react';
import { IconProps } from '../const';

export const UsersIcon = ({ width, height, className }: IconProps) => {
  const _w = width || '24';
  const _h = height || '24';
  const _cn = className || '';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={_w} height={_h} className={_cn} viewBox="0 0 24 22" fill="none">
      <path
        d="M8.61626 8.61534C10.7192 8.61534 12.4239 6.91059 12.4239 4.80767C12.4239 2.70475 10.7192 1 8.61626 1C6.51335 1 4.80859 2.70475 4.80859 4.80767C4.80859 6.91059 6.51335 8.61534 8.61626 8.61534Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2307 21.3076H1V19.6153C1 17.5956 1.80233 15.6586 3.23048 14.2305C4.65863 12.8023 6.59563 12 8.61534 12C10.6351 12 12.572 12.8023 14.0002 14.2305C15.4283 15.6586 16.2307 17.5956 16.2307 19.6153V21.3076Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3848 1C16.3946 1 17.3631 1.40116 18.0772 2.11524C18.7913 2.82932 19.1924 3.79781 19.1924 4.80767C19.1924 5.81753 18.7913 6.78602 18.0772 7.5001C17.3631 8.21417 16.3946 8.61534 15.3848 8.61534"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0918 12.3242C19.5334 12.8726 20.7744 13.8458 21.6508 15.115C22.5271 16.3842 22.9974 17.8895 22.9995 19.4319V21.3103H20.461"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
