import { useTranslation } from 'next-i18next';
import React from 'react';

export const TimeLive = (props: any) => {
  const { t } = useTranslation('layout');
  const end = props.end == props.start ? '--:--' : props.end;
  return (
    <>
      <div className="time-line tw-relative line-custom">
        <div
          className="ranger tw-relative"
          style={{
            width: props.width,
            left: props.left,
          }}
        >
          <div className="tw-absolute time-line-start tw-font-light tw-text-10 sm:tw-text-sm">
            {t('component.timeline.start')} {props.start ? props.start : '--:--'}
          </div>
          <div className="tw-absolute time-line-end tw-font-light tw-text-10 sm:tw-text-sm tw-w-max">
            {t('component.timeline.end')} {end}
          </div>
        </div>
      </div>
    </>
  );
};
