import React, { Fragment } from 'react';

import { useRouter } from 'next/router';
import { PagePath } from '@/layouts/shared';
import { useTranslation } from 'next-i18next';
import {
  Icon,
  Link,
  Image,
  Avatar,
  BellIcon,
  LiveVideoIcon,
  ArrowDownIcon,
  DiamondFilledIcon,
  MessageIcon,
} from '@/components';

type SidebarPartialProps = {
  isAuth: boolean;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
};

const SidebarPartial = ({ isAuth, isOpen, setOpen }: SidebarPartialProps) => {
  const router = useRouter();

  const { t } = useTranslation('layout');

  const onSlidderToggle = () => {
    return setOpen(!isOpen);
  };

  let items = [
    { path: PagePath.ProductsPage, icon: <DiamondFilledIcon />, title: t('sidebar.product') },
    { path: PagePath.LiveVideosPage, icon: <LiveVideoIcon />, title: t('sidebar.live-video') },
    { path: PagePath.ConsultListChatPage, icon: <MessageIcon />, title: t('sidebar.consult') },
    ...(isAuth
      ? [
          {
            title: t('sidebar.info'),
            path: PagePath.AccountProfilePage,
            icon: <Avatar src='' className="tw-w-6 tw-h-6" alt={t('sidebar.info')} />,
          },
          {
            title: t('sidebar.notification'),
            path: PagePath.NotificationsPage,
            icon: <BellIcon />,
          },
        ]
      : [
          {
            path: PagePath.LoginPage,
            title: t('sidebar.login'),
            icon: <Avatar src='' className="tw-w-6 tw-h-6" alt={t('sidebar.login')} />,
          },
        ]),
  ];

  return (
    <>
      <div
        className={`tw-hidden lg:tw-block lg:tw-fixed tw-left-0 tw-top-0 tw-bottom-0 tw-border-r tw-border-secondary-60 ${
          isOpen ? 'tw-w-32' : 'tw-w-12'
        }`}
      >
        <div className="tw-flex tw-flex-col tw-h-full tw-gap-y-4 tw-pt-4">
          <Image
            alt="logo"
            src="/logo.svg"
            objectFit="contain"
            className={`tw-w-24 tw-h-9 tw-mx-auto tw-py-6 ${isOpen ? '' : 'tw-hidden'}`}
          />
          <div className={`tw-w-4/5 tw-border-b-secondary-60 tw-border tw-mx-auto ${isOpen ? '' : 'tw-hidden'}`} />
          <div className="tw-flex-1">
            <div className={`tw-flex tw-flex-col tw-gap-y-6 tw-mt-32 ${isOpen ? '' : 'tw-hidden'}`}>
              <Fragment>
                {items.map(({ path, icon }, index) => {
                  const isActive = path === router.pathname;
                  return (
                    <div
                      key={`${path}-${index}`}
                      className={`tw-cursor-pointer tw-text-center tw-group ${
                        isActive ? 'tw-border-r-2 tw-border-primary-main' : ''
                      }`}
                    >
                      <Link
                        url={path}
                        className={`tw-px-4 tw-py-3 tw-inline-block tw-align-middle tw-rounded-xl ${
                          isActive ? 'tw-bg-primary-80' : ''
                        }`}
                      >
                        <Icon
                          icon={icon}
                          className={`tw-flex tw-justify-center group-hover:tw-text-primary-main tw-w-6 tw-h-6 ${
                            isActive ? 'tw-text-primary-main' : 'tw-text-secondary-60'
                          }`}
                        />
                      </Link>
                    </div>
                  );
                })}
              </Fragment>
            </div>
          </div>
          <div
            onClick={onSlidderToggle}
            className="tw-flex tw-justify-center tw-bg-muted-main tw-py-2 tw-cursor-pointer"
          >
            <Icon icon={<ArrowDownIcon />} className={`${isOpen ? 'tw-rotate-90' : 'tw--rotate-90'} tw-w-6 tw-h-6`} />
          </div>
        </div>
      </div>
      <div
        className="tw-fixed lg:tw-hidden tw-bottom-0 tw-left-0 tw-right-0 tw-bg-white tw-z-50 tw-px-4"
        style={{ backgroundColor: '#FBFCFC' }}
      >
        <div className={`tw-flex tw-justify-center tw-gap-x-4 tw-w-full`}>
          <Fragment>
            {items.map(({ path, icon }, index) => {
              const isActive = path === router.pathname;
              return (
                <div
                  key={`${path}-${index}`}
                  className={`tw-cursor-pointer tw-text-center tw-group ${
                    isActive ? 'tw-border-t-2 tw-border-primary-main' : ''
                  }`}
                >
                  <Link
                    url={path}
                    className={`tw-px-4 tw-py-3 tw-inline-block tw-align-middle tw-rounded-xl ${
                      isActive ? 'tw-bg-primary-80' : ''
                    }`}
                  >
                    <Icon
                      icon={icon}
                      className={`tw-flex tw-justify-center group-hover:tw-text-primary-main tw-w-6 tw-h-6 ${
                        isActive ? 'tw-text-primary-main' : 'tw-text-secondary-60'
                      }`}
                    />
                  </Link>
                </div>
              );
            })}
          </Fragment>
        </div>
      </div>
    </>
  );
};

export default SidebarPartial;
