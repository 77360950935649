import React from 'react';

export const FlowerIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 19 18" fill="none">
      <path
        d="M9.49354 11.5273C10.5784 11.5273 11.4578 10.6537 11.4578 9.57615C11.4578 8.49856 10.5784 7.625 9.49354 7.625C8.40872 7.625 7.5293 8.49856 7.5293 9.57615C7.5293 10.6537 8.40872 11.5273 9.49354 11.5273Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8355 7.06297C17.557 6.21601 16.9538 5.51418 16.1584 5.11154C15.3629 4.70889 14.4402 4.63835 13.5928 4.91539C13.2903 5.00111 13.0031 5.13367 12.7416 5.30824C12.8262 5.0055 12.8703 4.69285 12.8725 4.3785C12.8725 3.48247 12.5166 2.62313 11.883 1.98954C11.2494 1.35595 10.3901 1 9.49404 1C8.598 1 7.73867 1.35595 7.10508 1.98954C6.47149 2.62313 6.11554 3.48247 6.11554 4.3785C6.11782 4.69285 6.16185 5.0055 6.24649 5.30824C5.98584 5.12907 5.69859 4.99208 5.39532 4.9023C4.97658 4.76559 4.53502 4.71269 4.09584 4.74662C3.65666 4.78056 3.22846 4.90067 2.8357 5.10009C2.44294 5.29951 2.0933 5.57434 1.80676 5.90888C1.52021 6.24343 1.30236 6.63114 1.16564 7.04987C1.02893 7.46861 0.976033 7.91017 1.00997 8.34935C1.04391 8.78853 1.16402 9.21672 1.36344 9.60948C1.56286 10.0022 1.83768 10.3519 2.17223 10.6384C2.50677 10.925 2.89448 11.1428 3.31322 11.2795C3.61993 11.3701 3.93655 11.4229 4.25605 11.4367C4.01199 11.6324 3.79218 11.8566 3.60131 12.1045C3.34059 12.4595 3.15296 12.8627 3.0493 13.2907C2.94564 13.7187 2.92802 14.1631 2.99746 14.598C3.06689 15.0329 3.22201 15.4497 3.4538 15.8241C3.68559 16.1986 3.98944 16.5233 4.34772 16.7794C5.07172 17.3026 5.9729 17.5191 6.85555 17.3818C7.73819 17.2445 8.53104 16.7645 9.0619 16.0461C9.24611 15.7894 9.39171 15.507 9.49404 15.208C9.60044 15.5078 9.75046 15.7902 9.93927 16.0461C10.4701 16.7645 11.263 17.2445 12.1456 17.3818C13.0283 17.5191 13.9294 17.3026 14.6535 16.7794C15.0117 16.5233 15.3156 16.1986 15.5474 15.8241C15.7792 15.4497 15.9343 15.0329 16.0037 14.598C16.0732 14.1631 16.0555 13.7187 15.9519 13.2907C15.8482 12.8627 15.6606 12.4595 15.3999 12.1045C15.209 11.8566 14.9892 11.6324 14.7451 11.4367C15.0602 11.4218 15.3724 11.3691 15.6749 11.2795C16.0944 11.1458 16.4834 10.9304 16.8194 10.6457C17.1554 10.3611 17.4317 10.0127 17.6326 9.62084C17.8334 9.22892 17.9547 8.80116 17.9895 8.36217C18.0244 7.92319 17.972 7.48164 17.8355 7.06297V7.06297Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
