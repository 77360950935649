import { ViewImage } from '@/components';
import { humanTimeDate } from '@/libs/dayjs';
import React from 'react';
import { UserConversation } from '../../const';

type renderImageProps = {
  userId: number;
  ownerId: number;
  ownerName: string;
  idMessAd: number;
  image: any;
  staff: UserConversation | undefined;
  createdAt: string;
  isPopup?: boolean;
  onError?: () => void;
  onSuccess?: () => void;
};
export const RenderImage = (props: renderImageProps) => {
  const {
    ownerId,
    ownerName,
    userId,
    idMessAd,
    staff,
    image,
    createdAt,
    onError,
    onSuccess,
    isPopup,
  } = props;
  let grid = '';
  if (image.length <= 3) grid = image.length;
  else grid = '3';
  return (
    <>
      <div
        className={`${
          ownerId === userId
            ? 'chat-user custom-chat-user cu-rec'
            : 'custom-chat-admin ca-rec tw-break-all'
        }  ${
          ownerId === userId && !idMessAd ? 'custom-chat-user cu-rec' : ''
        } tw-px-4 tw-pt-2 tw-pb-1 tw-rounded-2xl tw-w-fit tw-relative tw-max-w-70 tw-break-all`}
      >
        {staff && ownerId !== userId && (
          <div
            className={`tw-text-secondary-30 ${!isPopup && 'tw-font-semibold'}`}
            style={{
              fontSize: isPopup ? '10px' : '15px',
              lineHeight: '15px',
            }}
          >
            {`An Thư Diamond - ${staff?.user_name}`}
          </div>
        )}
        {ownerId === userId && ownerName && (
          <div
            className={`tw-text-white ${!isPopup && 'tw-font-semibold'}`}
            style={{
              fontSize: isPopup ? '10px' : '15px',
              lineHeight: '15px',
            }}
          >
            {ownerName}
          </div>
        )}
        <div
          className={`tw-grid tw-grid-cols-${grid} tw-gap-2 tw-max-w-70 tw-mx-2`}
        >
          {image.map((i: string) => {
            return (
              i && (
                <div className="tw-pt-2 tw-pb-1 tw-rounded-5" key={i}>
                  <ViewImage
                    image={i}
                    onSuccess={() => onSuccess && onSuccess()}
                    onError={onError}
                    className="tw-w-full"
                    classImg=""
                  />
                </div>
              )
            );
          })}
        </div>
        <div
          className={`tw-text-right ${
            ownerId === userId ? 'tw-text-white' : 'tw-text-secondary-50'
          }`}
          style={{
            fontSize: isPopup ? '11px' : '9px',
          }}
        >
          {humanTimeDate(createdAt)}
        </div>
      </div>
    </>
  );
};
