import { useQuery, QueryObserverOptions } from 'react-query';

import axios, { AxiosResponse, AxiosErrorResponse } from '@/libs/axios';

import { QueryKey } from '@/layouts/shared';
import {
  CategoriesQueryResponse,
  Category,
  ProductsQueryPayload,
  ProductsQueryResponse,
  ProductsRelatedQueryResponse,
  RelatedProductsQueryPayload,
} from '@/features/products/list/const';

export const fetchCategories = () => {
  return axios.get('/api/v1/category/list');
};
export const fetchCategoryDetail = (code: string | string[]) => {
  return axios.get(`/api/v1/category/${code}`);
};
export const fetchProductsSitemap = () => {
  return axios.get('/api/v1/products/site-map?limit=200');
};

export const fetchMasterData = () => {
  return axios.get('/api/v1/masterdata/list');
};
export const fetchProductsByIds = (ids: string) => {
  return axios.get(`/api/v1/products/${ids}/list`);
};
export const fetchProducts = (params: string | ProductsQueryPayload) => {
  return axios.get('/api/v1/products/paging', { params });
};
export const fetchRelatedProducts = (params: string | RelatedProductsQueryPayload) => {
  return axios.get('api/v1/products/related', { params });
};

export const useFetchProductsQuery = (
  payload: ProductsQueryPayload,
  options?: QueryObserverOptions<
    AxiosResponse<ProductsQueryResponse>,
    AxiosErrorResponse,
    ProductsQueryResponse,
    AxiosResponse<ProductsQueryResponse>,
    (string | ProductsQueryPayload)[]
  >
) => {
  return useQuery([QueryKey.Products, payload], () => fetchProducts(payload), options);
};
export const useFetchRelatedProductsQuery = (
  payload: RelatedProductsQueryPayload,
  options?: QueryObserverOptions<
    AxiosResponse<ProductsRelatedQueryResponse>,
    AxiosErrorResponse,
    ProductsRelatedQueryResponse,
    AxiosResponse<ProductsRelatedQueryResponse>,
    (string | RelatedProductsQueryPayload)[]
  >
) => {
  return useQuery([QueryKey.RelatedProduct, payload], () => fetchRelatedProducts(payload), options);
};

export const useFetchCategories = (
  options?: QueryObserverOptions<
    AxiosResponse<CategoriesQueryResponse>,
    AxiosErrorResponse,
    CategoriesQueryResponse,
    AxiosResponse<CategoriesQueryResponse>,
    string[]
  >
) => {
  return useQuery([QueryKey.Categories], () => fetchCategories(), options);
};
