import React from 'react';

export const UploadIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="currentColor"
        d="M5 21.6667V13C5 9.22876 5 7.34315 6.17157 6.17157C7.34315 5 9.22876 5 13 5H27C30.7712 5 32.6569 5 33.8284 6.17157C35 7.34315 35 9.22876 35 13V27C35 30.7712 35 32.6569 33.8284 33.8284C32.6569 35 30.7712 35 27 35H20"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M33 25.626L30.4365 23.0625L30.4146 23.0406C29.776 22.402 29.2671 21.8931 28.8259 21.5396C28.3759 21.1791 27.9347 20.928 27.4246 20.885C27.0157 20.8505 26.6046 20.9172 26.2275 21.0792C25.7572 21.2813 25.418 21.6591 25.1052 22.1434C24.7984 22.6183 24.4765 23.262 24.0727 24.0697L24.0727 24.0697L24.0588 24.0974C23.8301 24.5548 23.6768 24.8598 23.5395 25.0718C23.4037 25.2814 23.3301 25.3237 23.2967 25.337C23.2173 25.3685 23.1311 25.3791 23.0464 25.3678C23.0108 25.363 22.9291 25.3398 22.7465 25.1694C22.5619 24.9971 22.3392 24.7384 22.0064 24.3501L13.713 14.6744C13.5332 14.4647 13.2176 14.4405 13.0079 14.6202C12.7983 14.7999 12.774 15.1155 12.9537 15.3252L21.2472 25.0009L21.2662 25.0231L21.2662 25.0231C21.575 25.3834 21.8338 25.6854 22.0641 25.9004C22.3012 26.1217 22.5714 26.3133 22.9144 26.359C23.1684 26.3929 23.4269 26.3611 23.6652 26.2666C23.9869 26.1391 24.2025 25.8877 24.3788 25.6154C24.5501 25.351 24.728 24.9952 24.9402 24.5708L24.9532 24.5447C25.3741 23.7029 25.6718 23.1091 25.9451 22.686C26.2159 22.2669 26.4206 22.0847 26.6223 21.998C26.8486 21.9008 27.0952 21.8607 27.3406 21.8814C27.5594 21.8999 27.8112 22.008 28.2006 22.32C28.5937 22.635 29.0639 23.1042 29.7294 23.7696L32.9798 27.02C32.9864 27.0266 32.9931 27.033 33 27.0391C33 27.0261 33 27.0129 33 26.9998V25.626Z"
      />
      <circle cx="27.5" cy="12.5" r="2.5" fill="currentColor" />
      <path
        fill="currentColor"
        d="M13.3333 26.6667V26.1667H13.8333V26.6667H13.3333ZM5.31236 33.7238C5.09673 33.8963 4.78208 33.8613 4.60958 33.6457C4.43707 33.4301 4.47203 33.1154 4.68766 32.9429L5.31236 33.7238ZM12.8333 35V26.6667H13.8333V35H12.8333ZM13.3333 27.1667H5.00001V26.1667H13.3333V27.1667ZM13.6457 27.0571L5.31236 33.7238L4.68766 32.9429L13.021 26.2762L13.6457 27.0571Z"
      />
    </svg>
  );
};
