export enum ConversationType {
  Product = 'product',
  Direct = 'direct',
}

export interface ConversationModel {
  created_at: string;
  id: number;
  last_message_id: number;
  type: string;
  type_code: string;
  type_id: number;
  type_image: string;
  type_title: string;
  title: string;
  unread_message: number;
  user_id: number;
  last_message_content: string;
  last_message_time: string;
  last_order_code: string;
  last_message_type: string;
  last_images: string[];
  searched_content: string;
  message: string;
}
