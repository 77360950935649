import React, { Fragment, ReactNode } from 'react';
import { Icon, CloseFilledIcon } from '@/components';
import { ReactPortal } from '../Portal';
type ModalProps = {
  onClose?: () => void;
  children: ReactNode;
  className?: string;
  childrenClass?: string;
};

export const ModalCustom = ({ children, onClose, className, childrenClass }: ModalProps): JSX.Element => {
  const cs = `tw-relative tw-p-6 tw-w-4/5 tw-max-w-md tw-h-auto tw-bg-white tw-rounded-5 ${className}`;
  return (
    <div className="tw-relative">
      <div
        tabIndex={-1}
        aria-hidden="true"
        className="tw-overflow-y-auto tw-overflow-x-hidden tw-fixed tw-top-0 tw-right-0 tw-left-0 tw-z-999 tw-w-full md:tw-inset-0 tw-h-full tw-flex tw-items-center tw-justify-center"
      >
        <div className={cs}>
          {onClose && (
            <div
              className="tw-absolute tw-top-1 tw-right-1 tw-text-secondary-50 tw-cursor-pointer close-modal"
              id="close-modal"
              onClick={onClose}
            >
              <Icon icon={<CloseFilledIcon />} />
            </div>
          )}
          <div className={`tw-flex tw-flex-col tw-gap-y-4 tw-h-full ${childrenClass}`}>{children}</div>
        </div>
      </div>
      <div className="tw-bg-gray-900 tw-bg-opacity-50 tw-fixed tw-inset-0 tw-z-60" />
    </div>
  );
};
