import React, { useReducer, useRef, useState, useCallback, useEffect } from 'react';
import { useCreateDirectConversation } from '../chat/api';
import { XIcon } from '@/components';
import ConversationPartial from '@/features/consults/chatpopup/partials/conversation';
import { reducer, initialState } from '@/features/consults/chat/reducer';
import { useProfile } from '@/contexts/profile';
import ConversationNoProductPartial from './partials/ConversationNoProduct';
import ListChatBubble, { ChatDetailType } from './partials/ListChat';
import { PagePath } from '@/layouts/shared';
import { useRouter } from 'next/router';
import { Message } from '../chat/const';
export type IChat = {
  socket: any;
  isMobile: boolean;
  isAuth: boolean;
};

const ChatPopup = ({ socket, isMobile = false, isAuth }: IChat) => {
  const router = useRouter();
  const profile: any = useProfile();
  const userId = profile?.id || 0;
  const userName = profile?.fullname || '';
  const [chatDetail, setChatDetail] = useState<ChatDetailType>();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [newMessage, setNewMessage] = useState<Message>();
  const [ids, setIds] = useState<number[]>([]);

  const ZALO = process.env.ZALO || '';
  const FB = process.env.FB_PAGE_ID || '';
  const chatListRef = useRef<any>(null);
  const seenMessage = useCallback((id: number) => {
    chatListRef.current?.seenMessage(id);
  }, []);
  useEffect(() => {
    const _ = localStorage.getItem('listChatBubble') || '{}';
    const listChatBubble = JSON.parse(_);
    if (listChatBubble && listChatBubble.userId == userId) {
      let _ = listChatBubble.ids.filter((v: any) => !!v);
      if (_.sort().join('') !== ids.sort().join('')) setIds(Array.from(new Set(_)));
    }
  }, [ids, userId]);
  const getCs = (fb: string, zalo: string) => {
    if (fb && zalo) return 'md:tw-mb-32';
    if (fb || zalo) return 'md:tw-mb-32';
    return 'md:tw-mb-6';
  };
  const closeChatDetail = () => {
    setChatDetail({
      chatId: null,
      productId: null,
    });
  };
  const { mutate: mutateCreateDirectConversation, isLoading } = useCreateDirectConversation({
    onSuccess: ({ data }) => {
      if (isMobile) {
        router.push({ pathname: PagePath.ConsultChatPage, query: { id: data.conversation.id } });
      } else {
        setChatDetail({
          chatId: data.conversation.id,
          productId: null,
        });
      }
    },
  });
  const setPChatDetail = (data: ChatDetailType) => {
    setChatDetail(data);
  };
  return (
    <div
      className={`tw-fixed md:!tw-bottom-0 tw-flex tw-flex-col md:tw-flex-row tw-right-0 tw-px-3  md:tw-px-6 tw-gap-x-2 md:tw-gap-x-4 lg:tw-justify-end tw-items-end tw-z-50 tw-bottom-32`}
    >
      {chatDetail?.chatId && chatDetail?.productId && (
        <div className="tw-w-96 tw-relative tw-order-2 md:tw-order-1" style={{ height: '580px', width: '360px' }}>
          <div
            className="tw-w-8 tw-h-8 tw-bg-white tw-rounded-full tw-z-10 tw-absolute tw-text-black tw-flex tw-items-center tw-justify-center tw-cursor-pointer"
            style={{
              left: '-16px',
              top: '-16px',
              boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
            }}
            onClick={() => closeChatDetail()}
          >
            <span className="tw-w-3 tw-h-3" style={{ color: '#474D58' }}>
              <XIcon className="tw-w-full tw-h-full" />
            </span>
          </div>
          <ConversationPartial
            conversationId={chatDetail.chatId}
            newMessage={newMessage}
            ownerId={userId}
            ownerName={userName}
            socket={socket}
            dispatch={dispatch}
            state={state}
            productId={chatDetail.productId}
            seenMessage={seenMessage}
          />
        </div>
      )}
      {chatDetail?.chatId && !chatDetail?.productId && (
        <div className="tw-w-96 tw-relative tw-order-2 md:tw-order-1" style={{ height: '580px', width: '360px' }}>
          <div
            className="tw-w-8 tw-h-8 tw-bg-white tw-rounded-full tw-z-10 tw-absolute tw-text-black tw-flex tw-items-center tw-justify-center tw-cursor-pointer"
            style={{
              left: '-16px',
              top: '-16px',
              boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
            }}
            onClick={() => closeChatDetail()}
          >
            <span className="tw-w-3 tw-h-3" style={{ color: '#474D58' }}>
              <XIcon className="tw-w-full tw-h-full" />
            </span>
          </div>
          <ConversationNoProductPartial
            conversationId={chatDetail.chatId}
            ownerId={userId}
            ownerName={userName}
            socket={socket}
            dispatch={dispatch}
            state={state}
            seenMessage={seenMessage}
            loading={isLoading}
            newMessage={newMessage}
            createDirectConversation={() => mutateCreateDirectConversation({})}
          />
        </div>
      )}
      <div
        className={`tw-flex lg:tw-mb-24 ${
          chatDetail?.chatId ? `tw-flex-row  md:tw-mb-6` : `tw-flex-col`
        } md:tw-flex-col tw-order-1 md:tw-order-2 tw-w-full md:tw-w-auto tw-justify-end ${getCs(FB, ZALO)}`}
      >
        <ListChatBubble
          socket={socket}
          userId={userId}
          ref={chatListRef}
          setNewMessage={(data) => setNewMessage(data)}
          isChatDetail={chatDetail?.chatId ? true : false}
          setChatDetail={setPChatDetail}
          createNewDirectConversation={() => mutateCreateDirectConversation({})}
          isMobile={isMobile}
          listIds={ids}
          isAuth={isAuth}
        />
      </div>
    </div>
  );
};
export default ChatPopup;
