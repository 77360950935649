import React from 'react';

export const ChatLineIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
      <path
        d="M6 18C6 15.2101 6 13.8151 6.30667 12.6706C7.13887 9.5648 9.5648 7.13887 12.6706 6.30667C13.8151 6 15.2101 6 18 6V6C20.7899 6 22.1849 6 23.3294 6.30667C26.4352 7.13887 28.8611 9.5648 29.6933 12.6706C30 13.8151 30 15.2101 30 18V26C30 27.8856 30 28.8284 29.4142 29.4142C28.8284 30 27.8856 30 26 30H18C15.2101 30 13.8151 30 12.6706 29.6933C9.5648 28.8611 7.13887 26.4352 6.30667 23.3294C6 22.1849 6 20.7899 6 18V18Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M13.5 16.5L22.5 16.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M18 22.5H22.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
