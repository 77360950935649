import { useMasterData } from '@/contexts/profile';
import { ProductStatus } from '@/layouts/shared';
import NextHead from 'next/head';
import Router from 'next/router';
import { memo } from 'react';
export enum HeadChannel {
  Graph = 'Graph',
  Twitter = 'Twitter',
}

type HeadProps = {
  title: string;
  image?: string;
  description?: string;
  blacklist?: HeadChannel[];
  type?: string;
  productCode?: string;
  productId?: number;
  price?: number;
  category?: string;
  updated?: string;
  status?: string;
  handle?: string;
  seo?: any;
};

const MasterDataDefault = {
  author: 'Công ty TNHH An Thư The Diamond Store',
  keywords:
    'Kim cương, an thu, kim cương an thư, nhẫn kim cương, vòng cổ kim cương, bông tai kim cương, dây chuyền kim cương, giá kim cương, hoa tai kim cuong, kim cuong cao cap, lac tay kim cuong, lắc tay kim cương, trang sức kim cương, vong tay kim cuong, vòng tay kim cương, vong tay kim cuong',
  copyright: 'Công ty TNHH An Thư The Diamond Store',
  generator: 'Công ty TNHH An Thư The Diamond Store',
};
const MetaTag = ({
  title,
  description,
  image,
  type,
  productCode,
  productId,
  price,
  category,
  status,
  updated,
  blacklist = [],
  handle,
  seo,
}: HeadProps) => {
  const masterData: any = useMasterData();

  const { router } = Router;
  const getLocale = (l: string | undefined) => {
    if (l === 'en') return 'en_US';
    return 'vi_VN';
  };

  const fbDomainVerification = process.env.FB_DOMAIN_VERIFY;
  const FB_PAGE_ID = process.env.FB_PAGE_ID || '';
  const APP_URL = process.env.APP_URL || '';

  let href = handle ? APP_URL + handle : APP_URL + router?.asPath;
  let origin = APP_URL;
  return (
    <NextHead>
      <meta charSet="utf-8" />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <title>{title}</title>
      <meta name="google-site-verification" content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34="></meta>
      <meta name="title" content={seo?.title || title} />
      <meta content="INDEX,FOLLOW" name="robots" />
      <meta name="robots" content="index,follow" />
      <meta name="description" content={seo?.description || description} />
      <meta name="google" content="nositelinkssearchbox" />
      <meta name="googlebot" content="notranslate" />
      <meta
        name="keywords"
        content={seo?.keywords || masterData?.company_info?.keywords || MasterDataDefault.keywords}
      />
      <meta name="copyright" content={masterData?.company_info?.copyright || MasterDataDefault.copyright} />
      <meta name="author" content={masterData?.company_info?.author || MasterDataDefault.author} />
      <meta name="GENERATOR" content={masterData?.company_info?.generator || MasterDataDefault.generator} />
      <meta name="resource-type" content="Document" />
      <meta name="distribution" content="Global" />
      <meta name="revisit-after" content="1 days" />
      <meta name="facebook-domain-verification" content={fbDomainVerification} />
      <meta itemProp="name" content={masterData?.company_info?.author} />
      <meta itemProp="description" content={masterData?.company_info?.author} />
      {image && <meta itemProp="image" content={image} />}
      <meta httpEquiv="audience" content="General" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1"></meta>
      <meta httpEquiv="x-dns-prefetch-control" content="on" />
      <meta property="fb:pages" content={FB_PAGE_ID} />
      <link rel="canonical" href={href} />
      {!blacklist.includes(HeadChannel.Graph) && (
        <>
          <meta property="og:type" content="website" />
          <meta property="og:url" content={href} />
          <meta property="og:title" content={seo?.title || title} />
          <meta property="og:description" content={seo?.description || description} />
          {image && <meta property="og:image" content={image} />}

          <meta property="og:site_name" content={origin} />
          <meta property="og:locale" content={getLocale(router?.locale)} />
        </>
      )}
      {!blacklist.includes(HeadChannel.Twitter) && (
        <>
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={href} />
          <meta property="twitter:title" content={seo?.title || title} />
          <meta property="twitter:description" content={seo?.description || description} />
          {image && <meta property="twitter:image" content={image} />}
        </>
      )}
      {type && type == 'product' && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
              {
                "@context": "https://schema.org",
                  "@type": "Product",
                  "name": "${seo?.title || title}",
                  "url": "${href}",
                  "image": "${image}",
                  "description": "${seo?.description || description}",
                  "sku": "${productCode}",
                  "mpn": "${productCode}",
                  "productID": ${productId},
                  "category": "${category}",
                  "offers": {
                    "@type": "Offer",
                    "url": "${href}",
                    "priceCurrency": "VND",
                    "price": ${price},
                    "priceValidUntil": "${updated}",
                    "itemCondition": "https://schema.org/NewCondition",
                    "availability": "${
                      status == ProductStatus.Availability
                        ? 'https://schema.org/InStock'
                        : 'https://schema.org/OutOfStock'
                    }",
                    "seller": {
                      "@id": "${APP_URL}"
                    }
                  }
              }
            `,
          }}
        />
      )}
    </NextHead>
  );
};
export default memo(MetaTag);
MetaTag.defaultProps = {
  image: '',
  blacklist: [],
  description: '',
  url: process.env.APP_URL,
};
