import { ArrowLeftIcon } from '@/components/icons/ArrowLeft';
import { XIcon } from '@/components/icons/XIcon';
import React from 'react';
import { Icon } from '../Icon';
import { Image } from '../Image';
type IGallery = {
  images: string[];
  defaultImage: string;
};

export const Gallery = ({ defaultImage, images }: IGallery) => {
  const prevImage = () => {};
  const nextImage = () => {};
  return (
    <>
      <div className="gallery-background">
        <span className="gallery-close" style={{ color: '#474D58' }}>
          <Icon icon={<XIcon className="tw-w-4 tw-h-4" />} />
        </span>
        <div className="tw-px-5 gallery-image">
          <Image src={defaultImage} alt="image-zoom" className="image" />
        </div>
        <span onClick={prevImage} className="gallery-prev tw-text-primary-main">
          <Icon className="tw-h-6 tw-w-6" icon={<ArrowLeftIcon />}></Icon>
        </span>
        <span onClick={nextImage} className="gallery-next tw-text-primary-main">
          <Icon className="tw-h-6 tw-w-6 tw-rotate-180" icon={<ArrowLeftIcon />}></Icon>
        </span>
      </div>
    </>
  );
};
