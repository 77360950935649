import React, { useState, useEffect } from 'react';

import NextImage, { ImageProps } from 'next/image';

import { twMerge as cm } from 'tailwind-merge';

interface NextImageProps extends ImageProps {
  src: string;
  rounded: string;
  className?: string;
  fallbackSrc: string;
  alt?: string;
  priority?: boolean;
  onErrorImage?: () => void;
  onLoadImage?: (e: any) => void;
  width?: number | string;
  height?: number | string;
  style?: any;
  title?: string;
}

export const Image = ({
  src,
  fallbackSrc,
  onErrorImage,
  className,
  layout,
  objectFit,
  rounded,
  alt,
  priority,
  onLoadImage,
  width,
  height,
  style,
  title,
}: NextImageProps) => {
  const [imgSrc, setImgSrc] = useState('/');

  const cs = cm('tw-inline-block tw-relative', className);

  const onError = () => {
    setImgSrc(fallbackSrc);
    onErrorImage && onErrorImage();
  };
  const onLoad = (e: any) => {
    onLoadImage && onLoadImage(e);
  };
  const onLoadingComplete = (e: any) => {
    setImgSrc(src);
  };
  useEffect(() => {
    if (src) {
      setImgSrc(src);
    }
  }, [src]);
  return (
    <div className={cs}>
      <NextImage
        src={imgSrc}
        className={rounded}
        onError={onError}
        onLoad={onLoad}
        layout={layout}
        objectFit={objectFit}
        onLoadingComplete={onLoadingComplete}
        alt={alt}
        style={style}
        placeholder="blur"
        blurDataURL={fallbackSrc}
        priority={priority}
        width={width}
        height={height}
        title={title}
      />
    </div>
  );
};

Image.defaultProps = {
  src: '/',
  layout: 'fill',
  className: '',
  objectFit: 'cover',
  rounded: '',
  fallbackSrc: '/bg-default.png',
  priority: false,
  title: 'link',
};
