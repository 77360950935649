import React from 'react';

export const CreditCardIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4.5" y="9" width="27" height="19.5" rx="2" stroke="currentColor" />
      <path d="M10.5 22.5H10.5158" stroke="currentColor" strokeLinecap="round" />
      <path d="M4.5 16.5L31.5 16.5" stroke="currentColor" strokeLinecap="round" />
    </svg>
  );
};
