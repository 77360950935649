/* eslint-disable react-hooks/rules-of-hooks */
import { CheckIcon, ClockIcon, Icon, ViewImage } from '@/components';
import { OrderType, PaymentMethod, PriceSymbol } from '@/layouts/shared';
import { parseStatus, parseStatusColor, parseStatusColorBg, toHumanOrderStatus, toMoney } from '@/libs/app';
import { humanTimeDate } from '@/libs/dayjs';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useFetchOrderDetailByCode } from '@/features/consults/chat/api';
import { Image } from '@/components';

export const RenderOrder = (props: any) => {
  const { orderCode, t } = props;
  const [orderDetail, setOrderDetail] = useState<any>();
  const { refetch: fetchOrder } = useFetchOrderDetailByCode(orderCode, {
    enabled: false,
    onError: ({ message }) => {
      toast.error(message);
    },
    onSuccess: ({ data }) => {
      setOrderDetail(data);
    },
  });

  const parseStatusIcon = (status: string) => {
    switch (status) {
      case OrderType.Confirmed:
        return <CheckIcon />;
      case OrderType.Completed:
        return <CheckIcon />;
      default:
        return <ClockIcon />;
    }
  };
  useEffect(() => {
    fetchOrder();
  }, [fetchOrder, orderCode]);
  return (
    <div className="tw-py-3 tw-px-4 tw-bg-white tw-border tw-rounded-2xl tw-flex tw-flex-col">
      <div className="tw-flex tw-items-center">
        <div className="tw-w-10 tw-h-10 tw-rounded-2xl tw-bg-secondary-20 tw-flex tw-items-center tw-justify-center tw-overflow-hidden tw-mr-4">
          {orderDetail && orderDetail?.order_items && (
            <Image
              src={orderDetail?.order_items[0]?.products.images[0].url}
              alt={orderDetail?.order_items[0]?.products.product_name}
              className="tw-w-full tw-h-full tw-object-cover"
            ></Image>
          )}
        </div>
        <div className="tw-flex-1">
          <div className="tw-flex tw-justify-between tw-items-center">
            <span className="tw-font-light tw-text-xs">Mã đơn:&nbsp;</span>
            <span className="tw-font-bold tw-text-sm">{orderDetail?.code}</span>
          </div>
          <div className="tw-flex tw-justify-between tw-items-center">
            <span className="tw-font-light tw-text-xs">Tổng tiền:&nbsp;</span>
            <span
              className={`tw-font-bold tw-text-base ${
                orderDetail?.payment_method === PaymentMethod.Atd ? 'tw-text-primary-main' : 'tw-text-warning-90'
              }`}
            >
              {orderDetail?.payment_method === PaymentMethod.Atd
                ? toMoney(orderDetail?.total_amount, t, PriceSymbol.Atd)
                : toMoney(orderDetail?.total_amount, t, PriceSymbol.$)}
            </span>
          </div>
        </div>
      </div>
      <div className="tw-flex tw-justify-between tw-items-center tw-text-xs tw-mt-2">
        <div
          className={`tw-flex tw-gap-x-2 tw-items-center tw-justify-between ${parseStatusColor(orderDetail?.status)}`}
        >
          <Icon
            icon={parseStatusIcon(orderDetail?.status)}
            className={'tw-rounded-full ' + parseStatusColorBg(orderDetail?.status)}
          />
          <span className="tw-ml-4">
            {toHumanOrderStatus(parseStatus(orderDetail?.status, orderDetail?.cancel_type), t)}
          </span>
        </div>

        <p className="tw-text-secondary-80 tw-text-normal">{humanTimeDate(orderDetail?.created_at)}</p>
      </div>
    </div>
  );
};
