import React from 'react';

export const PinIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M12.398 19.804L12.6283 20.2478L12.398 19.804ZM11.602 19.804L11.3717 20.2478H11.3717L11.602 19.804ZM18.5 11C18.5 13.327 17.3141 15.2164 15.9 16.6426C14.4865 18.0684 12.8868 18.9872 12.1678 19.3601L12.6283 20.2478C13.3922 19.8516 15.0935 18.8765 16.6102 17.3467C18.1264 15.8173 19.5 13.6894 19.5 11H18.5ZM12 4.5C15.5899 4.5 18.5 7.41015 18.5 11H19.5C19.5 6.85786 16.1421 3.5 12 3.5V4.5ZM5.5 11C5.5 7.41015 8.41015 4.5 12 4.5V3.5C7.85786 3.5 4.5 6.85786 4.5 11H5.5ZM11.8322 19.3601C11.1132 18.9872 9.51351 18.0684 8.09996 16.6426C6.68593 15.2164 5.5 13.327 5.5 11H4.5C4.5 13.6894 5.87358 15.8173 7.38981 17.3467C8.9065 18.8765 10.6078 19.8516 11.3717 20.2478L11.8322 19.3601ZM12.1678 19.3601C12.0593 19.4164 11.9407 19.4164 11.8322 19.3601L11.3717 20.2478C11.769 20.4538 12.231 20.4538 12.6283 20.2478L12.1678 19.3601ZM14.5 11C14.5 12.3807 13.3807 13.5 12 13.5V14.5C13.933 14.5 15.5 12.933 15.5 11H14.5ZM12 8.5C13.3807 8.5 14.5 9.61929 14.5 11H15.5C15.5 9.067 13.933 7.5 12 7.5V8.5ZM9.5 11C9.5 9.61929 10.6193 8.5 12 8.5V7.5C10.067 7.5 8.5 9.067 8.5 11H9.5ZM12 13.5C10.6193 13.5 9.5 12.3807 9.5 11H8.5C8.5 12.933 10.067 14.5 12 14.5V13.5Z"
      />
    </svg>
  );
};
