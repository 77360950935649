import React from 'react';

export const PaperIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.66602 17.5V6.5C1.66602 4.61438 1.66602 3.67157 2.2518 3.08579C2.83759 2.5 3.7804 2.5 5.66602 2.5H13.3327C14.077 2.5 14.5232 2.5 14.8699 2.53948C13.0577 2.8039 11.666 4.36444 11.666 6.25V9.16685L11.5837 17.4725L9.16602 16.6667L6.66602 17.5L4.16602 16.6667L1.66602 17.5ZM17.166 9.16666L13.666 9.16666V6.25C13.666 5.2835 14.4495 4.5 15.416 4.5C16.3825 4.5 17.166 5.2835 17.166 6.25V9.16666ZM3.16602 5.83333C3.16602 5.28105 3.61373 4.83333 4.16602 4.83333H9.16602C9.7183 4.83333 10.166 5.28105 10.166 5.83333C10.166 6.38562 9.7183 6.83333 9.16602 6.83333H4.16602C3.61373 6.83333 3.16602 6.38562 3.16602 5.83333ZM3.16602 9.16667C3.16602 8.61438 3.61373 8.16667 4.16602 8.16667H5.83268C6.38497 8.16667 6.83268 8.61438 6.83268 9.16667C6.83268 9.71895 6.38497 10.1667 5.83268 10.1667H4.16602C3.61373 10.1667 3.16602 9.71895 3.16602 9.16667ZM3.16602 12.5C3.16602 11.9477 3.61373 11.5 4.16602 11.5H7.49935C8.05163 11.5 8.49935 11.9477 8.49935 12.5C8.49935 13.0523 8.05163 13.5 7.49935 13.5H4.16602C3.61373 13.5 3.16602 13.0523 3.16602 12.5Z"
      fill="currentColor"
    />
    <path
      d="M18.332 13L16.4007 15.3175C15.7452 16.1042 15.4174 16.4976 14.9768 16.5176C14.5362 16.5375 14.1742 16.1755 13.4501 15.4514L12.9987 15"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);
