import React, { ReactNode } from 'react';

import toast from 'react-hot-toast';

import { useTranslation } from 'next-i18next';
import { twMerge as cm } from 'tailwind-merge';
import { CopyIcon } from '@/components/icons/Copy';

type ButtonCopyProps = {
  value: string;
  className?: string;
  children: ReactNode;
};

export const ButtonCopy = ({ className, children, value }: ButtonCopyProps) => {
  const { t } = useTranslation('layout');

  const cs = cm(
    'tw-bg-primary-main tw-opacity-50 tw-text-white tw-p-1 tw-text-xs tw-flex tw-items-center tw-gap-x-2 tw-cursor-pointer',
    className
  );

  const onCopy = async () => {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(value);

      return toast.success(t('message.copy-success'));
    }
  };

  return (
    <div className={cs} onClick={onCopy}>
      <span className="tw-w-4 tw-h-4">
        <CopyIcon />
      </span>
      <span className="tw-hidden lg:tw-block tw-truncate">{children}</span>
    </div>
  );
};

ButtonCopy.defaultProps = {
  className: '',
};
