import React from 'react';

const UserBoldIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M19.9632 20.3821C20.0704 19.825 19.9426 19.2628 19.588 18.732C19.2335 18.2011 18.66 17.7133 17.9047 17.3002C17.1495 16.8871 16.2293 16.5579 15.2035 16.3337C14.1778 16.1096 13.0695 15.9956 11.9501 15.9991C10.8307 16.0026 9.72527 16.1235 8.70523 16.354C7.68518 16.5845 6.77324 16.9195 6.02836 17.3373C5.28348 17.7551 4.72224 18.2464 4.38093 18.7794C4.03963 19.3125 3.92585 19.8754 4.04696 20.4318"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="12" cy="8" r="4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default UserBoldIcon;
