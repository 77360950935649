type ShareIconProps = {
  color?: string;
};

export const ShareIcon = ({ color }: ShareIconProps) => {
  return (
    <>
      <svg width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.63 14.351C15.63 13.7987 15.182 13.3488 14.6303 13.3732C8.69435 13.6357 2.96167 16.1935 2.33208 19.4548C2.27446 19.7697 2.00061 19.9988 1.68048 20H1.5475C1.41966 19.9999 1.29589 19.9597 1.19303 19.8864C1.14286 19.8506 1.10217 19.8028 1.07393 19.748C1.01707 19.6378 0.991913 19.5132 1.00228 19.3883C1.45309 11.9441 7.31419 6.03095 14.6482 5.42164C15.1917 5.37649 15.63 4.93522 15.63 4.38986V1.94136C15.6274 1.76106 15.6996 1.58774 15.8295 1.46264L16.0955 1.19668C16.2203 1.0708 16.3903 1 16.5676 1C16.7448 1 16.9148 1.0708 17.0396 1.19668L24.7392 8.8962C24.8677 9.01686 24.9406 9.18531 24.9406 9.36163C24.9406 9.53794 24.8677 9.7064 24.7392 9.82706L17.0396 17.5266C16.9148 17.6525 16.7448 17.7233 16.5676 17.7233C16.3903 17.7233 16.2203 17.6525 16.0955 17.5266L15.8295 17.2606C15.6996 17.1355 15.6274 16.9622 15.63 16.7819V14.351Z"
          stroke={color || 'currentColor'}
          strokeWidth="2.5"
        />
      </svg>
    </>
  );
};
