import React from 'react';

export const CircleExclamationIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="11.25" stroke="currentColor" />
      <path
        fill="currentColor"
        d="M15.625 9.375C15.625 9.72018 15.3452 10 15 10C14.6548 10 14.375 9.72018 14.375 9.375C14.375 9.02982 14.6548 8.75 15 8.75C15.3452 8.75 15.625 9.02982 15.625 9.375Z"
      />
      <path d="M15 21.25V12.5" stroke="currentColor" />
    </svg>
  );
};
