import React from 'react';
interface IP {
  text?: string;
  fill?: string;
  color?: string;
}
export const PentagonIcon = ({ text, fill, color }: IP) => {
  return (
    <>
      <div
        className="tw-relative"
        style={{
          width: '73px',
          height: '28px',
        }}
      >
        <span
          className="tw-absolute tw-w-full tw-h-full tw-flex tw-items-center"
          style={{
            color: color ? color : '#ffffff',
            zIndex: '1',
            fontSize: '14px',
            lineHeight: '14px',
            textIndent: '10px',
          }}
        >
          {text}
        </span>
        <div
          className="tw-absolute tw-w-full tw-h-full tw-top-0 tw-left-0"
          style={{
            zIndex: '0',
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 73 28" fill="none">
            <path d="M0 0H61.6758L72.1758 14L61.6758 28H0V0Z" fill={fill ? fill : '#106657'} />
            <path d="M1.5 1.5H61.0274L70.3756 14L61.0274 26.5H1.5V14V1.5Z" stroke="white" />
          </svg>
        </div>
      </div>
    </>
  );
};
