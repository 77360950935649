import React from 'react';
export const VideoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 0H2C0.89543 0 0 0.89543 0 2V14C0 15.1046 0.89543 16 2 16H18C19.1046 16 20 15.1046 20 14V2C20 0.89543 19.1046 0 18 0ZM18 14H2V2H18V14ZM13.06 8.62098L9.17 11.221C8.94033 11.3762 8.64377 11.3921 8.39882 11.2623C8.15388 11.1325 8.00051 10.8782 8 10.601V5.40098C8.00051 5.12377 8.15388 4.86944 8.39882 4.73964C8.64377 4.60984 8.94033 4.62574 9.17 4.78098L13.06 7.38098C13.2696 7.51755 13.396 7.7508 13.396 8.00098C13.396 8.25116 13.2696 8.4844 13.06 8.62098Z"
      fill="currentColor"
    />
  </svg>
);
