import React, { useRef } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { ArrowLeftIcon, Icon, Image } from '@/components';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

// import required modules
import { EffectFade, Navigation, Scrollbar } from 'swiper';
import { ImageModel } from '@/models';

export type GalleryPartialProps = {
  images: ImageModel[];
};

const NewGallery = ({ images }: GalleryPartialProps) => {
  const items = (Array.isArray(images) ? images : []).map(({ url }) => ({
    original: url,
    thumbnail: url,
    originalAlt: 'Product image',
    thumbnailAlt: 'product thumbnail image',
  }));

  const swiperRef = useRef<any>();
  return (
    <>
      <div>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          keyboard={{
            enabled: true,
          }}
          scrollbar={{ enabled: true }}
          pagination={{
            clickable: true,
          }}
          // loop={true}
          navigation={false}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          modules={[EffectFade, Navigation, Scrollbar]}
          className="product-loop-swiper"
          id="swiper"
        >
          {items.map((item, key) => (
            <SwiperSlide
              key={`item-${key}`}
              className="tw-bg-gray-50 md:tw-rounded-5 md:tw-border tw-border-gray-70 tw-border-opacity-50 tw-overflow-hidden tw-relative"
            >
              <div className="tw-w-full p-100p tw-relative">
                <Image
                  src={item.original}
                  className="!tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full"
                  alt={item.originalAlt}
                />
              </div>
            </SwiperSlide>
          ))}
          <button
            className="custom-prev tw-w-6 tw-h-6 tw-rounded-full tw-absolute tw-top-1/2 tw-left-2 md:tw-left-3 tw--translate-y-1/2 tw-bg-white/[0.25]  tw-text-black tw-flex tw-items-center tw-justify-center tw-z-10"
            onClick={() => swiperRef.current?.slidePrev()}
          >
            <Icon icon={<ArrowLeftIcon />} />
          </button>
          <button
            className="custom-next tw-w-6 tw-h-6 tw-rounded-full tw-absolute tw-top-1/2 tw-right-2 md:tw-right-3 tw--translate-y-1/2 tw-bg-white/[0.25]  tw-text-black tw-flex tw-items-center tw-justify-center tw-rotate-180 tw-z-10"
            onClick={() => swiperRef.current?.slideNext()}
          >
            <Icon icon={<ArrowLeftIcon />} />
          </button>
        </Swiper>
      </div>
    </>
  );
};

export default NewGallery;
