import React, { useState } from 'react';
import { IMenu } from './HeaderPartial';
import { Link, Image } from '@/components';
import { AnimatePresence, motion } from 'framer-motion';
import { PagePath } from '@/layouts/shared';
type IMenuItem = {
  item: IMenu;
  isActive: boolean;
};
export const MenuItem = ({ item, isActive }: IMenuItem) => {
  const [show, setShow] = useState<boolean>(false);
  const showChild = (item: IMenu, isShow: boolean) => {
    if (item.children) setShow(isShow);
  };
  return (
    <div
      className="tw-px-4 lg:tw-px-6 tw-py-2 tw-h-full menu-parent tw-flex tw-items-center"
      onMouseEnter={() => showChild(item, true)}
      onMouseLeave={() => showChild(item, false)}
    >
      {item.path ? (
        <Link
          url={item.path}
          target={item.target}
          title={item.title}
          className={`${
            isActive || show ? 'tw-font-semibold tw-block menu-active' : 'menu-item'
          } tw-text-xl tw-uppercase tw-block tw-w-max !tw-text-secondary-70 hover:tw-font-bold tw-relative`}
        >
          {item.title}
        </Link>
      ) : (
        <div
          className={`${
            isActive || show ? 'tw-font-semibold menu-active' : ' menu-item'
          } tw-text-xl tw-uppercase tw-block tw-w-max !tw-text-secondary-70 hover:tw-font-bold tw-relative tw-cursor-pointer`}
          title={item.title}
        >
          {item.title}
        </div>
      )}
      <AnimatePresence>
        {show && item.showKey == 'blog' && item.children && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="menu-children tw-bg-white !tw-absolute tw-top-[100%] tw-left-0  tw-border-t tw-z-[999] tw-w-full"
          >
            <div className="tw-flex tw-items-center tw-pt-4 tw-pb-4 tw-justify-center tw-gap-x-10 lg:tw-gap-x-20">
              {item.children.map((c) => (
                <Link
                  url={{ pathname: PagePath.BlogPage, query: { id: c.slug } }}
                  className="tw-text-base lg:tw-text-xl tw-uppercase tw-block tw-w-max tw-py-2 !tw-text-secondary-70 hover:tw-font-bold tw-relative tw-cursor-pointer after:tw-absolute after:tw-w-0 after:tw-h-[3px] after:tw-bottom-0 after:tw-left-1/2 after:tw-bg-primary-main hover:after:tw-w-full after:tw-transaction after:tw-duration-300 after:tw--translate-x-1/2 "
                  key={c.name}
                >
                  {c.name}
                </Link>
              ))}
            </div>
          </motion.div>
        )}
        {show && item.showKey == 'category' && item.children && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="menu-children tw-bg-white !tw-absolute tw-top-[100%] tw-left-0  tw-border-t tw-z-[999] tw-w-full"
          >
            <div className="tw-py-4 tw-gap-x-20">
              <div className="container">
                <div className="tw-text-xl tw-font-semibold">Danh mục sản phẩm</div>
                <div className="tw-mt-7 tw-grid tw-grid-cols-4 tw-gap-x-6 lg:tw-gap-x-10">
                  <div className="tw-col-span-3">
                    <div className="tw-grid tw-grid-cols-3 tw-w-full tw-gap-x-4 lg:tw-gap-x-10 tw-gap-y-1">
                      {item.children.map((c) => (
                        <Link
                          url={{ pathname: PagePath.CategoryPage, query: { id: c.slug } }}
                          className="tw-text-base tw-uppercase tw-font-normal tw-block tw-w-max tw-py-2 !tw-text-secondary-70 hover:tw-font-bold tw-relative tw-cursor-pointer after:tw-absolute after:tw-w-0 after:tw-h-[3px] after:tw-bottom-0 after:tw-left-1/2 after:tw-bg-primary-main hover:after:tw-w-full after:tw-transaction after:tw-duration-300 after:tw--translate-x-1/2 "
                          key={c.name}
                        >
                          {c.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                  {item?.categoryHeader && (
                    <div className="tw-w-full tw-pb-[70%] tw-relative tw-h-0">
                      <Link url={{ pathname: PagePath.CategoryPage, query: { id: item?.categoryHeader?.code } }}>
                        <Image
                          alt={item?.categoryHeader?.code}
                          src={item.categoryHeader?.image}
                          className="tw-w-full tw-h-full !tw-absolute tw-top-0 tw-left-0"
                          objectFit="cover"
                        />
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
