import React from 'react';
import { IconProps } from '../const';

export const FacebookIcon = ({ width, height }: IconProps) => {
  const _w = width || '100%';
  const _h = height || '100%';

  return (
    <svg width={_w} height={_h} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.4348 17.0178H18.5492V31.2471H12.6631V17.0178H9.8623V12.015H12.6631V8.77465C12.5351 7.16351 13.1196 5.57721 14.2625 4.43437C15.4053 3.29153 16.9916 2.70698 18.6027 2.83499L22.9701 2.8533V7.70957H19.7974C19.4403 7.6914 19.0936 7.83364 18.8521 8.09747C18.6107 8.36129 18.4996 8.71914 18.5492 9.07333V12.0178H22.9476L22.4348 17.0178Z" fill="currentColor"/>
    </svg>
  );
};
