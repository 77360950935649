import React from 'react';

export const BellLightIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 34 34" fill="none">
    <path 
    d="M9.08322 12.6357C9.52605 8.65019 12.8948 5.63501 16.9048 5.63501V5.63501C20.9148 5.63501 24.2836 8.65019 24.7264 12.6357L25.0812 15.8282C25.1641 16.5748 25.2056 16.948 25.2807 17.3122C25.4124 17.9501 25.6215 18.5696 25.9034 19.1568C26.0643 19.4921 26.2575 19.8141 26.644 20.4582L27.7662 22.3285C28.5719 23.6714 28.9748 24.3429 28.6876 24.8502C28.4004 25.3575 27.6173 25.3575 26.0512 25.3575H7.75846C6.19235 25.3575 5.40929 25.3575 5.12206 24.8502C4.83484 24.3429 5.23771 23.6714 6.04347 22.3285L7.16565 20.4582C7.55211 19.8141 7.74533 19.4921 7.90625 19.1568C8.18812 18.5696 8.39722 17.9501 8.5289 17.3122C8.60407 16.948 8.64555 16.5748 8.7285 15.8282L9.08322 12.6357Z" 
    stroke="currentColor"/>
    <path d="M12.8229 25.9293C13.0636 26.9777 13.5942 27.9041 14.3323 28.5648C15.0704 29.2256 15.9748 29.5837 16.9051 29.5837C17.8354 29.5837 18.7398 29.2256 19.4779 28.5648C20.216 27.9041 20.7466 26.9777 20.9873 25.9293"
    stroke="currentColor" 
    strokeLinecap="round"/>
    </svg>
  );
};
