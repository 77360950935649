/* eslint-disable jsx-a11y/alt-text */
import { ArrowDownIcon } from '@/components/icons/ArrowDown';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Icon } from '../Icon';
import { Image } from '../Image';
type SelectOption = {
  label: string;
  value: string;
  image?: string;
};
interface ISelect {
  icon?: ReactNode;
  options: SelectOption[];
  handleSelect: (value: SelectOption) => void;
  className?: string;
}

export const SelectCustom = ({ icon, options, handleSelect, className }: ISelect) => {
  const [defaultOption, setDefaultOption] = useState(options[0]);
  const [show, setShow] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    document.addEventListener('click', handleClick);
  });
  const handleChange = (option: SelectOption) => {
    setDefaultOption(option);
    handleSelect(option);
    setShow(!show);
  };
  const handleClick = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current?.contains(event.target)) {
      setShow(false);
    }
  };
  return (
    <div className="tw-flex tw-items-center tw-border tw-relative tw-border-gray-31 tw-rounded-5" ref={wrapperRef}>
      {icon && (
        <div className="tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center ">
          <span className="tw-h-5 tw-w-5 tw-text-secondary-70 tw-cursor-pointer" onClick={() => setShow(!show)}>
            {icon}
          </span>
        </div>
      )}

      <div
        className={`${
          className ? className : 'tw-h-10 tw-w-48'
        } tw-flex tw-items-center tw-text-secondary-70 tw-flex-1 tw-cursor-pointer ${icon ? 'tw-px-1' : 'tw-px-4'}`}
        onClick={() => setShow(!show)}
      >
        {defaultOption.image && (
          <span className="tw-flex tw-items-center tw-mr-2">
            <Image
              className="tw-rounded-full tw-w-5 tw-h-5 tw-overflow-hidden"
              alt={defaultOption.label}
              src={defaultOption.image}
            ></Image>
          </span>
        )}
        <span className={`tw-ml-1 tw-flex-1 ${defaultOption.value ? 'tw-text-primary-main' : ''}`}>
          {defaultOption.label}
        </span>{' '}
        <motion.div
          className={`${icon ? 'tw-mr-3' : ''}`}
          animate={{ rotate: show ? '-180deg' : '0deg' }}
          transition={{
            duration: 0.4,
          }}
        >
          <div className="tw-text-secondary-70">
            <Icon icon={<ArrowDownIcon />} />
          </div>
        </motion.div>
      </div>

      <AnimatePresence>
        {show && (
          <motion.div
            className="tw-py-2 tw-absolute tw-z-10 top-full-8 tw-border tw-border-gray-31 tw-rounded-5 tw-bg-white tw-w-full tw-left-0"
            transition={{ duration: 0.2 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <ul className="tw-max-h-48 tw-overflow-y-auto tw-w-full tw-py-1">
              {options.map((o, i) => {
                return (
                  <li
                    className={`tw-text-secondary-70 tw-flex tw-items-center  tw-px-4 tw-py-1 tw-cursor-pointer ${
                      defaultOption.value === o.value ? 'tw-bg-primary-main !tw-text-white' : ''
                    }`}
                    key={'item -' + i}
                    onClick={() => handleChange(o)}
                  >
                    {o.image && (
                      <span className="tw-flex tw-items-center tw-mr-2">
                        <Image
                          className="tw-rounded-full tw-w-6 tw-h-6 tw-overflow-hidden"
                          src={o.image}
                          alt={o.label}
                        ></Image>
                      </span>
                    )}
                    {o?.label}
                  </li>
                );
              })}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
