import { ArrowDownIcon, Icon, Image } from '@/components';
import { AnimatePresence, motion } from 'framer-motion';
import React, { memo, ReactNode, useState } from 'react';
import { boolean } from 'yup';
type CollapseProps = {
  children: ReactNode;
  title: string;
};

const Collapse = ({ title, children }: CollapseProps) => {
  const [isShow, setIsShow] = useState<boolean>(false);
  return (
    <>
      <div className="tw-border-t tw-border-primary-main">
        <div className="tw-py-4 tw-flex tw-justify-between tw-items-center" onClick={() => setIsShow(!isShow)}>
          <div className="tw-text-sm tw-uppercase tw-font-bold"> {title} </div>
          <motion.div
            variants={{
              open: { rotate: 180 },
              closed: { rotate: 0 },
            }}
            initial={false}
            animate={isShow ? 'open' : 'closed'}
            transition={{ duration: 0.4 }}
          >
            <div className="" style={{ color: '#11695A' }}>
              <Icon icon={<ArrowDownIcon />} />
            </div>
          </motion.div>
        </div>
        <AnimatePresence>
          {isShow && (
            <motion.div
              variants={{
                closed: {
                  opacity: 0,
                },
                open: {
                  opacity: 1,
                },
              }}
              initial="closed"
              exit="closed"
              animate="open"
              className="tw-pb-4"
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};
export default memo(Collapse);
