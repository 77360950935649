import React, { ReactNode } from 'react';

import NextLink from 'next/link';
import cm from '@/libs/tailwind-merge';

import { UrlObject } from 'url';

type LinkProps = {
  className?: string;
  url: string | UrlObject;
  children?: string | ReactNode;
  target?: string;
  title?: string;
  disabled?: boolean;
};

export const Link = ({ children, url, className, target, title, disabled }: LinkProps) => {
  const cs = cm(
    'tw-text-primary-main hover:tw-text-primary-main visited:tw-text-primary-main tw-no-underline',
    className
  );

  return (
    <NextLink href={url} passHref>
      <a target={target} title={title} className={cs}>
        {children}
      </a>
    </NextLink>
  );
};
