import React from 'react';

export const CircleQuestionIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="13" r="9.5" stroke="currentColor" />
      <path
        fill="currentColor"
        d="M7.35547 11.2334H8.44092C8.51074 10.3257 9.12012 9.76074 10.0786 9.76074C11.0244 9.76074 11.6338 10.3384 11.6338 11.1255C11.6338 11.8047 11.3481 12.2046 10.6118 12.6553C9.74219 13.1758 9.33594 13.7471 9.34229 14.585V15.1689H10.4531V14.7563C10.4531 14.0898 10.6816 13.7344 11.4878 13.2583C12.3003 12.7695 12.8145 12.0649 12.8145 11.0684C12.8145 9.76074 11.7227 8.77051 10.123 8.77051C8.33936 8.77051 7.42529 9.8623 7.35547 11.2334ZM9.90088 18.0635C10.3135 18.0635 10.6245 17.7461 10.6245 17.3398C10.6245 16.9272 10.3135 16.6162 9.90088 16.6162C9.49463 16.6162 9.17725 16.9272 9.17725 17.3398C9.17725 17.7461 9.49463 18.0635 9.90088 18.0635Z"
      />
    </svg>
  );
};
