import React, { Fragment, ReactNode, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

type ReactPortalProps = {
  children: ReactNode;
  wrapperId: string;
};

export const ReactPortal = ({ children, wrapperId }: ReactPortalProps): JSX.Element => {
  const [wrapperElement, setWrapperElement] = useState<any>(null);
  function createWrapperAndAppendToBody(wrapperId: string) {
    const wrapperElement = document.createElement('div');
    wrapperElement.setAttribute('id', wrapperId);
    document.body.appendChild(wrapperElement);
    return wrapperElement;
  }
  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    // if element is not found with wrapperId or wrapperId is not provided,
    // create and append to body
    if (!element) {
      element = createWrapperAndAppendToBody(wrapperId);
    }
    setWrapperElement(element);
  }, [wrapperId]);

  // wrapperElement state will be null on the very first render.
  if (wrapperElement === null) return <></>;

  return createPortal(children, wrapperElement);
};
