import * as React from 'react';
import { m, motion } from 'framer-motion';
import { Link } from '@/components';
import Image from 'next/image';
import { Category } from '@/features/products/list/const';
import { PagePath } from '@/layouts/shared';
const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const colors = ['#FF008C', '#D309E1', '#9C1AFF', '#7700FF', '#4400FF'];

export const MenuItem = ({ item, handleClick }: { item: Category; handleClick: () => void }) => {
  // const style = { border: `2px solid ${colors[i]}` };
  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      className="tw-cursor-pointer tw-flex tw-items-center"
      onClick={handleClick}
    >
      <Link
        url={{ pathname: PagePath.CategoryPage, query: { id: item.code } }}
        className=" !tw-text-secondary-70 tw-font-roboto tw-text-xs !tw-font-medium  tw-uppercase"
      >
        {item.product_category_name}
      </Link>
    </motion.li>
  );
};
