/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Image from 'next/image';
import { humanDate } from '@/libs/dayjs';
import { TimeLive } from '../TimeLine';

export const LiveProduct = ({ data }: any) => {
  const getTimestampByDuration = (_duration: string) => {
    if (!_duration) return 0;
    let h = _duration.split(':')[0];
    let m = _duration.split(':')[1];
    let s = _duration.split(':')[2];
    return +h * 3600 + +m * 60 + +s;
  };
  const secondsToMinute = (time: number) => {
    const m = Math.floor(time / 60);
    const s = time - m * 60;
    if (!m && !s) return '--:--';
    return `${m}:${Math.round(s)}`;
  };
  const _played = data.played || 0;
  const _duration = data.live_stream.duration ? getTimestampByDuration(data.live_stream.duration) : 0;

  const _startP = new Date(data.start_datetime).getTime() / 1000;
  const _startV = new Date(data.start_datetime).getTime() / 1000 - _played;

  const _endV = _duration ? _startV + _duration : new Date().getTime() / 1000;
  const _endP = data.end_datetime ? new Date(data.end_datetime).getTime() / 1000 : _endV;

  const videoProductLength = Math.abs(_endP - _startP);

  let _l = Math.floor((videoProductLength / _duration) * 85);
  let _sP = Math.floor((_played / _duration) * 85);
  let _eP = Math.floor(videoProductLength + _played);

  if (_played || _sP < 7 || !_sP) _sP = 7;
  if (_l < 15) _l = 15;
  if (_l > 86 || !_l) _l = 86;
  if (_eP > _duration) _eP = 0;
  return (
    <>
      <div className="tw-grid tw-grid-cols-3 tw-gap-x-4">
        {data.live_stream.images && data.live_stream.images[0] && (
          <Image
            src={data.live_stream.images[0].url}
            width={130}
            height={85}
            alt={data.live_stream.name}
            className="tw-object-cover tw-rounded-5"
          ></Image>
        )}
        <div className="tw-col-span-2">
          <h4 className="tw-text-base tw-leading-6 md:tw-leading-7 md:tw-text-xl tw-text-secondary-70 tw-line-clamp-2">
            {data.live_stream.name}
          </h4>
          <p className="tw-text-secondary-50 tw-text-base md:tw-text-lg">
            {data.start_datetime ? humanDate(data.start_datetime) : '---'} | {data.live_stream.duration}
          </p>
        </div>
      </div>
      <div className="tw-mt-5 tw-h-10">
        <TimeLive left={_sP + '%'} start={secondsToMinute(_played)} width={_l + '%'} end={secondsToMinute(_eP)} />
      </div>
    </>
  );
};
