import { UserModel } from '@/models';
import axios, { AxiosResponse, AxiosErrorResponse } from '@/libs/axios';

import { QueryObserverOptions, useQuery } from 'react-query';
import { QueryKey } from '../shared';

export type User = UserModel;
type UserQueryResponse = { data: User };
export const fetchAccountProfile = () => {
  return axios.get('/api/v1/user/profile');
};
export const fetchPopup = () => {
  return axios.get('/api/v1/popup/web');
};
export const useFetchUserQuery = (
  options?: QueryObserverOptions<
    AxiosResponse<UserQueryResponse>,
    AxiosErrorResponse,
    UserQueryResponse,
    AxiosResponse<UserQueryResponse>,
    (string | '')[]
  >
) => {
  return useQuery([QueryKey.Users], () => fetchAccountProfile(), options);
};

export const useMasterDataPopupQuery = (
  options?: QueryObserverOptions<AxiosResponse<any>, AxiosErrorResponse, any, AxiosResponse<any>, (string | '')[]>
) => {
  return useQuery([QueryKey.Popup], () => fetchPopup(), options);
};
