import React from 'react';
import { IconProps } from '../const';

export const PhoneIcon = ({ width, height, className }: IconProps) => {
  const _w = width || '24';
  const _h = height || '24';
  const _cn = className || '';

  return (
    <svg
      width={_w}
      height={_h}
      className={_cn}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.67666 5.6124C3.30278 4.94223 4.32876 3.91779 5.17311 3.08517C5.95479 2.31437 7.21104 2.32945 7.9816 3.11136L9.939 5.09762C10.5194 5.68658 10.497 6.62193 9.96675 7.25642C8.35993 9.17912 8.65743 10.3794 11.2694 12.9913C13.8837 15.6056 15.0477 15.8652 16.9704 14.2504C17.6015 13.7204 18.5337 13.701 19.1207 14.2795L21.1397 16.2694C21.9221 17.0405 21.9366 18.2977 21.1649 19.0795C20.3341 19.9213 19.3124 20.9444 18.6399 21.5756C14.5763 25.3905 -1.12886 9.68566 2.67666 5.6124Z"
        stroke="currentColor"
      />
    </svg>
  );
};
