import React from 'react';

export const FaceIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M9.99025 0.25C4.60825 0.25 0.25 4.618 0.25 10C0.25 15.382 4.60825 19.75 9.99025 19.75C15.382 19.75 19.75 15.382 19.75 10C19.75 4.618 15.382 0.25 9.99025 0.25ZM10 17.8C5.6905 17.8 2.2 14.3095 2.2 10C2.2 5.6905 5.6905 2.2 10 2.2C14.3095 2.2 17.8 5.6905 17.8 10C17.8 14.3095 14.3095 17.8 10 17.8ZM13.4125 9.025C14.2218 9.025 14.875 8.37175 14.875 7.5625C14.875 6.75325 14.2218 6.1 13.4125 6.1C12.6033 6.1 11.95 6.75325 11.95 7.5625C11.95 8.37175 12.6033 9.025 13.4125 9.025ZM6.5875 9.025C7.39675 9.025 8.05 8.37175 8.05 7.5625C8.05 6.75325 7.39675 6.1 6.5875 6.1C5.77825 6.1 5.125 6.75325 5.125 7.5625C5.125 8.37175 5.77825 9.025 6.5875 9.025ZM10 15.3625C12.2718 15.3625 14.2023 13.939 14.9823 11.95H5.01775C5.79775 13.939 7.72825 15.3625 10 15.3625Z"
        fill="currentColor"
      />
    </svg>
  );
};
