type DiamondLikeIconProps = {
  color?: string;
};

export const DiamondLikeIcon = ({ color }: DiamondLikeIconProps) => {
  return (
    <>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.0935 9.94202L24.0236 12.0347C24.024 12.0357 24.0245 12.0367 24.0249 12.0377C24.1522 12.3304 24.2106 12.7811 24.1285 13.2862C24.0461 13.7928 23.8469 14.2023 23.6327 14.4389C23.6327 14.439 23.6326 14.439 23.6325 14.4391L15.6426 23.2338C14.7211 24.2475 13.2773 24.2475 12.3558 23.2338L4.3659 14.4391C4.36571 14.4389 4.36552 14.4387 4.36533 14.4385C4.15127 14.2018 3.95232 13.7925 3.86996 13.2862C3.78781 12.781 3.84623 12.3303 3.97356 12.0376L4.90493 9.94202L6.95627 5.32651C7.17606 4.84122 7.39692 4.54299 7.64745 4.35492C7.87629 4.18313 8.25639 4.00781 8.98255 4.00781H19.0159C19.742 4.00781 20.1221 4.18313 20.351 4.35492C20.6015 4.54297 20.8223 4.84116 21.0421 5.32638L23.0935 9.94202Z"
          stroke={color || 'currentColor'}
          strokeWidth="3"
        />
      </svg>
    </>
  );
};

export const DiamondLikeFilledIcon = ({ color }: DiamondLikeIconProps) => {
  return (
    <>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.3975 11.4328L24.4642 9.33281L22.4109 4.71281C21.8625 3.49948 20.9992 2.50781 19.0159 2.50781H8.98255C6.99921 2.50781 6.13588 3.49948 5.58755 4.71281L3.53421 9.33281L2.60088 11.4328C2.06421 12.6578 2.35588 14.4545 3.25421 15.4461L11.2459 24.2428C12.7625 25.9111 15.2359 25.9111 16.7525 24.2428L24.7442 15.4461C25.6425 14.4545 25.9342 12.6578 25.3975 11.4328Z"
          fill={color || 'currentColor'}
        />
      </svg>
    </>
  );
};
