import React from 'react';

export const LoadingDot32px = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ margin: 'auto', display: 'block', shapeRendering: 'auto' }}
      width="32px"
      height="32px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle cx="84" cy="50" r="10" fill="#106657">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="1.25s"
          calcMode="spline"
          keyTimes="0;1"
          values="10;0"
          keySplines="0 0.5 0.5 1"
          begin="0s"
        />
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="5s"
          calcMode="discrete"
          keyTimes="0;0.25;0.5;0.75;1"
          values="#106657;#25fad5;#1dc9ab;#14a289;#106657"
          begin="0s"
        />
      </circle>
      <circle cx="16" cy="50" r="10" fill="#106657">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="5s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="0s"
        />
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="5s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="0s"
        />
      </circle>
      <circle cx="50" cy="50" r="10" fill="#14a289">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="5s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1.25s"
        />
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="5s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1.25s"
        />
      </circle>
      <circle cx="84" cy="50" r="10" fill="#1dc9ab">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="5s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-2.5s"
        />
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="5s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-2.5s"
        />
      </circle>
      <circle cx="16" cy="50" r="10" fill="#25fad5">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="5s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-3.75s"
        />
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="5s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-3.75s"
        />
      </circle>
    </svg>
  );
};
