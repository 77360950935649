import { ModalCustom, Image, Icon, Link, XIcon } from '@/components';
import Router from 'next/router';
import React, { useEffect, useState } from 'react';
import { useMasterDataPopupQuery } from '../api';

export const PopupPartial = () => {
  const [show, setShow] = useState<boolean>(false);
  const [popupData, setPopupData] = useState<any>(null);
  const { refetch } = useMasterDataPopupQuery({
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: ({ data }) => {
      if (!data?.title) return setShow(false);
      else {
        setShow(true);
        setPopupData(data);
      }
    },
  });
  const redirect = () => {
    Router.push(popupData.link);
    setShow(false);
  };
  useEffect(() => {
    refetch();
  }, [refetch]);
  if (!show) return <></>;
  return (
    <ModalCustom className="!tw-p-0 tw-rounded-2xl tw-overflow-hidden md:tw-max-w-2xl" childrenClass="!tw-gap-y-0">
      <div
        className="tw-absolute tw-top-4 tw-right-4 tw-w-9 tw-h-9 tw-flex tw-items-center tw-justify-center tw-rounded-full  tw-z-1 tw-cursor-pointer"
        style={{ color: popupData?.web_extra?.icon_cancel_color }}
        onClick={() => setShow(false)}
      >
        <Icon className="tw-w-5 tw-h-5" icon={<XIcon />} />
      </div>
      <div onClick={redirect}>
        <div className="tw-w-full tw-relative tw-pb-160-percent md:tw-pb-85-percent">
          <Image
            src={popupData?.web_extra?.image_url}
            alt={popupData?.title}
            className="!tw-absolute tw-w-full tw-h-full tw-top-0 tw-left-0 md:tw-block tw-hidden"
          ></Image>
          <Image
            src={popupData.mobile_extra.image_url}
            alt={popupData.title}
            className="!tw-absolute tw-w-full tw-h-full tw-top-0 tw-left-0 md:tw-hidden tw-block"
          ></Image>
        </div>
      </div>
    </ModalCustom>
  );
};
