import React from 'react';
import { IconProps } from '../const';

export const OrderIcon = ({ className }: IconProps) => {
  const _cn = className || '';

  return (
    <svg
      className={_cn}
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="5" y="4" width="14" height="17" rx="2" stroke="currentColor" />
      <path d="M9 9H15" stroke="currentColor" strokeLinecap="round" />
      <path d="M9 13H15" stroke="currentColor" strokeLinecap="round" />
      <path d="M9 17H13" stroke="currentColor" strokeLinecap="round" />
    </svg>
  );
};
