import React from 'react';

export const ArrowLeftIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M9 12L8.29289 11.2929L7.58579 12L8.29289 12.7071L9 12ZM14.2929 5.29289L8.29289 11.2929L9.70711 12.7071L15.7071 6.70711L14.2929 5.29289ZM8.29289 12.7071L14.2929 18.7071L15.7071 17.2929L9.70711 11.2929L8.29289 12.7071Z"
      />
    </svg>
  );
};
