import React from 'react';

import { getLinkBct, getSocialUrl } from '@/libs/app';
import { Link, Image } from '@/components';
import { useTranslation } from 'next-i18next';
import { SocialType, PagePath, BctType } from '@/layouts/shared';

const FooterPartial = () => {
  const { t } = useTranslation('layout');

  const pageItems = {
    info: [
      // {path: PagePath.AboutPage, type: "link", text: t('footer.page.about')},
      { path: PagePath.AboutPage, type: 'phone', text: process.env.HOTLINE || '033 333 6789' },
    ],
    support: [
      { path: PagePath.PrivacyPolicyPage, type: 'link', text: t('footer.page.privacy-policy') },
      { path: PagePath.PaymentGuidePolicyPage, type: 'link', text: t('footer.page.payment-guide') },
      { path: PagePath.CheckoutPolicyPage, type: 'link', text: t('footer.page.checkout-policy') },
    ],
    delivery: [
      { path: PagePath.RefundPolicyPage, type: 'link', text: t('footer.page.refund-policy') },
      {
        path: PagePath.ShippingAndDeliveryPolicyPage,
        type: 'link',
        text: t('footer.page.shipping-and-delivery-policy'),
      },
    ],
  };

  return (
    <div className="tw-flex tw-flex-col tw-mt-8 tw-mb-4 tw-gap-y-8">
      <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-5 tw-gap-0 md:tw-gap-5 tw-mt-6 tw-px-0  tw-gap-y-4 lg:tw-gap-y-0">
        <div>
          <div className="tw-font-bold tw-uppercase tw-text-base tw-mb-5">{t('footer.title.info')}</div>

          <div className="list tw-flex tw-flex-col tw-gap-y-3">
            {/* {t('footer.label.contact')}{' '} */}
            <Link url={`tel:033 333 6789`} className="tw-text-sm">
              033 333 6789 (HCM)
            </Link>
            <Link url={`tel:033 3333 789`} className="tw-text-sm">
              033 3333 789 (HN)
            </Link>
          </div>
        </div>
        <div>
          <div className="tw-font-bold tw-uppercase tw-text-base tw-mb-5">{t('footer.title.support')}</div>

          <div className="list tw-flex tw-flex-col tw-gap-y-3">
            {pageItems.support.map(({ path, type, text }, index) => {
              if (type === 'link') {
                return (
                  <Link url={path} key={`${text}-${index}`} className="tw-text-sm">
                    {text}
                  </Link>
                );
              }
              if (type === 'phone') {
                return (
                  <Link url={`tel:${text}`} key={`${text}-${index}`} className="tw-text-sm">
                    {t('footer.label.contact')}
                    {text}
                  </Link>
                );
              }
              if (type === 'email') {
                return (
                  <Link url={`email:${text}`} key={`${text}-${index}`} className="tw-text-sm">
                    {t('footer.label.contact')}
                    {text}
                  </Link>
                );
              }
            })}
          </div>
        </div>
        <div>
          <div className="tw-font-bold tw-uppercase tw-text-base tw-mb-5">{t('footer.title.delivery')}</div>

          <div className="list tw-flex tw-flex-col tw-gap-y-3">
            {pageItems.delivery.map(({ path, type, text }, index) => {
              if (type === 'link') {
                return (
                  <Link url={path} key={`${text}-${index}`} className="tw-text-sm">
                    {text}
                  </Link>
                );
              }
              if (type === 'phone') {
                return (
                  <Link url={`tel:${text}`} key={`${text}-${index}`} className="tw-text-sm">
                    {t('footer.label.contact')}
                    {text}
                  </Link>
                );
              }
              if (type === 'email') {
                return (
                  <Link url={`email:${text}`} key={`${text}-${index}`} className="tw-text-sm">
                    {t('footer.label.contact')}
                    {text}
                  </Link>
                );
              }
            })}
          </div>
        </div>
        <div className="tw-pl-0 md:tw-pl-6">
          <div className="tw-font-bold tw-uppercase tw-text-base tw-mb-2">{t('footer.title.link')}</div>

          <div className="list tw-flex tw-gap-x-3 tw-items-center">
            <Link url={getSocialUrl(SocialType.Facebook)} target="_blank">
              <Image src="/facebook-color.png" alt="Facebook" className="tw-w-7 tw-h-7 tw-rounded-full" />
            </Link>
            <Link url={getSocialUrl(SocialType.Youtube)} target="_blank">
              <Image src="/youtube.webp" alt="Youtube" className="tw-h-10 tw-w-10 tw-rounded-full" />
            </Link>
          </div>
        </div>
        <div>
          <div className="tw-font-bold tw-uppercase tw-text-base tw-mb-5">{t('footer.title.download')}</div>
          <div className="tw-flex tw-gap-3 md:tw-gap-4">
            <Image src="/qr-code.jpg" alt="Apple Store" className="tw-w-20 tw-h-20" objectFit="contain" />
            <div className="list tw-flex tw-flex-col tw-gap-y-2">
              <Link url={getSocialUrl(SocialType.AppleStore)} target="_blank">
                <Image src="/dl-appstore.webp" alt="Apple Store" className="tw-h-8 tw-w-24" objectFit="contain" />
              </Link>
              <Link url={getSocialUrl(SocialType.GoogleStore)} target="_blank">
                <Image src="/dl-ggplay.webp" alt="Google Store" className="tw-h-8 tw-w-24" objectFit="contain" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="tw-px-0">
        <div className="tw-grid  tw-grid-cols-1 lg:tw-grid-cols-5 tw-gap-0 lg:tw-gap-2 tw-mt-6 tw-border-t tw-py-6">
          <div className="tw-text-sm tw-col-span-4 tw-text-secondary-50 tw-flex tw-flex-col tw-gap-y-1">
            <p>Copyright © 2022 Công ty TNHH An Thư The Diamond Store</p>
            <p>Mã số thuế: 0314503621, Ngày cấp: 07/07/2017, Người đại diện: Nguyễn Thành An</p>
            <p>
              Giấy chứng nhận Đăng ký Kinh doanh số 0314503621 do Sở Kế hoạch và Đầu tư Thành phố Hồ Chí Minh cấp lần
              đầu ngày 07/07/2017 và sửa đổi lần thứ 7 ngày 21/04/2020
            </p>
            <p>
              Địa chỉ đăng ký trụ sở chính: 89A Nguyễn Trãi, Phường Bến Thành, Quận 1, Thành phố Hồ Chí Minh, Việt Nam
            </p>
            <p>Điện thoại: 033 333 6789 | Email: support@anthu.tech</p>
          </div>
          <div>
            <div className="tw-font-bold tw-uppercase tw-text-base tw-mb-5">{t('footer.title.certification')}</div>
            <div className="list tw-flex tw-gap-x-3">
              <Link url={getLinkBct(BctType.Announced)} target="_blank">
                <Image src="/bct-2.webp" alt="Facebook" className="tw-w-28 tw-h-10 tw-rounded-full" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterPartial;
