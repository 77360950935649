import { ChatReducerType, ChatReducerTypes } from '@/features/consults/chat/const';

export const initialState = {
  messageMapper: {},
};

export type ChatActionType =
  | { type: ChatReducerType.OnChatSubmit; payload: ChatReducerTypes['messageMapper'] }
  | { type: ChatReducerType.OnMessageMapperInit; payload: ChatReducerTypes['messageMapper'] };

export const reducer = (state: ChatReducerTypes, action: ChatActionType): ChatReducerTypes => {
  switch (action.type) {
    case ChatReducerType.OnMessageMapperInit:
      return {
        ...state,
        messageMapper: {
          ...initialState.messageMapper,
          ...action.payload,
        },
      };

    case ChatReducerType.OnChatSubmit:
      return {
        ...state,
        messageMapper: {
          ...state.messageMapper,
          ...action.payload,
        },
      };

    default:
      return { ...initialState };
  }
};
