import React, { ReactNode, MouseEvent } from 'react';

import cm from '@/libs/tailwind-merge';

export enum ButtonVariant {
  Outlined = 'outlined',
  Standard = 'standard',
}

type ButtonProps = {
  loading?: boolean;
  dataId?: string;
  disabled?: boolean;
  className?: string;
  children: ReactNode;
  variant?: ButtonVariant;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const Button = ({
  dataId,
  onClick,
  loading,
  variant,
  children,
  disabled,
  className,
}: ButtonProps): JSX.Element => {
  const isDisabled = loading || disabled;

  let variantCs =
    'tw-border tw-border-primary-main disabled:tw-opacity-50 disabled:tw-cursor-not-allowed tw-bg-primary-main tw-text-white';
  if (variant === ButtonVariant.Outlined) {
    variantCs = 'tw-border tw-border-primary-main tw-bg-white tw-text-primary-main disabled:tw-cursor-not-allowed';
  }

  const cs = cm('tw-text-center tw-w-full tw-p-2 tw-rounded-5', variantCs, className);

  return (
    <button data-id={dataId} type="button" onClick={onClick} disabled={isDisabled} className={cs}>
      {children}
    </button>
  );
};

Button.defaultProps = {
  dataId: '',
  className: '',
  loading: false,
  disabled: false,
  variant: ButtonVariant.Standard,
  onClick: (e: MouseEvent<HTMLButtonElement>) => {},
};
